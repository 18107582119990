<template>
    <div class="b-fees" :style="`--program-color: var(--color-${programInfos.color});`">
        <accordion class="b-fees__accordion" @toggle="updateToggleText">
            <template #heading>
                <div class="b-fees__heading">
                    <icon :icon="programInfos.icon" />
                    <span>{{ programInfos.fullTitle }}</span>
                </div>
                <div class="b-fees__toggle-text">{{ toggleText.toUpperCase() }}</div>
            </template>
            <template #content>
                <app-table :table="data.fees" />
            </template>
        </accordion>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Accordion from "../components/Accordion.vue";
import AppTable from "../components/AppTable.vue";
import Icon from "../objects/Icon.vue";
export default {
    name: "BlockFees",
    components: {
        AppTable,
        Accordion,
        Icon
    },
    data: () => ({
        toggleText: "Voir plus"
    }),
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            getProgramBySlug: "global/getProgramBySlug"
        }),
        programInfos() {
            let _program;
            if (this.data && this.data.program) _program = this.getProgramBySlug(this.data.program[0].slug);

            return _program;
        }
    },
    mounted() {},
    methods: {
        updateToggleText(isOpen) {
            if (isOpen) this.toggleText = "Voir moins";
            else this.toggleText = "Voir plus";
        }
    }
};
</script>

<style lang="scss">
.b-fees {
    --block-padding: 2.5rem;
    padding: var(--block-padding);
    background: var(--program-color);
    border-radius: $border-radius-xs;
    &__heading {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        max-width: 13rem;

        .o-icon svg {
            --svg-width: 3.5rem;
        }
    }

    &__accordion {
        gap: 0;
        border-bottom: 0;

        .c-accordion__content {
            padding-top: max(var(--grid-gutter), 4rem);
        }

        .c-table__line .column1 {
            font-weight: bold;
            text-transform: uppercase;
        }

        .c-accordion__inner {
            border-bottom: 0;
        }

        .c-accordion__icon {
            transform: translateY(-3px);
        }
    }

    &__toggle-text {
        justify-self: end;
        margin-left: auto;
        padding-right: 1rem;
        font-weight: bold;
        transition: all 0.5s ease;
    }

    &__toggle-text,
    .c-accordion__icon {
        align-self: end;
    }
}
</style>
