import getAsset from "./assets";

/*
 * Craft GraphQL Snippets
 */

/**********************************
 *
 *
 *  DEFAULTS
 *
 *
 **********************************/

// Default data
const defaultData = `
id
uri
`;

// Default entry
const entry = `
id
section: sectionHandle
title
slug
uri
`;

// Default category
const category = `
id
slug
title
`;

/**********************************
 *
 *
 *  ASSET
 *
 *
 **********************************/

const logo = `
logo {
    url
    title
}
`;

/**********************************
 *
 *
 *  ATOMS
 *
 *
 **********************************/

// Hero
const hero = `
heading
colour
decoType
${getAsset("image")}
circle: trueOrFalse1
`;

// SEO
const seo = `
seo {
    title
    description
}
`;

// Map (SuperTable)
const map = `
map {
    ...on map_BlockType {
        lng
        lat
    }
}
`;

// Nominations (SuperTable)
const nominationsPreload = `
nominations {
    ...on nominations_BlockType {
        id
    }
}
`;

const nominations = `
nominations {
    ...on nominations_BlockType {
        festival {
            id
            title
        }
        label
    }
}
`;

// Awards (SuperTable)
const awardsPreload = `
awards {
    ...on awards_BlockType {
        id
    }
}
`;

const awards = `
awards {
    ...on awards_BlockType {
        festival {
            id
            title
            ...on festival_default_Entry {
                ${logo}
            }
        }
        label
    }
}
`;

// Simple button
const button = `
label
externalLink
btnType
target {
    uri
    title
}
`

// Buttons (Matrix)
const buttons = `
buttons {
    ...on buttons_button_BlockType {
        ${button}
    }
  }
`
// Tableau (Matrix)
const table = `
table {
    ...on table_line_BlockType {
        column1
        column2
    }
  }
`


/**********************************
 *
 *
 *  RELATIONS
 *
 *
 **********************************/

const articleType = `
articleType: relationCategoryArticleTypes {
   ${category}
}
`;

const relationProgram = `
program: relationEntryProgram {
    ${entry}
}
`;
const relationEntryPage = `
relationEntryPage {
    ...on page_default_Entry {
        ${entry}
    }
}
`;

const studentPreload = `
student: relationEntryStudent {
    title
    id
}
`;

const student = `
student: relationEntryStudent {
    title
    id
    ...on student_default_Entry {
        ${getAsset("image", "medium.1:1")}
        role: label
        bio: body2
    }
}
`;

const teacher  = `
teacher {
    ...on teacher_default_Entry {
        title
        role: label
        email
        phone
        videoId
        bio: description
        ${getAsset('image', 'medium.1:1')}
    }
}
`

/**********************************
 *
 *
 *  BUILDER
 *
 *
 **********************************/

// Accordions
const blockAccordions = `
...on layout_BlockAccordions_BlockType {
    typeHandle
    heading
    openFirst: trueOrFalse1
    ${getAsset('image')}
    accordions: children {
        ...on layout_accordion_BlockType {
            heading
            content: body2
        }
    }
}
`;

// Activities
const blockActivities = `
...on layout_BlockActivities_BlockType {
    typeHandle
    activities: children {
        ...on layout_activity_BlockType {
            heading
            description: body2
            videoId
            ${getAsset('image')}
        }
    }
}
`;

// Buttons
const blockButtons = `
...on layout_BlockButtons_BlockType {
    typeHandle
    horizontal: trueOrFalse1
    ${buttons}
}
`

// Partners
const blockPartners = `
...on layout_BlockPartners_BlockType {
    typeHandle
    partners: children {
        ...on layout_partner_BlockType {
            ${logo}
            url: externalLink
        }
    }
}
`;

// Table
const blockTable = `
...on layout_BlockTable_BlockType {
    typeHandle
    heading
    ${table}
}
`

// TextImage
const blockTextImage = `
...on layout_BlockTextImage_BlockType {
    typeHandle
    heading
    ${getAsset('image', 'large.16:9')}
}
`


// Persons
const blockPersons = `
...on layout_BlockPersons_BlockType {
    typeHandle
    persons: children {
        ...on layout_person_BlockType {
            ${teacher}
            showContactBtn: trueOrFalse1
        }
    }
}
`;

// Fees
const blockFees = `
...on layout_BlockFees_BlockType {
    typeHandle
    ${relationProgram}
    fees: list {
        ...on list_TableRow {
            column1
            column2
        }
    }
}
`;

// Content
const blockContent = fieldName => `
...on ${fieldName || 'layout' }_BlockContent_BlockType {
    typeHandle
    content: body
}
`;

// CTA
const blockCTA = `
...on layout_BlockCTA_BlockType {
    typeHandle
    heading
    label
    displayHalf: trueOrFalse1
    horizontal: trueOrFalse2
    ${getAsset("image", "medium.16:9")}
    content: body2
    ${buttons}
}
`;

// FeaturedArticle
const blockFeaturedArticle = `
...on layout_BlockFeaturedArticle_BlockType {
    typeHandle
    article: relationEntryArticle {
        postDate
        ${entry}
        ...on article_default_Entry {
            ${articleType}
            ${getAsset("image", "article.card")}
            excerpt: description
        }
    }
}
`;

// FeaturedVideo
const blockFeaturedVideo = `
...on layout_BlockFeaturedVideo_BlockType {
    typeHandle
    video: relationEntryVideo {
        ${entry}
        ...on video_default_Entry {
            ${getAsset("image", "cover")},
            videoId,
            description: body2
            ${relationProgram}
            ${student}
            ${nominations}
            ${awards}
        }
    }
}
`;

// Heading
const blockHeading = `
...on layout_BlockHeading_BlockType {
    typeHandle
    heading
}
`;

// Gallery
const blockGallery = `
...on layout_BlockGallery_BlockType {
    typeHandle
    ${getAsset("images")}
}
`;

// Image
const blockImage = `
...on layout_BlockImage_BlockType {
    typeHandle
    ${getAsset()}
}
`;

// Spacer
const blockSpacer = `
...on layout_BlockSpacer_BlockType {
    typeHandle
}
`;

// Tabs
const blockTabs = `
...on layout_BlockTabs_BlockType {
    typeHandle
    tabs: children {
        ...on layout_tab_BlockType {
            title: label
            ${getAsset('image', 'tabs')}
        }
    }
}
`

// Quote
const blockQuote = fieldName => `
...on ${fieldName || 'layout' }_BlockQuote_BlockType {
    typeHandle
    ${getAsset('image', 'medium.1:1')}
    content: description
}
`;

// Video
const blockVideo = `
...on layout_BlockVideo_BlockType {
    typeHandle
    videoId
}
`;

// Section
const blockSection = `
...on layout_BlockSection_BlockType {
    typeHandle
    id
    title: label
    sectionWidth
    hasPadding: trueOrFalse1
    hasBackgroundColor: trueOrFalse2
    children {
        ${blockAccordions}
        ${blockActivities}
        ${blockButtons}
        ${blockTable}
        ${blockTextImage}
        ${blockContent()}
        ${blockCTA}
        ${blockFees}
        ${blockFeaturedArticle}
        ${blockFeaturedVideo}
        ${blockHeading}
        ${blockGallery}
        ${blockImage}
        ${blockSpacer}
        ${blockVideo}
        ${blockPersons}
        ${blockPartners}
        ${blockTabs}
    }
}
`;

// Layout
const layout = `
sections: layout {
    ${blockSection}
}
`;

// LayoutArticle
const layoutArticle = `
sections: layoutArticle {
    ${blockContent('layoutArticle')}
    ${blockQuote('layoutArticle')}
}
`;

/**********************************
 *
 *
 *  CHANNELS AND STRUCTURES
 *
 *
 **********************************/

const page = `
${entry}
...on page_default_Entry {
    ${hero}
    ${layout}
    ${seo}
    children {
        ${entry}
    }
    parent {
        title
        children {
            ${entry}
        }
    }
}
`;

const article = `
${entry}
postDate: postDate @formatDateTime
...on article_default_Entry {
    ${layoutArticle}
    ${articleType}
    ${seo}
    ${getAsset("image", 'cover')}
}
`;

const program = `
${entry}
...on program_default_Entry {
    ${layout}
    ${seo}
    colour
    fullTitle: altTitle
    inscriptionRunning: trueOrFalse1
    inscriptionLink: externalLink
    limitDate: date1
    formationStart: date2
    diplomaType
    duration
    educationType
    location
}
`;

const videoPreload = `
${entry}
...on video_default_Entry {
    ${getAsset("image", "cover")}
    videoId
    ${relationProgram}
    ${studentPreload}
    ${nominationsPreload}
    ${awardsPreload}
    ${seo}
}
`;

const video = `
${entry}
postDate: postDate @formatDateTime
...on video_default_Entry {
    ${getAsset("image", "cover")}
    videoId
    description: body2
    ${relationProgram}
    ${student}
    ${nominations}
    ${awards}
    ${seo}
}
`;

// Exports
export default {
    // Defaults
    defaultData,
    entry,
    category,

    // Atoms
    hero,
    seo,
    map,
    buttons,

    // Relations
    relationProgram,
    relationEntryPage,
    student,
    teacher,

    // Builder
    layout,

    // CHANNELS
    page,
    article,
    program,
    videoPreload,
    video
};
