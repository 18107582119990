<template>
    <div class="l-tab-list" v-if="tabs">
        <div class="l-tab-list__inner">
            <btn
                v-for="(tab, index) in tabs"
                :key="`tab-${index}`"
                ref="tabBtn"
                :class="{ '-active-tab': index == activeTab }"
                :label="tab.title"
                :type="activeTab == index ? 'light' : 'transparent'"
                @click.native="toggleTab(index)"
            />
        </div>
    </div>
</template>

<script>
import Btn from 'components/Btn.vue'
export default {
    name: 'TabList',
    components: {
        Btn,
    },
    props: {
        tabs: {
            type: Array,
        }
    },
    data: () =>({
        activeTab: 0,
    }),
    methods: {
        toggleTab(index) {
            this.activeTab = index
            this.$emit('tabchange',  this.activeTab)
        }
    },
    watch: {
    }

}
</script>

<style lang="scss">
.l-tab-list {
    width: 100%;

    &__inner {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        overflow-y: hidden;
        // gap: var(--grid-gutter-half);
    }

    .c-btn {
        --padding-x: 1.5rem;
        --padding-y: 1.5rem;
        white-space: pre;
        transition: background .6s $in-quad;
        overflow: visible;

        &.-active-tab {
            &:hover {
                --btn-color-ripple: var(--color-light);
                --btn-color: var(--color-dark);
            }
        }
    }
}
</style>
