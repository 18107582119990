<template>
    <div class="c-modal-video" :class="{ 'is-open': data, 'is-teacher': !student }">
        <div
            v-if="data"
            class="c-modal-video__inner"
            @mouseover="hideCloseButton = false"
            @mouseleave="hideCloseButton = true"
        >
            <btn
                label="fermer"
                type="light"
                class="btn-close"
                :class="{ '-hide': hideCloseButton }"
                @click.native="closeModal"
            />
            <base-video
                ref="video"
                :videoId="data.videoId"
                :displayControlsFirst="true"
                :overwrite-poster="data.image"
                @loaded="setDefaults"
                :muted="false"
                class="c-modal-video__video"
                v-cursor.arrow
            />
            <div class="c-modal-video__infos grid">
                <div v-if="program" class="c-modal-video__program">
                    <icon :icon="program.icon" />
                    <div class="-program-title">{{ program.title }}</div>
                </div>
                <template v-if="student">
                    <h1 class="c-modal-video__video-title t-t3">{{ data.title }}</h1>
                    <student-infos :student="student" @seeMore="gotoVideoPage" />
                </template>
                <teacher-infos v-else-if="infosReady" :teacher="teacher" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { gsap } from "gsap/all";

import { EventBus } from "src/event-bus";
import StudentInfos from "components/Modal/StudentInfos";
import TeacherInfos from "components/Modal/TeacherInfos";
import Icon from "../objects/Icon.vue";
import BaseVideo from "./BaseVideo.vue";
import Btn from "./Btn.vue";
export default {
    components: { BaseVideo, Icon, Btn, StudentInfos, TeacherInfos },
    name: "ModalVideo",
    data: () => ({
        currentScroll: 0,
        hideCloseButton: false,
        infosReady: false,
        defaultVolumeLevel: 0.5
    }),
    mounted() {
        this.init();
    },
    computed: {
        ...mapState({
            data: state => state.modal.data
        }),
        ...mapGetters({
            getProgramBySlug: "global/getProgramBySlug"
        }),
        program() {
            let prog;

            if (this.data && this.data.program) prog = this.getProgramBySlug(this.data.program.slug);

            return prog;
        },
        student() {
            if (this.data.student) {
                const student = this.data.student instanceof Array ? this.data.student[0] : this.data.student;

                return student;
            }
        },
        teacher() {
            if (!this.student) return this.data;
        }
    },
    beforeDestroy() {
        this.tl ? this.tl.kill() : "";
    },
    methods: {
        init() {
            this.itemsToReveal = [".c-modal-video__video", ".c-modal-video__program", ".c-modal-video__video-title"];

            this.itemsToReveal.forEach((item, index) => {
                if (document.querySelector(item)) {
                    gsap.set(item, {
                        opacity: 0,
                        y: index == 0 ? 0 : 20
                    });
                }
            });
            this.tl = gsap.timeline({});

            setTimeout(() => {
                this.itemsToReveal.forEach(item => {
                    if (document.querySelector(item)) {
                        this.reveal(item);
                    }
                });
            }, 1000);
        },
        reveal(item) {
            this.tl.to(item, {
                opacity: 1,
                y: 0,
                duration: 0.5,
                delay: -0.2,
                ease: "power1.out"
            });
        },
        closeModal() {
            this.$store.dispatch("modal/close");

            //Emit a cursor hover out event so the cursor knows
            EventBus.$emit("cursor-hover-out");
        },
        getInfosHeight() {
            setTimeout(() => {
                let infos = this.$el.querySelector(".c-modal-video__infos");
                let video = this.$el.querySelector(".c-modal-video__video");

                this.$el.style.setProperty("--video-height", video.offsetHeight + "px");
                this.$el.style.setProperty("--infos-height", infos.offsetHeight + "px");
                this.infosReady = true;
            }, 1000);
        },
        gotoVideoPage() {
            this.$router.push({ path: "/" + this.data.uri });
            setTimeout(this.closeModal, 800);
        },
        setDefaults() {
            this.$nextTick(() => {
                const videoWrap = this.$refs.video.$el;
                const video = videoWrap.querySelector("video");

                video.muted = false;
                video.volume = this.defaultVolumeLevel;
            });
        }
    },
    watch: {
        data(data) {
            if (data) {
                this.currentScroll = window.scrollY;
                document.documentElement.style.overflowY = "hidden";
                this.getInfosHeight();
                window.addEventListener("resizeEnd", this.getInfosHeight);
            } else {
                // Add previous scroll
                document.documentElement.style.overflowY = "scroll";
                window.scrollTo(0, this.currentScroll);
                window.removeEventListener("resizeEnd", this.getInfosHeight);
            }
        },
        $route() {}
    }
};
</script>

<style lang="scss">
.c-modal-video {
    z-index: 500;
    background: var(--color-light);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    transform: scale(0);
    transition: transform 0.6s $out-quad;
    transform-origin: center;

    &.is-open {
        transform: scale(1);
    }

    &__inner {
        display: grid;
        grid-auto-flow: row;
    }

    &__infos {
        display: grid;
        margin-top: var(--grid-gutter);
        gap: var(--grid-gutter-half);

        .-program-title {
            font-size: fs("medium");
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    //  &__video-title{
    //      padding-top:var(--grid-gutter);
    // }

    &__program {
        display: grid;
        grid-auto-flow: column;
        width: fit-content;
        align-items: center;
        gap: var(--grid-gutter-half);
    }

    .btn-close {
        position: absolute;
        z-index: 501;
        top: var(--grid-gutter);
        right: var(--grid-gutter);
        transition: opacity 0.5s ease;
        border: 1px solid var(--color-light);

        &:hover {
            border-color: currentColor;
        }

        &.-hide {
            opacity: 0;
            pointer-events: none;
        }
    }

    .c-base-video {
        .plyr__progress {
            --plyr-range-fill-background: var(--color-chartreuse);

            input[type="range"] {
                //The border left persist so hide it with a pseudo-el
                &:before {
                    @include pseudo-el;
                    position: absolute;
                    width: var(--value);
                    height: 100%;
                    background: var(--plyr-range-fill-background);
                }
            }
        }

        @media #{md('md')} {
            --height: min(var(--infos-height), 20rem);
            height: calc(100vh - var(--height) - var(--grid-gutter-double));
        }
    }

    &.is-teacher {
        .c-base-video {
            @media #{md('md')} {
                height: calc(100vh - 30rem);
            }
        }
    }
}
</style>
