<template>
    <div class="c-programs" :class="{ '-revealed': isRevealed }" v-reveal.once>
        <div class="c-programs__inner">
            <ul class="c-programs__inner__main">
                <li class="c-programs__inner__main__title">
                    <!-- title les programmes-->
                    <section-title
                        :section-number="sectionTitle.sectionNumber"
                        :section-name="sectionTitle.sectionName"
                    />
                </li>
                <li>
                    <!-- lists -->
                    <home-programs-list />
                </li>
                <!-- <li class="c-programs__inner__main__more">
          <btn type="dark" tag="router-link" url="/" :is-background-white="true">En Savoir Plus</btn>
        </li> -->
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import SectionTitle from "@/templates/components/SectionTitle/SectionTitle";
import HomeProgramsList from "@/templates/components/HomePrograms/HomeProgramsList";
import Btn from "@/templates/components/Btn";
export default {
    components: {
        SectionTitle,
        HomeProgramsList,
        Btn
    },
    data() {
        return {
            sectionTitle: {
                sectionNumber: 1,
                sectionName: "Les programmes"
            },
            isRevealed: false
        };
    },
    computed: {
        ...mapGetters({
            isLoaderCompleted: "loader/isLoaderCompleted"
        })
    },
    watch: {
        isLoaderCompleted(bool) {
            bool ? (this.isRevealed = true) : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-programs {
    @include shared-reveal-animation-css;
    &__inner {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        position: relative;

        &__main {
            @include reset-list;
            grid-column: 1/13;
            //padding-top: var(--grid-gutter);
            //padding-bottom: var(--grid-gutter-triple);

            &__title {
                /* align the top of the slider with the top of this component on mobile */
                // position: absolute;
                // left: 0px;
                @media #{md("lg")} {
                    position: relative;
                }
            }
            &__more {
                padding-top: var(--grid-gutter-double);
                text-transform: uppercase;
            }
        }
    }
}
</style>
