// State
const state = {
    data: false
};

// Getters
const getters = {};

// Actions
const actions = {
    open(store, { data }) {
        store.commit("openModal", data);
    },
    close(store) {
        store.commit("closeModal", false);
    }
};

// Mutations
const mutations = {
    openModal(state, data) {
        state.data = data;
    },
    closeModal(state) {
        state.data = false;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
