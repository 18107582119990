<template>
    <div v-if="program" class="l-hero-program">
        <div class="l-hero-program__inner">
            <div class="-program-info -title" v-if="program.fullTitle">
                <icon class="program-icon" :icon="program.icon" />
                <div>
                    {{ program.fullTitle }}
                </div>
            </div>
            <div class="-program-info">
                <div   v-if="program.diplomaType">
                    <h4 class="-heading">Type de diplôme</h4>
                    <p>{{ program.diplomaType }}</p>
                </div>
                <div v-if="program.educationType">
                    <h4 class="-heading">Enseignement</h4>
                    <p>{{ program.educationType }}</p>
                </div>
            </div>
            <div class="-program-info -last">
                <div v-if="program.duration">
                    <h4 class="-heading">Durée</h4>
                    <p>{{ program.duration }}</p>
                </div>
                <div v-if="program.location">
                    <h4 class="-heading">Lieu</h4>
                    <p>{{ program.location }}</p>
                </div>
            </div>
        </div>
        <inscription-banner
            v-if="program.inscriptionRunning" class="inscription-banner"
            :program="program"
            ref="inscriptionBanner"
        />
    </div>
</template>

<script>
import InscriptionBanner from '../components/InscriptionBanner.vue';
import Icon from "../objects/Icon.vue";
import { mapGetters } from 'vuex'

import { DELAY } from 'src/constants'

import { gsap } from 'gsap/all'
export default {
    name: "ProgramHero",
    components: {
        Icon,
        InscriptionBanner,
    },
    props: {
        page: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters({
            isLoading: 'loader/isLoading',
            isLoaderCompleted: 'loader/isLoaderCompleted',
            getProgramBySlug: 'global/getProgramBySlug'
        }),
        program() {
            if (this.page && this.page.slug) {
                const _program = this.getProgramBySlug(this.page.slug)

                return {...this.page, ..._program};
            }

        },
    },
    methods: {
        revealProgramInfos () {
            gsap.to('.-program-info', {
                opacity: 1,
                xPercent: 0,
                duration: .4,
                stagger: .2,
                ease: 'sine.inOut',
                // delay: DELAY.PROGRAM_INFOS / 1000,
                onComplete: this.revealInscriptionBanner
            })
        },
        revealInscriptionBanner() {
            if (this.$refs.inscriptionBanner)
                this.$refs.inscriptionBanner.revealBanner()
        }
    },
    watch: {
        isLoaderCompleted: {
            handler(loaded) {
                if( loaded )
                    setTimeout(() => {
                        this.revealProgramInfos()
                    }, DELAY.PROGRAM_INFOS)
            },
            immediate: true
        }
    }
};
</script>


<style lang="scss">
.l-hero-program {

    margin-top: var(--grid-gutter-triple);

    &__inner {
        display: grid;
        column-gap: var(--grid-gutter-double);
        row-gap: var(--grid-gutter);
        max-width: min(100vw, 120rem);
        transform: translateY(var(--grid-gutter-double));

        @media #{md("sm")} {
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: var(--grid-gutter);
            transform: translateY(0);
        }
        @media #{md("md")} {
            grid-template-columns: 1fr 2fr 2fr;
        }
    }

    .-heading {
        font-size: fs("small");
        text-transform: uppercase;
        margin-bottom: .4rem;
    }

    .-program-info {
        display: grid;
        column-gap: var(--grid-gutter);
        row-gap: var(--grid-gutter-half);

        grid-auto-rows: minmax(min-content, max-content);

        opacity: 0;
        transform: translateX(-50%);

        &.-title {
            gap: var(--grid-gutter-half);
            grid-auto-rows: minmax(min-content, max-content);
            max-width: 17rem;
            font-weight: 600;

            @media #{md("sm")} {
                font-size: fs('medium');
            }
        }

        &.-last{
            grid-column: 2/3;

            @media #{md("sm")} {
                grid-column: 3/4;
            }
        }
    }

    .program-icon svg {
        --svg-width: min(5rem, calc(6vw + 1rem));
    }
}
</style>
