<template>
    <div class="b-feature-video-section">
        <!-- labels at the left -->
        <block-featured-video-block-awards
            v-if="data.awards.length || data.nominations.length"
            :awards="data.awards.length"
            :nominations="data.nominations.length"
            :class="{ '-hide': hideInfo }"
        />
        <!-- video -->
        <base-video
            v-cursor.label="cursorInfo"
            :videoId="data.videoId"
            :overwrite-poster="poster"
            :is-play-mobile-visible="true"
            :is-object-fit="true"
            @controlshidden="hideInfo = true"
            @controlsshown="hideInfo = false"
        />
    </div>
</template>

<script>
import Asset from "objects/Asset.vue";
import BlockFeaturedVideoBlockAwards from "blocks/BlockFeaturedVideo/BlockFeaturedVideoSection/BlockFeaturedVideoSectionAwards";
import BaseVideo from "components/BaseVideo";
export default {
    components: {
        Asset,
        BlockFeaturedVideoBlockAwards,
        BaseVideo
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        hideInfo: false
    }),
    computed: {
        cursorInfo() {
            return this.hideInfo ? null : { label: "Visionner", iconBefore: "play" };
        },
        poster() {
            return this.data.image.length && this.data.image[0].url ? this.data.image[0] : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.b-feature-video-section {
    display: flex;
    flex: 1;
    // height: 100%;
}
</style>
