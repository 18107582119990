<template>
    <div class="c-programs-list">
        <card-grid
            ref="homeProgramSlider"
            :cards="cards"
            :slider="true"
            card-type="HomeProgramsCard"
            card-model="enhance"
            slider-modifier="nav-on-top"
            nav-horizontal-position="end"
            :is-nav-have-outline="false"
            :calc-children-height="true"
            arrow-nav="play"
            @sliderActive="sliderChange"
        >
        </card-grid>
    </div>
</template>

<script>
import HomeProgramsCard from "@/templates/components/HomePrograms/HomeProgramsCard/HomeProgramsCard";
import CardGrid from "@/templates/components/CardGrid";
import { mapState } from 'vuex';


export default {
    components: {
        CardGrid,
        HomeProgramsCard,
    },
    computed: {
        ...mapState({
            programs: state => state.global.programs,
        }),
        cards() {
            if (this.programs) {

                // Temporary
                const programs = Array.from(this.programs, program => {
                    let image = program.image ? program.image : '/medias/images/tmp-program.png'

                    image = typeof image == 'string' ? { url: image } : image

                    return { ...program, image }
                })

                return programs
            }
        }
    },
    methods: {
        sliderChange(state) {
            this.isSlider = state
        },
    },
};
</script>

<style lang="scss" scoped>
.c-programs-list {

    &::v-deep .programs-card {
        &:nth-child(2) {
            @media #{md("lg")} {
                //@include transform(translate(0px, var(--grid-gutter)));
                margin-top: var(--grid-gutter);
            }
        }
        &:nth-child(3) {
            @media #{md("lg")} {
                //@include transform(translate(0px, var(--grid-gutter-double)));
                margin-top: var(--grid-gutter-double);
            }
        }
    }
}
</style>
