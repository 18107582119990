<template>
    <div class="l-elements__filters">
        <form :class="{ 'panel-open': datePanelOpen || filterPanelOpen }">
            <div :class="{ 'filter-panel-open': filterPanelOpen }">
                <slot name="header" />

                <div
                    v-for="group in filterGroups"
                    class="l-elements__filter-group"
                    :class="`group-${group.group}`"
                    :key="`group-${group.group}`"
                    @clik.stop=""
                >
                    <btn
                        label="Tout"
                        :type="filterBtnType('all')"
                        :is-background-white="btnBackground('all')"
                        @click.prevent.native="reset"
                    />

                    <div
                        v-for="(category, j) in filters[group.group]"
                        :key="`category-${j}`"
                        class="l-elements__filter-items"
                        :class="`-${group.type}`"
                        ref="categories"
                    >
                        <template>
                            <input
                                class="o-input"
                                :class="{
                                    '-selected': category.id == selectedOption
                                }"
                                type="radio"
                                :value="category.id"
                                :id="`category-${category.id}`"
                                :ref="group.group"
                                @change="
                                    toggleCheck({
                                        id: category.id,
                                        group: group.group
                                    })
                                "
                                :name="group.group"
                            />
                            <label :for="`category-${category.id}`">
                                <btn
                                    :label="category.title"
                                    tag="span"
                                    :type="filterBtnType(category.id)"
                                    :is-background-white="btnBackground(category.id)"
                                    :icon-before="category.icon"
                                />
                            </label>
                        </template>
                    </div>
                </div>
            </div>

            <div class="l-elements__filter-by-date">
                <btn
                    :label="`Trier ${activeOrder}`"
                    icon-after="arrow-down"
                    class="btn-date-toggle"
                    type="dark"
                    @click.stop.prevent.native="datePanelOpen = !datePanelOpen"
                />
                <div class="filter-by-date__btns" :class="{ 'date-panel-open': datePanelOpen }">
                    <div class="l-elements__filter-items">
                        <input
                            class="o-input"
                            type="radio"
                            value="plus recent"
                            id="date-newer"
                            @change="sortByDate('newer')"
                            name="filter-date"
                        />
                        <label for="date-newer">
                            <btn
                                label="Plus récent"
                                tag="span"
                                class="btn-filter -newer"
                                :is-background-white="selectedOrder != 'newer'"
                                :class="{
                                    '-selected': selectedOrder == 'newer'
                                }"
                                :type="selectedOrder == 'newer' ? 'dark' : 'light'"
                            />
                        </label>
                    </div>
                    <div class="l-elements__filter-items">
                        <input
                            class="o-input"
                            type="radio"
                            value="plus ancien"
                            id="date-older"
                            @change="sortByDate('older')"
                            name="filter-date"
                        />
                        <label for="date-older">
                            <btn
                                label="Plus ancien"
                                tag="span"
                                class="btn-filter -older"
                                :is-background-white="selectedOrder != 'older'"
                                :class="{
                                    '-selected': selectedOrder == 'older'
                                }"
                                :type="selectedOrder == 'older' ? 'dark' : 'light'"
                            />
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Variables from "mixins/variables";
import Btn from "components/Btn";
import { DELAY } from "src/constants";

import { gsap } from "gsap/all";

export default {
    name: "",
    components: { Btn },
    data: () => ({
        activeOrder: "Plus récent",
        datePanelOpen: false,
        filterPanelOpen: false,
        selectedOrder: "newer",
        selectedOption: "all"
    }),
    props: {
        filterGroups: {
            type: Array,
            default: () => []
        },
        filters: {}
    },
    mixins: [Variables],
    mounted() {
        this.btns = gsap.utils.toArray(this.$el.querySelectorAll(".c-btn"));
        gsap.set(this.btns, {
            opacity: 0,
            x: -50
        });

        setTimeout(this.revealFilters, DELAY.FILTERS);

        document.addEventListener("click", this.closePanels);
        window.addEventListener("resizeEnd", this.updateDatePanel && this.closePanels);
    },
    methods: {
        revealFilters() {
            gsap.to(this.btns, {
                stagger: 0.1,
                opacity: 1,
                x: 0,
                duration: 0.3,
                ease: "power1.in"
            });
        },
        closePanels() {
            if (this.filterPanelOpen) {
                this.filterPanelOpen = false;
            }
            if (this.datePanelOpen) {
                this.datePanelOpen = false;
            }
            return;
        },
        btnBackground(id) {
            return this.filterBtnType(id) != "dark";
        },
        sortByDate(date) {
            this.$emit("sort-by-date", date);

            this.selectedOrder = date;
            return this.updateDatePanel(date);
        },
        filterBtnType(id) {
            // if (id == 'all')
            let type = id == this.selectedOption ? "dark" : this.isSmallScreen ? "light" : "outline";

            return type;
        },
        toggleCheck(selected) {
            this.selectedOption = selected.id;
            this.$emit("toggle-check", selected);
        },
        toggleFilterPanel() {
            this.filterPanelOpen = !this.filterPanelOpen;
        },
        updateDatePanel(date) {
            if (this.W.w < 480) this.activeOrder = "";
            else this.activeOrder = date == "newer" ? ": plus récent" : ": plus ancien";
        },
        reset() {
            this.selectedOption = "all";
            this.$emit("reset");
        }
    },
    destroyed() {
        document.removeEventListener("click", this.closePanels);
        window.removeEventListener("resizeEnd", this.updateDatePanel);
    }
};
</script>

<style lang="scss">
.l-elements {
    &__filters {
        width: 100%;
        padding: 2rem 0;
        justify-self: center;
        align-items: center;
        z-index: 5;
    }

    &__filter-group {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        transform-origin: top;
        transform: scaleY(0);
        opacity: 0;
        transition: transform 0.35s $in-out-quad, opacity 0.5s $out-quad;

        .filter-panel-open & {
            transform: scaleY(1) translateY(1rem);
            opacity: 1;
            border-radius: 0.3rem;

            .l-elements__filter-items {
                border-top: 0;
                .c-btn {
                    border: 1px solid var(--page-color);
                    border-top: 0;
                }
            }
        }

        > .c-btn {
            border-top-left-radius: $border-radius-xs !important;
            border-top-right-radius: $border-radius-xs !important;
            border: 1px solid var(--page-color);
        }

        @media #{md('sm')} {
            flex-direction: row;
            align-items: center;
            position: static;
            opacity: 1;
            transform: translate(0) !important;
            filter: initial;

            .c-btn {
                border-radius: $border-radius-xs !important;
            }
        }
    }

    form {
        display: flex;
        gap: 2rem;
        position: absolute;
        z-index: 10;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
        z-index: 0;

        &.panel-open {
            z-index: 2;
        }

        h4 {
            margin-bottom: 0.5rem;
        }

        .l-elements__filter-items {
            .c-btn {
                width: 100%;
            }
            // border-top: 1px solid currentColor;

            &:last-of-type {
                .c-btn {
                    border-bottom-left-radius: 0.3rem;
                    border-bottom-right-radius: 0.3rem;
                    margin-bottom: 2rem;
                }
            }
        }

        .o-input {
            opacity: 0;
            width: 0;

            &.-selected {
                --btn-color: white;
                --btn-bg: black;
            }
        }

        @media #{md('sm')} {
            flex-direction: column;
            justify-content: space-between;
            // align-items: center;
            position: relative;
            overflow: visible;

            .l-elements__filter-items {
                padding-left: 1rem;
                border: 0;

                &:last-of-type {
                    .c-btn {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media #{md('md')} {
            flex-direction: row;
            align-items: center;
        }
    }

    &__filter-by-date {
        z-index: 1;
        width: fit-content;

        .btn-date-toggle {
            z-index: 2;
            border-radius: $border-radius-xs !important;
            overflow: hidden;
        }
        .filter-by-date__btns {
            transform: scaleY(0);
            transform-origin: top;
            position: absolute;
            top: 100%;
            right: 0;
            transition: transform 0.35s $in-out-quad, opacity 0.5s;
            opacity: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: fit-content;
            border: 1px solid var(--page-color); // currentColor or page-color ??
            border-top: 0;
            border-radius: 0.3rem;
            overflow: hidden;
            // filter: drop-shadow(0 0 .5rem rgba($color-dark, .5));

            .l-elements__filter-items {
                padding-left: 0;

                &:first-of-type {
                    .c-btn {
                        border-top-left-radius: $border-radius-xs;
                        border-top-right-radius: $border-radius-xs;
                    }
                }
            }

            .c-btn {
                white-space: nowrap;
                margin-bottom: 0 !important;
            }

            &.date-panel-open {
                transform: scaleY(1) translateY(1rem);
                z-index: 2;
                opacity: 1;

                .c-btn {
                    border: 0;
                    border-top: 1px solid var(--page-color);
                }
                .l-elements__filter-items {
                    // border-top: 0;
                }
            }
        }
    }
}
.l-elements__filter-items {
    .is-firefox & {
        display: flex;
        justify-content: flex-start;
        label{
            width: 100%;
        }
        @media #{md('md')} {
        align-content: center;
        justify-content: center;
        }
    }
}
</style>
