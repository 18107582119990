<template>
    <div class="c-students-infos" v-if="student">
        <div class="-student-name">{{ student.title }}</div>
        <btn
            v-if="student"
            label="en savoir plus"
            type="underline"
            class="see-more-btn"
            :is-background-white="true"
            @click.native="handleClick"
        />
    </div>
</template>
<script>

import { gsap } from 'gsap/all'
import Btn from 'components/Btn'

export default {
    components: { Btn },
    props: {
        student: Object
    },
    mounted() {
        this.init()
    },
    beforeDestroy() {
        this.tl ? this.tl.kill() : ''
    },
    methods: {
        init() {

            this.itemsToReveal = [
                '.-student-name',
                '.see-more-btn'
            ]

            this.itemsToReveal.forEach(item => {
                gsap.set(item, {
                    opacity: 0,
                    y: 20
                })
            })

            this.tl = gsap.timeline({})

            setTimeout(() => {
                this.itemsToReveal.forEach(item => {

                    this.reveal(item)
                })
            }, 800)

        },
        reveal(item) {
            this.tl
                .to(item, {
                    opacity: 1,
                    y: 0,
                    duration: .5,
                    delay: -.2,
                    ease: 'power1.out'
                })
        },
        handleClick() {
            this.$emit('seeMore')
        }
    }
}
</script>

<style>
.c-students-infos {
    display: flex;
    justify-content: space-between;
}
</style>
