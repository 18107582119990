<template>
    <div class="c-student">
        <div class="c-student__inner">
            <div class="c-student__asset">
                <asset :rounded="true" :asset="student.image" />
            </div>
            <div class="c-student__infos">
                <div>
                    <h2 class="t-t4">{{ student.title }}</h2>
                    <span class="c-student__role">{{ student.role }}</span>
                </div>
                <div v-html="student.bio" class="c-studio__bio"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Asset from '../objects/Asset.vue'
export default {
    name: 'StudentBio',
    components: { Asset },
    props: {
        student: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang='scss'>
.c-student {
    --card-gap: 3rem;
    padding: var(--card-gap);
    background: var(--color-light);
    max-width: 50rem;

    &__inner{
        display: grid;
        grid-auto-flow: row;
        gap: var(--card-gap);

        @media #{md('xs')} {
            grid-auto-flow: column;
        }
    }

    &__infos {
        display: grid;
        gap: var(--card-gap);
    }

    &__asset {
        max-width: 6rem;

    }
}
</style>
