<template>
    <li class="c-hero-video-cropped">
        <app-video-background class="c-hero-video-cropped__video" :video-id="videoId" :teaser="teaser" />
    </li>
</template>

<script>
import AppVideoBackground from "objects/AppVideoBackground";
export default {
    components: {
        AppVideoBackground
    },
    props: {
        ...AppVideoBackground.props
    }
};
</script>

<style lang="scss" scoped>
.c-hero-video-cropped {
    display: flex;
    position: relative;

    justify-content: center;
    align-items: center;
    align-content: center;

    height: 100vw;
    width: 100%;
    max-width: calc(100vw - var(--grid-gutter-quadruple));
    max-height: calc(100vw - var(--grid-gutter-quadruple));

    clip-path: circle(50%);

    /* avoid thin line arround the crop */
    @media #{md('sm')} {
        max-width: calc(65vw + 2px);
        max-height: calc(65vw + 2px);
        margin-top: -1px;
        margin-left: -1px;
    }

    @media #{md('md')} {
        margin-top: -1px;
        margin-left: -1px;
        max-width: calc(700px + 2px);
        max-height: calc(700px + 2px);
    }
    &__video {
        @include transition(0.5s ease all);
        position: relative;

        opacity: 1;
        top: 0;
        right: 0;

        width: 100%;
        max-width: calc(100vw - var(--grid-gutter-double));
        left: calc(-100vw);
        @media #{md('xs')} {
            left: calc(-40%);
        }

        &:hover {
            @include transform(scale(1.1) translate(-5%, 0px));
        }
    }
}
</style>
