<template>
    <div class="b-form">
        <app-form
            formName="Contact"
            formTemplate="contact"
            subject="Questions et commentaires"
            :toEmail="data.email"
            :successText="successText"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";

import AppForm from "components/AppForm.vue";

export default {
    name: "BlockForm",
    components: { AppForm },
    props: {
        data: Object,
    },
    computed: {
        ...mapState({
            forms: (state) => state.global.forms,
        }),
        successText() {
            return this.forms ? this.forms.success : null;
        },
    },
};
</script>

<style lang="scss">
.b-form {
    width: min(55rem, 100%);
}
</style>
