<template>
  <div class="c-hero-author">
    <anim-text
      :text="copy"
      class="t-t-3"
      tag="h3"
      :visible="isVisible"
      reveal.once="true"
      :chars="false"
      :word="false"
      :lines="true"
      :is-axis-y="false"
      :duration-in="2"
    />
  </div>
</template>

<script>
import AnimText from "objects/AnimText";
export default {
  components: {
    AnimText
  },
  props: {
    copy: {
      type: String,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.c-hero-author {
  display: flex;
  margin-top: 25px;
  overflow: hidden;
}
</style>
