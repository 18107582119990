<template>
    <div class="b-featured-video-copy">
        <div v-show="data" class="b-featured-video-copy__inner | t-content">
            <div class="b-featured-video-copy__inner__category">
                <shared-program-title :data="data.program[0]" :is-visible="true" />
            </div>
            <div class="b-featured-video-copy__inner__title">
                <animated-large-copy
                    :custom-ref="`${data.id}BlockFeaturedVideoCopy`"
                    :copy="data.title"
                    title-size="2"
                    :lines="true"
                    :is-axis-y="true"
                    :is-visible="false"
                    :is-visible-on-viewport="true"
                    :is-first-line-end-left="false"
                />
            </div>
            <div class="b-featured-video-copy__inner__author">
                <p>{{ data.student[0].title }}</p>
            </div>
            <div class="b-featured-video-copy__inner__copy">
                <p v-html="data.description" />
            </div>

            <div class="b-featured-video-copy__inner__btn | -actions">
                <btn
                    tag="router-link"
                    :url="`/${data.uri}`"
                    label="En savoir plus"
                    type="dark"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AnimatedLargeCopy from "components/AnimatedLargeCopy/AnimatedLargeCopy";
import SharedProgramTitle from "components/Shared/SharedProgramTitle";
import Btn from "components/Btn";
export default {
    components: {
        AnimatedLargeCopy,
        Btn,
        SharedProgramTitle
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.b-featured-video-copy {
    --content-actions-extra-gap: var(--grid-gutter-half);
    &__inner {
        padding-top: var(--grid-gutter-double);
    }
}
</style>
