<template>
    <header class="l-header | grid" :style="`--nav-height: ${navHeight}px`">
        <div class="l-header__inner">
            <app-logo class="l-header__logo" :logo="W.w < 992 ? 'emcv-logo-mobile' : 'emcv-logo'" />
            <app-nav :isSmallScreen="isSmallScreen" :navList="navList" class="l-header__mav" />
            <div class="l-header__actions">
                <btn
                    tag="router-link"
                    v-if="inscription"
                    :url="inscription.url || '/pages/inscription'"
                    title="Inscription"
                    class="l-nav__link -inscription"
                    :type="btnType(inscription.url || '/pages/inscription')"
                    :is-background-white="true"
                    label="Inscription"
                    exact
                    itemprop="url"
                />
                <burger-menu @click.native.stop="toggleNav" />
            </div>
        </div>
        <div v-if="page.section" class="l-header__content">
            <div v-if="page.section == 'home' && page.videos" class="p-home__hero">
                <home-hero :slides="page.videos" />
            </div>
            <app-hero v-else-if="page.section != 'video' && page.section != 'article'" :page="page" />
        </div>
    </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Btn from "components/Btn";
import AppLogo from "objects/AppLogo";
import AppHero from "layout/AppHero";
import AppNav from "layout/AppNav";

import Variables from "src/mixins/variables";
import BurgerMenu from "objects/BurgerMenu.vue";
import HomeHero from "../home/HomeHero.vue";
import { DELAY } from "src/constants";

export default {
    name: "AppHeader",
    components: {
        Btn,
        AppNav,
        AppLogo,
        AppHero,
        BurgerMenu,
        HomeHero
    },
    data: () => ({
        currentScroll: 0,
        navIsOpen: false,
        isHeroBgWhite: false,
        navHeight: 40
    }),
    mixins: [Variables],
    computed: {
        ...mapState({
            nav: state => state.global.navs,
            page: state => state.global.currentPage
        }),
        ...mapGetters({
            isLoading: "loader/isLoading",
            isLoaderCompleted: "loader/isLoaderCompleted"
        }),
        inscription() {
            return this.nav.filter(({ entry }) => entry.title.trim().toLowerCase() == "inscription");
        },
        navList() {
            return this.nav.filter(({ entry }) => entry.title.trim().toLowerCase() != "inscription");
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        this.$nextTick(() => {
            this.getHeaderHeight();
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
    methods: {
        btnType(url) {
            const type = this.$route.path == url ? "dark" : url.includes("inscription") ? "outline" : "transparent";
            return type;
        },
        openNav() {
            this.navIsOpen = true;

            this.currentScroll = window.scrollY;
            document.documentElement.style.overflowY = "hidden";
        },
        closeNav() {
            if (this.navIsOpen) {
                this.navIsOpen = false;

                // Add previous scroll
                document.documentElement.style.overflowY = "scroll";
                window.scrollTo(0, this.currentScroll);
            }
        },
        toggleNav() {
            if (this.navIsOpen) this.closeNav();
            else this.openNav();
        },
        getHeroBgHeight() {
            this.$nextTick(() => {
                const bgHeight = document.querySelector(".o-bg").getBoundingClientRect().height;

                if (bgHeight < 10) this.isHeroBgWhite = true;
                else this.isHeroBgWhite = false;
            });
        },
        ////////////////////////////////
        //       START GET HERO
        ////////////////////////////////
        getHeaderHeight() {
            // calculate hero height from this component to avoid potential issue
            this.navHeight = document.querySelector(".l-header__inner").getBoundingClientRect().height;
        },

        ////////////////////////////////
        //       END GET HERO
        ////////////////////////////////

        onResize() {
            this.getHeaderHeight();
        }
    },
    watch: {
        isLoaderCompleted: {
            handler(loaded) {
                if (loaded) {
                    setTimeout(this.getHeroBgHeight, DELAY.NAVS);
                }
            },
            immediate: true
        },
        navIsOpen(isOpen) {
            this.$emit("toggleNav", isOpen);
        },
        $route() {
            this.closeNav();
        }
    }
};
</script>

<style lang="scss">
.l-header {
    padding-top: var(--grid-gutter-half);
    z-index: 3;

    .nav-is-open & {
        z-index: 500;
    }

    .l-header__inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media #{md('sm')} {
            flex-direction: row;
        }
    }

    .l-header__logo,
    .l-header__actions {
        min-height: 4.8rem;
    }

    .l-header__logo {
        @include transform(translateY(-100%));
        opacity: 0;
        display: flex;
        align-items: center;
        z-index: 501;
    }

    .l-header__actions {
        display: flex;
        align-items: center;
        z-index: 501;

        .-inscription {
            @include transform(translateY(-100%));
            opacity: 0;
        }

        @media #{md("sm", "max")} {
            position: absolute;
            top: 0;
            right: 0;

            .o-burger {
                margin-left: 1rem;
            }
        }

        @media #{md("sm")} {
            position: relative;
        }
    }
}
</style>
