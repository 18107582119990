<template>
    <div
        class="c-home-progress"
        :class="{ 'c-home-progress--completed': progression >= 100, 'c-home-progress--loader': isLoader }"
    >
        <div
            class="c-home-progress__bar"
            :class="`c-home-progress__bar--${colour}`"
            :style="{ width: progression + '%' }"
        />
    </div>
</template>

<script>
export default {
    props: {
        progression: {
            type: Number,
            required: false,
            default: 0
        },
        colour: {
            type: String,
            required: false,
            default: "blue"
        },
        isLoader: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.c-home-progress {
    --bar-transition: "background-color" 1s ease-in-out;
    --bar-left: calc(var(--grid-gutter) * -1);

    position: absolute;
    display: flex;
    height: 9px;
    width: 100vw;
    bottom: 0rem;
    left: var(--bar-left);

    @media #{md('xl')} {
        left: -20vw;
        width: 120vw;
    }

    &--loader {
        --bar-left: 0;
        --bar-transition: 0.5s ease-in-out "background-color", 1s ease width;
    }

    &__bar {
        @include transition(var(--bar-transition));
        height: 100%;
        mix-blend-mode: multiply;

        &--blue {
            background-color: adjust-color($color: $color-blue, $lightness: -10%);
        }
        &--green {
            background-color: adjust-color($color: $color-green, $lightness: -10%);
        }
        &--brown {
            background-color: adjust-color($color: $color-brown, $lightness: -10%);
        }
    }
}
</style>
