<template>
  <i :class="className">
    <svg :class="`svg-${icon}`">
      <title v-if="title">{{ title }}</title>
      <use v-bind:xlink:href="path"></use>
    </svg>
  </i>
</template>

<script>
export default {
  name: "Icon",
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    }
  },
  computed: {
    path() {
      return `/dist/svg/sprite.svg#svg-${this.icon}`;
    },
    className() {
      let classname = `o-icon -${this.icon}`;

      if (this.size) {
        classname += ` -${this.size}`;
      }
      return classname;
    }
  }
};
</script>

<style lang="scss">
.o-icon {
  display: inline-block;
  vertical-align: middle;

  svg {
    display: block;
    width: var(--svg-width, 1em);
    height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
    fill: currentColor;
  }
}

/*----------  SVG Sizes  ----------*/

// // Socials
// .svg-instagram {
//    --svg-width: 1em;
//    --svg-ratio: 16/16;
// }

.svg-accessibility-icon {
    --svg-width: 1.5rem;
}

.svg-play,
.svg-record,
.svg-stop {
    --svg-ratio: 1 / 1;
}

.svg-emcv-logo {
  --svg-width: 14.5rem;
  --svg-ratio: 86/145;
}

.svg-emcv-logo-mobile {
  --svg-width: 9.9rem;
  --svg-ratio: 27/99;
}

.svg-emcv-logo-small {
  --svg-width: 3rem;
  --svg-ratio: 17/30;
}

.svg-logo-rdl {
    --svg-width: 12.5rem;
    --svg-ratio: 373/1973;
}

.svg-logo-collegia {
    --svg-width: 7rem;
    --svg-ratio: 761/1595;
}
</style>
