<template>
    <div class="home">
        <app-section class="p-home__intro">
            <home-intro :heading="page.heading || page.title" :image="page.image" />
        </app-section>

        <app-section class="p-home__programs">
            <home-programs />
        </app-section>

        <app-builder v-if="page.sections && page.sections.length" :sections="page.sections" />
    </div>
</template>

<script>
import { SECTION_HANDLES } from "src/constants";

import AppBuilder from "layout/AppBuilder.vue";
import AppSection from "layout/AppSection";
import HomeIntro from "@/templates/home/HomeIntro";

import HomePrograms from "@/templates/components/HomePrograms/HomePrograms";

export default {
    name: "Home",
    components: {
        AppBuilder,
        AppSection,
        HomeIntro,
        HomePrograms
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    },
    props: {
        page: Object
    },
    data() {
        return {
            SECTION_HANDLES
        };
    }
};
</script>

<style lang="scss">
.p-home {
    display: flex;
    flex-direction: column;

    &__hero {
        display: flex;

        margin-left: calc(0px - var(--grid-gutter-half));
        width: calc(100% + var(--grid-gutter));
    }

    .l-builder {
        --builder-section-count-start: 1;
    }

    @media #{md('sm')} {
        .p-home__programs {
            padding-bottom: var(--grid-gutter);
        }

        #section-en-lumiere {
            .-s__title {
                position: absolute;
                + .-b {
                    margin-top: 0 !important;
                }
            }
        }
    }
}
</style>
