/*
 * Intro video
 */

// State
const state = {
    heroVideoLoaded: 0,
    heroVideosLength: 0
};

// Getters
const getters = {
    isHomeHeroReady: state => state.heroVideoLoaded >= state.heroVideosLength && state.heroVideosLength > 0
};

// Actions
const actions = {
    updateHeroVideoLoaded(store) {
        store.commit("setHeroVideoLoaded", store.state.heroVideoLoaded + 1);
    }
};

// Mutations
const mutations = {
    setHeroVideoLoaded(state, value) {
        state.heroVideoLoaded = value;
    },
    setHeroVideosLength(state, quantity) {
        state.heroVideosLength = quantity;
    },
    resetHomeHeroStore(state) {
        state.heroVideoLoaded = 0;
        state.heroVideosLength = 0;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
