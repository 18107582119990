<template>
    <div class="c-home-intro" :class="{ '-revealed': isRevealed }" v-reveal.once>
        <div class="c-home-intro__inner">
            <animated-large-copy
                :custom-ref="`homeIntro-${copy}`"
                :copy="heading || copy"
                :image="image"
                :is-img-circle="false"
                :is-last-line-above-img="true"
                :lines="true"
                :is-axis-y="false"
                :is-visible="false"
                :is-visible-on-viewport="true"
                vertical-position="bottom"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import AnimatedLargeCopy from "@/templates/components/AnimatedLargeCopy/AnimatedLargeCopy";
export default {
    components: {
        AnimatedLargeCopy
    },
    props: {
        image: null,
        heading: null
    },
    data() {
        return {
            copy: "Nous sommes l’école des métiers du cinéma",
            isRevealed: false
        };
    },
    computed: {
        ...mapGetters({
            isLoaderCompleted: "loader/isLoaderCompleted"
        })
    },
    watch: {
        isLoaderCompleted(bool) {
            bool ? (this.isRevealed = true) : null;
        }
    }
};
</script>

<style lang="scss">
.c-home-intro {
    @include shared-reveal-animation-css;
    display: flex;
    flex: 1;
    &__inner {
        display: flex;
        flex: 1;
        justify-content: space-between;
        width: 100%;
        padding-top: var(--grid-gutter-triple);
        /* because of the image add extra padding */
        padding-bottom: var(--grid-gutter-quadruple);
    }
}
</style>
