<template>
    <app-section
        class="p-program__inner"
        :class="{'extra-padding': page.inscriptionRunning}"
    >
        <app-builder
            v-if="page.sections && page.sections.length"
            :sections="page.sections"
        />
    </app-section>
</template>

<script>
import AppBuilder from 'layout/AppBuilder.vue'
import AppSection from 'layout/AppSection.vue'

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Program',
    components: {
        AppBuilder,
        AppSection
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    })
};

</script>

<style lang="scss">

.p-program__inner {
    &.extra-padding > * {
        padding-top: calc(7rem + var(--grid-gutter-double));
    }
}

</style>
