<template>
    <footer class="l-footer">
        <div class="l-footer__inner | grid" :class="{ '-inscription-banner': inscription }">
            <app-footer-left :address="address" :socials="socials" />

            <div class="l-footer__right" v-if="inscription">
                <div class="-inscription-text">
                    <p>{{ inscription.inscriptionSubTitle }}</p>
                    <h4 class="t-t3">{{ inscription.inscriptionTitle }}</h4>
                    <btn
                        v-if="inscription.inscriptionPage.length"
                        label="inscription"
                        :is-background-white="false"
                        tag="router-link"
                        :url="'/' + inscription.inscriptionPage[0].uri"
                    />
                </div>
                <asset class="-inscription-image" :asset="inscription.inscriptionImage" />
            </div>
        </div>
    </footer>
</template>
<script>
import { mapState } from "vuex";

import AppFooterLeft from "components/AppFooter/AppFooterLeft";
import Asset from "../objects/Asset.vue";
import Btn from "components/Btn";

export default {
    components: { AppFooterLeft, Asset, Btn },
    name: "AppFooter",
    data: () => ({
        socials: null,
        address: null,
        inscription: null,
        bannerHeight: 0,
        lastSection: null
    }),
    computed: {
        ...mapState({
            settings: state => state.global.settings
        })
    },
    methods: {
        setDatas() {
            const { facebook, instagram, youtube, vimeo, ...other } = this.settings;

            this.address = {};
            this.inscription = {};

            for (let item in other) {
                if (item.includes("inscription")) {
                    this.inscription[item] = other[item];
                } else {
                    this.address[item] = other[item];
                }
            }

            const socials = [facebook, instagram, youtube, vimeo].filter(
                social => social != undefined && social.length > 1
            );

            this.socials = socials.map(social => {
                const nameIndex = social.search(/(fb|facebook|instagram|youtube|vimeo)/g),
                    name = social.slice(nameIndex).split(".")[0];

                return {
                    name: name == "fb" ? "facebook" : name,
                    icon: name == "fb" ? "facebook" : name,
                    url: social
                };
            });
        }
    },
    watch: {
        settings: "setDatas"
    }
};
</script>

<style lang="scss">
.l-footer {
    .-list {
        @include reset-list;

        &-title {
            padding-left: 1.5rem;
        }

        li + li {
            margin-top: 0.8rem;

            &.-accessibility {
                margin-top: 2rem;
            }
        }
    }

    .c-btn {
        white-space: nowrap;
    }
}

.l-footer__inner {
    display: flex;
    flex-direction: column;

    @media #{md('md')} {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        .l-footer__right {
            order: 2;
            grid-column: 8/13;
            //margin-top: 0;
        }
    }
}

.l-footer__logo-wrapper {
    grid-column: 1/5;
    grid-row: 1/2;
    position: static;

    @media #{md('md')} {
        grid-column: 1/2;
        // grid-row: 1/2;
    }
}

.l-footer__navList {
    grid-column: 3/5;
    grid-row: 2/3;
    justify-self: center;

    .l-footer__social-link {
        font-weight: 400;
        padding: 0.5rem;
        margin-left: 1rem;
    }

    @media #{md('md')} {
        grid-column: 3/4;
        grid-row: 1/2;
    }
}

.l-footer__right {
    display: flex;
    align-self: end;
    width: 100%;
    height: calc(100% + var(--grid-gutter) * 2);
    margin-top: var(--footer-negative-gap);
    color: white;
    background: black;

    .c-btn {
        --btn-color: var(--color-light);
        width: fit-content;

        &:hover {
            color: var(--color-dark) !important;
        }

        --btn-color-ripple: var(--color-light);
    }
    .-inscription-text {
        position: absolute;
        top: 50%;
        left: var(--grid-gutter);
        z-index: 1;
        display: grid;
        gap: var(--grid-gutter);
        max-width: 80%;
        transform: translateY(-50%);

        p {
            max-width: 21ch;
        }
        .t-t3 {
            max-width: 18ch;
        }
    }

    .-inscription-image {
        justify-self: flex-end;
        margin-left: auto;
        transform: translateX(var(--grid-gutter));
        width: 50%;

        .o-asset__img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            min-height: 33rem;
        }

        @media #{md('sm')} {
            transform: translateX(0);
        }
    }
}
</style>
