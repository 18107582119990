<template>
    <li class="programs-card-list">
        <div v-if="title" class="programs-card-list__title">
            <p>
                <b>{{ title }}</b>
            </p>
        </div>
        <div class="programs-card-list__copy">
            <p>{{ copy }}</p>
        </div>
    </li>
</template>

<script>
export default {
    props: {
        copy: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.programs-card-list {
    display: flex;
    flex-direction: column;
    padding-top: var(--grid-gutter-half);
    b {
        text-transform: uppercase;
        margin-bottom: 2rem;
    }
}
</style>
