<template>
    <div class="c-footer-left">
        <ul class="c-footer-left__inner">
            <li class="c-footer-left__inner__logo-wrapper">
                <app-logo class=" -footer-logo" />
            </li>
            <li class="c-footer-left__inner__address | t-content">
                <h4 class="t-t5 -uppercase">Nous joindre</h4>
                <div class="logos">
                    <icon icon="logo-rdl" />
                </div>
                <div v-if="address">
                    <p>
                        <span class="-adress-link" v-html="address.address"></span>
                        <span class="-adress-link" v-text="address.phone"></span>
                        <!-- TODO: TMP Hide email -->
                        <!-- <span
                                class="-adress-link -email"
                                v-text="address.email"
                            ></span> -->
                    </p>
                </div>
            </li>
            <li class="c-footer-left__inner__navList | t-content" v-if="socials">
                <h4 class="t-t5 -uppercase | -list-title">Nous suivre</h4>
                <ul class="-list">
                    <li v-for="(social, i) in socials" :key="i">
                        <btn
                            tag="a"
                            :url="social.url"
                            :icon-before="social.icon"
                            type="transparent"
                            :is-background-white="true"
                            class="c-footer-left__inner__social-link -small"
                        >
                            {{ capitalize(social.name) }}
                        </btn>
                    </li>
                    <li class="-accessibility">
                        <btn
                            type="outline"
                            tag="router-link"
                            url="/pages/accessibilite"
                            icon-before="accessibility-icon"
                            class="-small"
                            :is-background-white="true"
                        >
                            Accessibilité
                        </btn>
                    </li>
                </ul>
            </li>
        </ul>
        <p class="c-footer-left__inner__copyright">
            {{ year }} &copy; Tous droits réservés.
            <br />
            École des métiers du cinéma et de la vidéo
        </p>
    </div>
</template>

<script>
import { capitalize } from "src/utils";

import AppLogo from "objects/AppLogo";
import Btn from "components/Btn";
import Icon from "objects/Icon";
export default {
    components: {
        AppLogo,
        Btn,
        Icon
    },
    props: {
        address: {
            type: Object,
            required: false,
            default: null
        },
        socials: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        year: () => new Date().getFullYear()
    },
    methods: {
        capitalize
    }
};
</script>

<style lang="scss">
.c-footer-left {
    --grid-column: 1/13;
    --margin-bottom-footer-left: var(--grid-gutter-triple);
    --order-footer: 2;

    grid-column: 1/13;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    margin-bottom: var(--margin-bottom-footer-left);
    padding-top: var(--grid-gutter);

    grid-column: var(--grid-column);

    order: var(--order-footer);
    .-inscription-banner & {
        --grid-column: 1/8;
        --order-footer: 1;
    }

    @media #{md('md')} {
        --margin-bottom-footer-left: var(--grid-gutter);
        margin-bottom: var(--margin-bottom-footer-left);
    }

    &__inner {
        --gap-inner-footer: var(--grid-gutter);
        @include reset-list;
        display: grid;
        grid-auto-flow: column;
        row-gap: var(--grid-gutter-double);

        &__address {
            grid-column: 1/3;
            grid-row: 2/3;
            font-size: 1.2rem;

            li:first-of-type {
                margin-left: -1.5rem;
            }

            .-adress-link {
                display: block;
                max-width: 30ch;
                padding-top: 1rem;
            }

            .logos {
                display: flex;
                align-items: center;

                i:not(:last-child) {
                    margin-right: var(--content-gap);
                }

                @media #{md('sm', 'max')} {
                    flex-direction: column;
                    align-items: flex-start;

                    i:not(:last-child) {
                        margin: 0 0 var(--content-gap);
                    }
                }
            }

            @media #{md('md')} {
                grid-column: 2/3;
                grid-row: 1/2;
            }
        }

        &__logo-wrapper {
            --logo-wrapper-row: 1/5;
            grid-column: var(--logo-wrapper-row);
            grid-row: 1/2;
            position: static;

            @media #{md('md')} {
                --logo-wrapper-row: 1/2;
            }
        }

        &__navList {
            grid-column: 3/5;
            grid-row: 2/3;
            justify-self: center;

            .c-footer__social-link {
                font-weight: 400;
                padding: 0.5rem;
                margin-left: 1rem;
            }

            @media #{md('md')} {
                grid-column: 3/4;
                grid-row: 1/2;
            }
        }

        &__copyright {
            grid-column: 1/3;
            bottom: calc(-1 * var(--grid-gutter));

            @media #{md('md')} {
                bottom: 0;
            }
        }
    }
}

.t-content {
    --content-gap: 3rem;
}
</style>
