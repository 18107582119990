<template>
    <div class="c-inscription-banner">
        <div class="c-inscription-banner__inner">
            <div ref="content" class="-title">
                <icon icon="record" />
                <h3 class="t-t4">Inscription en cours</h3>
            </div>
            <div ref="content" v-if="program.limitDate">
                <h4 class="-heading">Limite d'inscription</h4>
                <p class="-value">{{ limitDate }}</p>
            </div>
            <div ref="content" v-if="program.formationStart">
                <h4 class="-heading">Début de la formation</h4>
                <p class="-value">{{ formationStart }}</p>
            </div>
            <div ref="content">
                <btn
                    tag="a"
                    label="inscription"
                    type="outline"
                    :url="program.inscriptionLink"
                />
            </div>

        </div>
    </div>
</template>

<script>
import Btn from './Btn.vue'
import Icon from 'objects/Icon.vue'
import { formatDate } from 'src/utils'

import { gsap } from 'gsap/all'

export default {
    name: 'InscriptionBanner',
    components: { Btn, Icon, },
    props: {
        program: Object
    },
    computed: {
        limitDate() {
            return formatDate(this.program.limitDate, { month: 'long' })
        },
        formationStart() {
            return formatDate(this.program.formationStart, { month: 'long' })
        },
    },
    mounted() {
        gsap.set(this.$el, { opacity: 0, x: -200 })
    },
    methods: {
        revealBanner() {
            gsap.to(this.$el, {
                opacity: 1,
                duration: .5,
                x: 0,
                ease: 'sine.out'
            })
        }
    },
}
</script>

<style lang="scss">

.c-inscription-banner {
    display: flex;
    background: var(--color-dark);
    color: var(--color-light);
    padding: max(var(--grid-gutter), 3rem);
    border-radius: $border-radius-xs;
    width: 100%;
    transform: translateY(calc(50% + var(--grid-gutter-double)));

    &__inner {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: space-between;
        align-items: center;
        gap: var(--grid-gutter);

        @media #{md('sm')} {
            grid-auto-columns: auto;
            grid-auto-flow: column;
            width: 100%;
        }
    }

    .-title {
        display: grid;
        grid-auto-flow: column;
        width: fit-content;
        align-items: center;

        .o-icon {
            color: var(--page-color);
            padding-right: var(--grid-gutter-half);

            svg {
                --svg-width: 1.5em;
            }
        }
    }

    .-heading {
        font-size: fs('small');
    }

    .c-btn {
        --btn-color: var(--color-light);

        &:hover {
            --btn-color: var(--color-dark);
        }
    }

}
</style>
