<template>
    <div class="c-teacher-infos" v-if="teacher">
        <div class="left c-teacher-infos__info">
            <div class="teacher-thumbnail">
                <asset :asset="teacher.image"  />
            </div>
            <div>
                <span class="teacher-role">{{ teacher.role }}</span>
                <h1 class="t-t3 teacher-name">{{ teacher.title }}</h1>
            </div>
        </div>
        <div class="right c-teacher-infos__bio">
            <h2 class="teacher-bio-heading">Biographie</h2>
            <div class="teacher-bio">
                {{ teacher.bio}}
            </div>
        </div>
    </div>
</template>

<script>
import Asset  from 'objects/Asset'
import { gsap } from 'gsap/all'

export default {
    components: { Asset },
    props: {
        teacher: Object
    },
    mounted() {
        this.init()
    },
    beforeDestroy() {
        this.tl ? this.tl.kill() : ''
    },
    methods: {
        init() {

            this.itemsToReveal = [
                '.teacher-thumbnail',
                '.teacher-role',
                '.teacher-name',
                '.teacher-bio-heading',
                '.teacher-bio',
            ]

            this.itemsToReveal.forEach(item => {
                gsap.set(item, {
                    opacity: 0,
                    y: 20
                })
            })

            this.tl = gsap.timeline({})

            setTimeout(() => {
                this.itemsToReveal.forEach(item => {

                    this.reveal(item)
                })
            }, 800)
        },
        reveal(item) {
            this.tl
                .to(item, {
                    opacity: 1,
                    y: 0,
                    duration: .5,
                    delay: -.2,
                    ease: 'power1.out'
                })
        }
    }
}
</script>

<style lang="scss">
.c-teacher-infos {
    display: grid;
    gap: 3rem;
    margin-bottom: var(--grid-gutter);
    max-height: max(calc(100vh - var(--video-height) - 6rem), 20rem);
    overflow: auto;

    @media #{md('sm')} {
        grid-auto-flow: column;
    }

    .left {
        display: grid;
        height: fit-content;
        gap: 2rem;
        grid-auto-flow: column;
        align-items: center;
        justify-content: start;
    }

    .teacher-role {
        margin-bottom: 1rem;
        display: inline-block;
    }

    .teacher-bio {
        margin-bottom: var(--grid-gutter);
        @media #{md('sm')} {
            columns: 2;
        }
    }

    .teacher-bio-heading {
        margin-bottom: 1.5rem;
        font-size: 1.2rem;
        text-transform: uppercase;
    }

    .teacher-thumbnail {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        overflow: hidden;
    }
}
</style>
