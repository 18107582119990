<template>
    <div class="home-programs-card-img">
        <asset :asset="image" />
    </div>
</template>

<script>
import Asset from 'objects/Asset.vue';
export default {
    components: {
        Asset,
    },
    props: {
        image: {
            type: Object | Array
        }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style lang="scss">
.home-programs-card-img {
    width: 100%;
    height: 100%;
    // position: absolute;

    // img {
    //     position: absolute;

    //     object-fit: cover;
    //     height: 100%;
    //     min-width: 100%;
    // }
}
</style>
