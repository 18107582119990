<template>
    <section class="l-section">
        <div class="grid">
            <div class="l-section__content">
                <slot />
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'AppSection',
};

</script>

<style lang="scss">

.l-section {}

</style>
