<template>
    <div class="c-hero-video">
        <div class="c-hero-video__inner">
            <div class="c-hero-video__inner__crop">
                <home-hero-video-list :slides="slides" :current-slide="currentSlide" :length-array="lengthArray" />
            </div>
        </div>
        <button
            @click="goToNextSlide"
            class="c-hero-video__inner__decorative"
            data-attribute="Aller sur la slide suivante"
        >
            <div class="c-hero-video__inner__decorative__arrow" />
        </button>
    </div>
</template>

<script>
import { gsap } from "gsap/all";

import { mapGetters } from "vuex";

import HomeHeroVideoList from "components/HomeHero/HomeHeroVideo/HomeHeroVideoList";

export default {
    components: {
        HomeHeroVideoList
    },
    props: {
        slides: {
            type: Array,
            required: true
        },
        currentSlide: {
            type: Number,
            required: false,
            default: 0
        },
        lengthArray: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            decorativeAnimation: null,
            isDecorativeAnimationStarted: false
        };
    },

    computed: {
        ...mapGetters({
            isLoaderCompleted: "loader/isLoaderCompleted",
            isHomeHeroReady: "hero/isHomeHeroReady"
        })
    },
    watch: {
        isLoaderCompleted: {
            handler(loading) {
                loading ? (this.initCircleAnimation(), this.triggerAnimation()) : null;
            },
            immediate: true
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
    },
    beforeDestroy() {
        this.killAnimation();
        window.removeEventListener("resize", this.onResize);
    },

    methods: {
        ////////////////////////////////
        //       START INIT TIMELINE
        ////////////////////////////////
        initCircleAnimation() {
            this.decorativeAnimation = gsap.timeline({ paused: true });

            const heroVideoList = document.getElementsByClassName("c-home-hero__inner")[0];

            if (!heroVideoList) return;

            const heroVideoListRect = heroVideoList.getBoundingClientRect();

            const desktopDecoPos = heroVideoListRect.width + heroVideoListRect.x - 700 / 2 - 70;
            window.innerWidth <= 767 ? this.customizeAnimation("100%") : this.customizeAnimation(desktopDecoPos);

            this.isLoaderCompleted && !this.isDecorativeAnimationStarted ? this.startDecorativeAnimation() : null;
        },

        customizeAnimation(xPos) {
            this.decorativeAnimation.to(
                ".c-hero-video__inner__decorative",

                {
                    opacity: 1,
                    x: xPos,
                    duration: 1,
                    ease: "power3.out"
                }
            );
        },
        ////////////////////////////////
        //       END INIT TIMELINE
        ////////////////////////////////

        ////////////////////////////////
        //       START PLAY TIMELINE
        ////////////////////////////////
        triggerAnimation() {
            this.isLoaderCompleted &&
            this.isHomeHeroReady &&
            this.decorativeAnimation &&
            !this.isDecorativeAnimationStarted
                ? this.startDecorativeAnimation()
                : null;
        },
        startDecorativeAnimation() {
            this.isDecorativeAnimationStarted = true;
            this.decorativeAnimation.play();
        },
        ////////////////////////////////
        //       END PLAY TIMELINE
        ////////////////////////////////

        ////////////////////////////////
        //       START ON RESIZE OR DESTROY
        ////////////////////////////////

        onResize() {
            this.resetAnimationOnResize();
        },
        resetAnimationOnResize() {
            this.decorativeAnimation ? this.killAnimation() : null;
            this.restartAnimation();
        },
        killAnimation() {
            this.decorativeAnimation.kill();
            this.decorativeAnimation = null;
        },
        restartAnimation() {
            this.initCircleAnimation();
            this.isDecorativeAnimationStarted
                ? (this.decorativeAnimation.seek(100), this.decorativeAnimation.pause())
                : null;
        },
        ////////////////////////////////
        //       END ON RESIZE OR DESTROY
        ////////////////////////////////

        goToNextSlide() {
            this.$emit("goToNextSlide");
        }
    }
};
</script>

<style lang="scss" scoped>
.c-hero-video {
    display: flex;
    &__inner {
        display: flex;
        padding-top: calc(var(--grid-gutter) * 2);
        @media #{md('md')} {
            padding-top: 0;
        }
        &__crop {
            display: flex;
            position: relative;
            height: 100vw;

            max-width: calc(100vw - var(--grid-gutter-quadruple));
            max-height: calc(100vw - var(--grid-gutter-quadruple));
            clip-path: circle(50%);
            /* above the circle */
            z-index: 2;
            overflow: hidden;

            @media #{md('sm')} {
                max-width: 65vw;
                max-height: 65vw;
            }
            @media #{md('md')} {
                max-width: 700px;
                max-height: 700px;
            }
        }
        &__decorative {
            --hero-decorative-pointer-event: none;
            --decor-scale: 1;
            --scale-arrow: 0;
            @include borderRadius(50%);
            position: absolute;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;
            max-width: calc(100vw - var(--grid-gutter-quadruple));
            max-height: calc(100vw - var(--grid-gutter-quadruple));

            top: var(--grid-gutter-double);
            left: var(--grid-gutter);

            pointer-events: var(--hero-decorative-pointer-event);

            z-index: 1;

            @media #{md('sm')} {
                max-width: 65vw;
                max-height: 65vw;
                --hero-decorative-pointer-event: auto;
            }
            @media #{md('md')} {
                max-width: 700px;
                max-height: 700px;

                top: 0px;
                left: 0px;
            }
            &:hover {
                --decor-scale: 0.8;
                --scale-arrow: 1;
            }

            &:after {
                @include transition(0.5s ease transform);
                @include full-screen-dom(absolute);
                @include transform(scale(var(--decor-scale)));
                content: " ";
                background: var(--color-light);
                border-radius: 50%;
            }

            &__arrow {
                $arrow-size: 15px;
                @include transition(0.5s ease transform);
                @include transform(scaleX(var(--scale-arrow)));
                transform-origin: center left;
                width: 0;
                height: 0;
                border-top: $arrow-size solid transparent;
                border-bottom: $arrow-size solid transparent;
                border-left: $arrow-size solid var(--color-dark);
                z-index: 1;
            }
        }
    }
}
</style>
