<template>
    <picture
        v-if="image"
        :class="className"
        :style="`--ratio: ${ratio}`"
        v-reveal.once="reveal"
    >
        <source
            v-if="image.srcset"
            :data-srcset="lazy ? image.srcset : false"
            :srcset="!lazy ? image.srcset : false"
            :type="image.mimeType"
        >
        <img
            class="o-asset__img"
            :class="{ 'lazyload' : lazy }"
            ref="image"
            :src="lazy ? '/static/images/blank.gif' : image.url"
            :data-sizes="lazy ? 'auto' : false"
            :data-src="lazy ? image.url : false"
            :alt="title ? title : image.title"
            :width="image.width"
            :height="image.height"
            @lazyloaded="loaded()"
            @load="loaded()"
        />
    </picture>
</template>

<script>
import { round } from 'src/utils'

export default {
    name: 'Asset',
    props: {
        asset: {
            type: Object|Array,
            default: () => []
        },
        url: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        lazy: {
            type: Boolean,
            default: false,
        },
        cover: {
            type: Boolean,
            default: false,
        },
        contain: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: false,
        },
        reveal :{
            type: Boolean,
            required: false,
            default: true
        },
    },
    data: () => ({
        isLoaded: false
    }),
    created() {
        this.isLoaded = this.lazy
    },
    computed: {
        className() {
            let classname = 'o-asset'

            if(this.rounded) {
                classname += ' -rounded'
            }
            if(this.cover) {
                classname += ' -cover'
            }

            if(this.contain) {
                classname += ' -contain'
            }

            if(this.lazy) {
                classname += ' -lazy'

                if(this.loaded) {
                    classname += ' is-loaded'
                }
            }
            if(this.reveal) {
                classname += ' js-reveal'
            }



            return classname
        },
        image() {
            let image = this.asset

            // If is array, take first index
            if(Array.isArray(image) && image.length > 0) {
                return image[0]
            }

            if(image && image.url) {
                return image
            }

            if(this.url) {
                return {
                    url: this.url,
                    title: this.title,
                    width: false,
                    height: false,
                }
            }
        },
        width() {
            return this.image.width
        },
        height() {
            return this.image.height
        },
        ratio() {
            if(!this.width || !this.height) {
                return null
            }

            return round(this.width / this.height, 3)
        },
        styles() {
            let styles = false

            if(this.ratio) {
                styles = `--asset-ratio: ${this.ratio};`
            }

            return styles
        },
    },
    methods: {
        loaded() {
            this.isLoaded = true
            this.$emit('loaded')
        },
    }
};

</script>

<style lang="scss">

.o-asset {
    @include transition(0.3s ease all);
    display: block;
    opacity: 0;



    &.-cover {

        .o-asset__img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &.-rounded {

        .o-asset__img {
            border-radius: 50%;
        }
    }
}

.o-asset__img {
    @include img;
}
.is-visible{
    opacity: 1;
}

</style>
