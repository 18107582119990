<template>
    <div v-if="person" class="c-person" :class="{ '-single': !isInGrid }" >
        <div
            class="c-person__media"
            :class="{'-has-video': person.videoId, '-no-image': !person.image.length }"
        >
            <asset
                v-if="person.image"
                :asset="person.image"
                :rounded="true"
                @click.native="openModal"
            />
            <div v-if="person.videoId" class="c-person__text">
                <icon icon="play" />
                <span>Qui suis-je</span>
            </div>
        </div>
        <div class="c-person__infos">
            <span v-if="person.role">{{ person.role }}</span>
            <div v-if="person.title" class="t-t4">{{ person.title }}</div>
            <!-- Display the phone and email only for ressources person  -->
            <div v-if="person.phone && !isInGrid">
                {{ person.phone }}
            </div>
            <!-- TODO: TMP Hide email -->
            <!-- <div v-if="person.email && !isInGrid">
                {{ person.email }}
            </div> -->
            <btn
                v-if="data.showContactBtn"
                label="Contactez-nous"
                tag="router-link"
                :url="this.getContactUrl()"
                class="-small"
                type="dark"
            />
        </div>
    </div>
</template>

<script>
import Asset from '../objects/Asset.vue'
import Icon from '../objects/Icon.vue'
import Btn from "components/Btn";
import { mapState } from 'vuex';

export default {
    components: { Asset, Icon, Btn },
    name: 'Person',
    props: {
        data: Object,
        isInGrid: false
    },
    computed: {
        ...mapState({
            navs : state => state.global.navs
        }),
        person() {
            if (this.data && this.data.teacher)
                return this.data.teacher[0]
        }
    },
    methods: {
        openModal() {
            if (this.person.videoId) {
                this.$store.dispatch('modal/open', { data: this.person })

            }
        },
        getContactUrl() {
           return this.navs.find( nav => nav.entry.section === 'contact').url;
        }
    }
}
</script>

<style lang="scss">

//
// Ne pas modifier les CSS ci-dessous pour le cas "une personne dans le bas d'un programme"
// Ajouter plutôt un modifier pour changer les grilles et les alignements
//
.c-person {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: max-content;
    justify-items: center;
    align-items: end;
    width: 100%;
    gap: 2rem;

    &__infos {
        display: grid;
        grid-template-rows: max-content;
        gap: .5rem;
        text-align: center;

        .c-btn {
            justify-self: start;
            margin-top: 1rem;
        }
    }

    &__media {
        max-width: 30rem;

        &.-has-video {

            .c-person__text {
                display: none;
                color: $color-light;
                background: $color-dark;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                text-transform: uppercase;
                display: grid;
                grid-auto-flow: column;
                place-content: center;
                gap: 1rem;
                opacity: 0;
                pointer-events: none;
                transition: all .5s ease;
            }

            &:hover .c-person__text {
                opacity: 1;
            }
        }
    }

    &.-single {
        align-items: center;
        width: fit-content;
        grid-auto-flow: row;

        .c-person__infos {
            text-align: left;
        }

        .c-person__media {
            width: fit-content;
            max-width: 20rem;
            margin-right: auto;
        }

        @media #{md('xs')} {
            grid-auto-flow: column;

            .c-person__media {
                max-width: auto;
            }
        }



        .-no-image {
            display: none;
        }
    }
}
</style>

