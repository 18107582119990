<template>
    <div v-if="data" class="b-text-img">
        <div class="b-text-img__text t-t6">{{ data.heading }}</div>
        <asset
            class="b-text-img__asset"
            :asset="data.image"
        />
    </div>
</template>

<script>
import Asset from 'objects/Asset'
import { gsap, ScrollTrigger } from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)

export default {
    components: { Asset },
    props: {
        data: null
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.itemsToReveal = [
                '.b-text-img__text',
                '.b-text-img__asset'
            ]

            this.revealTl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.b-text-img__asset',
                    start: 'top bottom'
                },
                onComplete: () => this.runParallax()
            })

            this.itemsToReveal.forEach(item => gsap.set(item, {
                opacity: 0,
                y: 20
            }))

            this.reveal()
        },
        reveal() {
            this.itemsToReveal.forEach(item => {
                this.revealTl.to(item, {
                    opacity: 1,
                    y: 0,
                    duration: .5,
                    ease: 'power1.out'
                })
            })


        },
        runParallax() {
            this.revealTl ? this.revealTl.kill() : ''

            this.parallaxTl = gsap.timeline({
                scrollTrigger: {
                    trigger: '.b-text-img__asset',
                    start: 'top bottom-=20%',
                    end: 'bottom top',
                    scrub: 1
                }
            })
            this.parallaxTl.to('.b-text-img__asset', {
                y: -100,
            })
        }
    },
    beforeDestroy() {
        this.parallaxTl ? this.parallaxTl.kill() : ''
    }

}
</script>

<style lang="scss">
.b-text-img {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    margin-top: var(--grid-gutter);


    &__asset {
        grid-column: 2/5;
        grid-row: 2/6;

        .o-asset {
            max-height: 100% !important;
        }
    }

    &__text {
        grid-column: 1/3;
        grid-row: 1/5;
        padding-top: 2rem;
        z-index: 4;
    }
    @media #{md('sm')} {
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows:  repeat(5, 1fr);

        &__asset {
            grid-column: 4/11;
            width: min(100%, 50vw);
        }
        &__text {
            grid-column: 1/8;
            grid-row: 2/5;
        }
    }
}
</style>
