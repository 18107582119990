<template>
    <div>
        {{ page.title }}
    </div>
</template>

<script>

import { SECTION_HANDLES } from 'src/constants'

export default {
    name: 'Programs',
    components: {
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    }),
};

</script>

<style lang="scss">

.p-programs {}

</style>
