<template>
    <div
        class="l-elements"
        :class="{
            'has-filters': hasFilters
        }"
    >
        <app-elements-filter
            v-if="hasFilters && ready"
            ref="filter"
            :filterGroups="filterGroups"
            :filters="filters"
            @toggle-check="filterElements"
            @sort-by-date="sortByDate"
            @reset="reset"
        >
            <template #header>
                <slot name="filterHeader" />
            </template>
        </app-elements-filter>
        <div class="l-elements__list" v-if="ready">
            <div v-if="$slots.listHeader" class="l-elements__list-header">
                <slot name="listHeader" v-bind="totalElements"> </slot>
            </div>

            <slot name="elements" v-bind="sortedElements">
                <card-grid :cards="sortedElements" :cardType="cardType" :class="{ 'is-loading': isBlocked }">
                </card-grid>
            </slot>

            <pagination
                ref="pagination"
                :total="totalElements"
                :perPage="currentPerPage"
                :loaded="totalLoaded"
                :currentPage="defaultPage"
                @pageChange="getElementsByPage"
                @block="isBlocked = true"
                :isBlocked="isBlocked"
            >
                <template #progressText>
                    <slot name="paginationText" />
                </template>
            </pagination>
        </div>
    </div>
</template>

<script>
import Btn from "components/Btn";
import CardGrid from "components/CardGrid";
import Pagination from "components/Pagination";
import AppElementsFilter from "./AppElementsFilter.vue";

import { mapState } from "vuex";
import _ from "lodash";

export default {
    name: "AppElements",
    components: {
        Btn,
        CardGrid,
        Pagination,
        AppElementsFilter
    },
    props: {
        filterGroups: {
            type: Array,
            default: () => []
        },
        sectionHandle: {
            type: String,
            required: true
        },
        cardType: String,
        options: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        ready: false,
        filters: {},
        filteredElements: [],
        activeFilters: {},
        searchOptions: {
            limit: 12,
            offset: 0,
            relatedToCategories: [],
            relatedToEntries: [],
            searchText: "",
            order: {
                key: "postDate",
                direction: "DESC"
            }
        },
        defaultPerPage: 0,
        currentPerPage: 0,
        defaultPage: 1,
        isBlocked: false,
        totalLoaded: 0
    }),
    created() {
        // Merge options
        this.searchOptions = {
            ...this.searchOptions,
            ...this.options
        };

        // Keep track of the default pagination
        this.defaultPerPage = this.searchOptions.limit;
        this.currentPerPage = this.defaultPerPage;

        // Look if there's a targeted page
        const page = parseInt(this.$route.query.page);
        if (page && page > 1) {
            this.defaultPage = page;
            this.searchOptions.limit = page * this.defaultPerPage; // Handle first-init post-per-page
        }

        this.getQueries(this.buildQueries());
    },
    computed: {
        ...mapState({
            filterGroupsFromStore(state) {
                return state[this.sectionHandle].filterGroups;
            },
            elements(state) {
                return state[this.sectionHandle].elements;
            },
            totalElements(state) {
                return state[this.sectionHandle].resultsCount;
            }
        }),
        hasFilters() {
            return this.filterGroups.length;
        },
        searchPagesCount() {
            if (this.searchOptions.limit && this.searchOptions.limit != 0) {
                return Math.ceil(this.totalElements / this.searchOptions.limit);
            } else return 1;
        },
        sortedElements() {
            if (!this.filteredElements || this.filteredElements.length === 0) return [];

            const orderKey = this.searchOptions.order.key;
            const orderDirection = this.searchOptions.order.direction;

            if (orderDirection == "DESC")
                return [...this.filteredElements].sort((a, b) => (a[orderKey] < b[orderKey] ? 1 : -1));

            return [...this.filteredElements].sort((a, b) => (a[orderKey] > b[orderKey] ? 1 : -1));
        }
    },
    methods: {
        sortByDate(date) {
            let direction = date == "newer" ? "DESC" : "ASC";
            this.searchOptions.order.direction = direction;
        },
        buildQueries() {
            let filterQueries = this.filterGroups.map(filter => {
                this.$set(this.activeFilters, filter.group, []);

                return filter.type == "entry" ? this.getRelatedEntries(filter.group) : this.getCategories(filter.group);
            });

            //
            const searchQuery = this.$store.dispatch(this.sectionHandle + "/searchEntries", this.searchOptions);

            return [...filterQueries, searchQuery].filter(el => el != null);
        },
        filterElements(selected) {
            let { id, group } = selected;

            let filterGroup = new Set(this.activeFilters[group]);

            // Clear the field + reset to new value
            filterGroup.clear();
            filterGroup.add(id);

            // Reset to default limit
            this.searchOptions.limit = this.defaultPerPage;

            // Update activeFilters
            this.$set(this.activeFilters, group, [...filterGroup]);

            this.$nextTick(() => this.updateSearchQuery(false));
        },
        getCategories(group) {
            const action = "/getCategoryGroup";

            return this.$store.dispatch(this.sectionHandle + action, {
                group: group,
                sections: [this.sectionHandle]
            });
        },
        getElements() {
            this.isBlocked = true;

            this.$store.dispatch(this.sectionHandle + "/searchEntries", this.searchOptions).then(results => {
                this.totalLoaded = results.length;

                this.filteredElements = results;
                this.isBlocked = false;
            });
        },
        getElementsByPage(page) {
            this.isBlocked = true;
            this.searchOptions.offset = 0;
            this.searchOptions.limit = page * this.defaultPerPage;
            this.updateSearchQuery(true);
        },
        getQueries(queries) {
            // Get categories and first entries query
            Promise.all([...queries]).then(results => {
                // Create a key for each single group under `this.filters`
                results.forEach((res, i) => {
                    if (this.filterGroups[i]) {
                        let groupHandle = this.filterGroups[i].group;
                        this.filters[groupHandle] = res;
                    }
                });

                // Get searchQuery index
                const lastIndex = results.length - 1;

                // Update elements following the searchQuery results
                this.filteredElements = results[lastIndex];

                // Set the total itemsLoaded
                this.totalLoaded = this.filteredElements.length;

                // Mark the component as `ready`
                this.ready = true;
            });
        },
        getRelatedEntries(group) {
            return this.$store.state.global[group];
        },
        updateSearchQuery(keepPage) {
            this.isBlocked = true;

            if (keepPage === false) {
                // Reset to page 1 when making a new search
                this.searchOptions.offset = 0;
                if (this.$refs.pagination) {
                    this.$refs.pagination.reset();
                }
            }

            const relatedToCategories = [];
            const relatedToEntries = [];

            Object.keys(this.filters).forEach(group => {
                const ids = [...this.activeFilters[group]].map(id => parseInt(id));

                if (ids.length) {
                    if (group != "programs") {
                        let category = {
                            group: [group],
                            id: ids
                        };
                        relatedToCategories.push(category);
                    } else {
                        let program = {
                            id: ids
                        };
                        relatedToEntries.push(program);
                    }
                }
            });

            this.searchOptions.relatedToCategories = relatedToCategories;
            this.searchOptions.relatedToEntries = relatedToEntries;
            this.searchOptions.update = true;

            // De-structure to break reactivity
            const newOptions = { ...this.searchOptions },
                isEqual = _.isEqual(newOptions, this.lastOptionsFetched);

            if (!isEqual) {
                // Fetch Only if options have changed
                this.getElements(true);
                this.lastOptionsFetched = newOptions;
            }
        },
        reset() {
            // Reset filters
            Object.keys(this.filters).forEach(filter => {
                this.$set(this.activeFilters, filter, []);

                //Uncheck all the inputs
                if (this.$refs[filter]) {
                    this.$refs[filter].forEach(input => {
                        input.checked = false;
                        input.selectedIndex = 0;
                    });
                }
            });

            // Reset search history
            this.lastOptionsFetched = null;

            this.currentPerPage = this.defaultPerPage;

            // Reset pagination
            this.$refs.pagination.reset();

            // Reset options
            this.searchOptions.limit = this.defaultPerPage;
            this.searchOptions.searchText = "";
            this.searchOptions.offset = 0;
            this.searchOptions.relatedToCategories = [];
            this.searchOptions.relatedToEntries = [];

            this.getElements();
        }
    }
};
</script>

<style lang="scss">
.l-elements {
    --elements-grid-gap: 4rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--elements-grid-gap);
    // margin-top: -15rem;
    // margin-bottom: 10rem;
    z-index: 5;

    @media #{md('sm')} {
        // margin-top: min(-25rem, -25vw);
    }

    .c-btn {
        cursor: pointer;
        border-radius: 0;
        white-space: nowrap;
        font-size: fs("small");

        @media #{md('sm')} {
            width: fit-content;
            font-size: fs("regular");
        }
    }

    // this can be deleted after March 16 to have a quick fall back
    // .c-card-grid.is-loading {
    //     .c-card__asset {
    //         @include skeleton($duration: 1.5s);

    //         .o-asset__img {
    //             opacity: 0;
    //             transition-duration: 0.3s;
    //         }
    //     }

    //     .c-card__content {

    //         .c-card__before-content * *,
    //         &-inner * * {
    //             // overflow: hidden;
    //             @include skeleton;
    //         }
    //     }
    // }

    &__list {
        margin: 0 auto;
        width: 100%;
    }
}
</style>
