<template>
    <div class="c-hero-video-list">
        <ul class="c-hero-video-list__inner" :style="`transform: translate(-${centerCurrentSlider(currentSlide)}%)`">
            <home-hero-video-cropped
                v-for="(item, index) in slides"
                :teaser="{
                    start: Number(item.teaserStartTime),
                    duration: Number(item.teaserDurationTime)
                }"
                :key="index"
                :video-id="item.videoId"
                :currentSlide="currentSlide"
                @click.native="openModal"
                ref="video"
            />
        </ul>
    </div>
</template>

<script>
import HomeHeroVideoCropped from "components/HomeHero/HomeHeroVideo/HomeHeroVideoCropped";
import { mapState } from "vuex";
export default {
    components: {
        HomeHeroVideoCropped
    },
    props: {
        slides: {
            type: Array,
            required: true
        },
        currentSlide: {
            type: Number,
            required: false,
            default: 0
        },
        lengthArray: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            transformPosition: 0
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.setToStoreVideosLength();
        });
    },
    beforeDestroy() {
        this.resetHomeHeroStore();
    },
    computed: {
        ...mapState({
            modal: state => state.modal.data
        })
    },
    methods: {
        centerCurrentSlider(currentSlide) {
            // width of each video * current slide
            return this.calculateTransformLength() * currentSlide;
        },
        calculateTransformLength() {
            // 100 / number of videos
            return 100 / this.lengthArray;
        },
        openModal() {
            this.bgVideos = this.$el.querySelectorAll("video");

            this.$store.dispatch("modal/open", { data: this.slides[this.currentSlide] });
        },
        toggleVideoState() {
            this.$nextTick(() => {
                this.bgVideos.forEach(video => {
                    this.modal ? video.pause() : video.play();
                });
            });
        },
        setToStoreVideosLength() {
            // this is used start the animation only after the videos are loaded: https://mambomambo-team.atlassian.net/browse/EMCV-224
            this.$store.commit("hero/setHeroVideosLength", this.slides.length);
        },
        resetHomeHeroStore() {
            // this is used start the animation only after the videos are loaded: https://mambomambo-team.atlassian.net/browse/EMCV-224
            // it's a bit uncessary, I only added it just in case the client remove or add a video while a user visit the website
            this.$store.commit("hero/resetHomeHeroStore", 0);
        }
    },
    watch: {
        modal: "toggleVideoState"
    }
};
</script>

<style lang="scss" scoped>
.c-hero-video-list {
    display: flex;
    background: var(--color-light);
    z-index: 2;
    &__inner {
        @include transition(0.75s ease all);
        display: flex;
        flex-direction: row;
    }
}
</style>
