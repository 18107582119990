<template>
    <router-link
        title="ÉMCV"
        aria-label="Retour à l’accueil"
        to="/"
        class="o-logo"
        ref="logo"
    >
        <icon :icon="logo" title="ÉMCV" />
    </router-link>
</template>

<script>
import Icon from "objects/Icon";

export default {
    name: "AppLogo",
    components: { Icon },
    props: {
        className: {
            type: String,
            // required: true
        },
        logo: {
            type: String,
            default: "emcv-logo",
        },
    },
    data: () => ({}),
};
</script>

<style lang="scss">
.o-logo {
    margin-right: auto;
    z-index: 100;
}
</style>
