<template>
    <div
        class="c-base-video"
        :class="{ 'c-base-video--fit': isObjectFit }"
        v-cursor.label="cursor"
        v-if="videoId"
        @click="playVideo"
    >
        <vue-plyr :options="plyrOptions" ref="plyr" v-if="isLoaded">
            <video
                :id="id"
                :preload="preload"
                :playsinline="playsinline"
                :controls="controls"
                :muted="muted"
                :loop="loop"
                :autoplay="autoplay"
                :data-poster="poster.src"
                @lazyloaded="isLoaded = true"
            >
                <source :data-size="defaultVideo.size" :src="defaultVideo.src" :type="defaultVideo.type" />
            </video>
        </vue-plyr>

        <asset v-if="isPosterVisible" class="c-base-video__poster" :asset="overwritePoster" :cover="true" />

        <div v-if="isPlayMobileVisible && !this.isVideoStarted" class="c-base-video__play">
            <div class="c-base-video__btn">
                <div>
                    <icon class="c-base-video__btn__icon" icon="play" />
                    <span ref="label" class="c-base-video__btn_label | t-sub">Visionner</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "src/event-bus";

import { DELAY } from "src/constants";

import Asset from "objects/Asset";
import Icon from "@/templates/objects/Icon";

export default {
    props: {
        displayControlsFirst: {
            type: Boolean,
            default: false,
        },
        cursorLabel: { label: "visionner", iconBefore: "play" },
        videoId: {
            type: Number | String,
            required: true,
        },
        options: {
            type: Object,
            default: () => {},
        },
        preload: {
            type: String,
            default: "auto",
            validator: (text) => ["none", "auto", "metadata"].includes(text),
        },
        muted: {
            type: Boolean,
            default: true,
        },
        loop: {
            type: Boolean,
            default: false,
        },
        playsinline: {
            type: Boolean,
            default: true,
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
        controls: {
            type: Boolean,
            default: true,
        },
        // use the image from the DB instead of the one from VIMEO
        overwritePoster: {
            type: Object | Array,
            required: false,
            default: null,
        },
        // add a circle play button on mobile only
        isPlayMobileVisible: {
            type: Boolean,
            required: false,
            default: false,
        },
        isObjectFit: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        Asset,
        Icon,
    },
    data() {
        return {
            isLoaded: false,
            error: null,
            isPaused: false,
            resize: false,
            plyrOptions: null,

            player: null,
            cursor: null,

            videoSources: null,
            posterSources: null,

            poster: null,
            defaultVideo: null,
            defaultImage: null,

            client: null,
            client_id: "7c568520c7829412502eab3acb1cde6880a18e43",
            client_secret:
                "5gapVkLEASOEaUAHWujCJBsfTnqfuNBZe2+pbzTIrmK0FsxyC+6oinxqOOGjFGJpnboQ/vwMPr1scAnlFB/japcEm5iUm2gnI+pMStk/tsJKRx33dJqNRe6Cf2bB34dd",
            access_token: "ba41f6eb901c3a417094b809eba00159",

            isVideoStarted: false, // overwrite VIMEO poster + allow poster to be cover
        };
    },
    created() {
        this.cursor = this.cursorLabel;
    },
    mounted() {
        this.defaultOptions = {
            // debug: true,
            controls: ["play", "fullscreen", "progress", "volume", "mute", "settings"],
            loadSprite: false,
            iconUrl: "/dist/svg/sprite.svg",
            iconPrefix: "svg-plyr",
        };
        this.plyrOptions = { ...this.defaultOptions, ...this.options };
        this.cursor = this.cursorLabel;
        this.createVimeo();
    },
    computed: {
        ...mapGetters({
            isLoading: "loader/isLoading",
            isLoaderCompleted: "loader/isLoaderCompleted",
        }),
        id() {
            return "video-" + this.videoId;
        },
        showCursorLabel() {
            if (this.player) return !this.player.playing;
        },
        isPosterVisible() {
            return this.overwritePoster && this.overwritePoster.url && !this.isVideoStarted;
        },
    },
    methods: {
        createVimeo() {
            const Vimeo = require("vimeo").Vimeo;
            const client = new Vimeo(this.client_id, this.client_secret, this.access_token);

            client.request(
                {
                    method: "GET",
                    path: `/videos/${this.videoId}`,
                },
                (error, body) => {
                    if (error) {
                        this.isLoaded = false;
                        this.error = error;
                        return;
                    }

                    if (body.files) {
                        // Videos
                        this.videoSources = body.files.map((file) => ({
                            size: file.width,
                            src: file.link,
                            type: file.type,
                        }));

                        this.defaultVideo =
                            this.getVideoBySize(1280) ||
                            this.getVideoBySize(1366) ||
                            this.getVideoBySize(640) ||
                            this.getVideoBySize(1080) ||
                            this.getVideoBySize(720);
                    } else {
                        // TMP to avoid any JS error while the client update their Vimeo account(or not)
                        this.defaultVideo = {
                            size: null,
                            src: body.player_embed_url,
                            type: body.type,
                        };
                    }

                    //   Posters;
                    this.posterSources = body.pictures.sizes.map((file) => ({
                        size: file.width,
                        src: file.link,
                    }));

                    // This should be depending on the viewport (avoid loading big size image)
                    this.poster = this.getPosterBySize(960);

                    //
                    this.defaultImage = this.getPosterBySize(960);

                    this.isLoaded = true;
                    this.error = null;
                    this.$emit("loaded");
                }
            );
        },

        /*------------------------------
        Start set poster
        ------------------------------*/
        isPosterCanBeOverwrite() {
            return this.overwritePoster && this.overwritePoster.url ? true : false; // true false for code readibility
        },

        getPosterBySize(size) {
            const posters = this.posterSources;
            return posters.find((poster) => poster.size === size);
        },

        getVideoBySize(size) {
            const videos = this.videoSources;
            return videos.find((video) => video.size === size);
        },
        /*------------------------------
        End set poster
        ------------------------------*/

        toggleControls() {
            if (this.player.playing) {
                this.$el.classList.remove("-hide-controls");

                this.cursor = null;
                EventBus.$emit("cursor-label-reset", this.cursor);
            }
            this.hidePoster();
        },
        setPlayer() {
            if (this.error) return;

            this.player = this.$refs.plyr.player;

            if (!this.displayControlsFirst) {
                this.$el.classList.add("-hide-controls");
            }

            this.playerControls = document.querySelectorAll(".plyr__controls");

            this.playerControls.forEach((control) => {
                control.classList.add("grid");
            });
            this.player.on("play", this.toggleControls);
            this.player.on("pause", this.toggleControls);

            this.player.on("controlshidden", () => this.$emit("controlshidden"));
            this.player.on("controlsshown", () => this.$emit("controlsshown"));
        },

        playVideo() {
            // this is used only on mobile
            this.isVideoStarted ? null : this.$refs.plyr.player.play();
        },

        hidePoster() {
            this.isVideoStarted = true;
        },
    },
    watch: {
        isLoaderCompleted: {
            handler(loaded) {
                if (loaded) {
                    setTimeout(() => this.setPlayer(), DELAY.HERO);
                }
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/vendors/vue-plyr.css";

.c-base-video {
    position: relative;
    // overflow: hidden;
    // --object-fit-video: auto; // https://mambomambo-team.atlassian.net/jira/software/projects/EMCV/boards/5?selectedIssue=EMCV-199
    --object-fit-video: contain; /* 2023-01-10 : Use contain instead of auto to avoid stretched videos */
    min-height: 20rem; /* 2023-01-10 : Avoid really small video on narrow sreens */

    &--fit {
        --object-fit-video: cover;
    }
    video {
        width: 100%;
        //max-width: calc(100vw - 2 * var(--grid-gutter));
        height: auto;
        max-height: 100%;
        margin: 0 auto;
        object-fit: var(--object-fit-video);
    }

    &__poster {
        // not crazy about this solution, but it's client bug proof
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &.-hide-controls .plyr__controls {
        opacity: 0;
        .is-touch & {
            opacity: 1;
        }
    }

    .plyr__video-wrapper {
        display: flex;
    }

    .plyr__controls {
        --plyr-color-main: black;
        --plyr-video-control-color: black;
        --plyr-video-controls-background: white;
        --plyr-range-track-height: 1rem;
        --plyr-range-thumb-height: 1rem;
        --plyr-video-progress-buffered-background: transparent;

        padding-top: 1.5rem !important;

        .plyr__progress__container {
            position: absolute;
            // margin-right: -1rem;
            height: var(--plyr-range-track-height);
            top: 0;
            transform: translate3d(0, -100%, 0);
            width: calc(100%);

            ::-webkit-slider-thumb {
                opacity: 0;
            }

            .plyr__progress {
                margin-right: -1rem;

                input[type="range"] {
                    height: var(--plyr-range-track-height);
                }

                &__buffer {
                    margin-top: 0;
                }
            }

            .plyr__tooltip {
                color: black;
                font-weight: bold;

                &:before {
                    opacity: 0;
                }
            }
        }

        [data-plyr="fullscreen"] {
            order: 4;
        }

        .plyr__menu {
            order: 2;
        }
        .plyr__volume {
            order: 3;

            input[type="range"] {
                &:after {
                    @include pseudo-el($width: 100%, $height: 60%, $bg: rgba(black, 0.1));
                    top: 19%;
                    border-radius: inherit;
                    position: absolute;
                    z-index: -2;
                }
            }
        }

        &:after {
            @include pseudo-el($width: 200%, $height: 100%);
            background: var(--plyr-video-controls-background);
            position: absolute;
            top: 0px;
            left: -25%;
            z-index: -1;
            border-bottom: $default-border;
        }
    }

    // TODO Move to an other component
    &__play {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        z-index: 1;
        width: 100%;
        height: 100%;

        justify-content: center;
        align-content: center;
        align-items: center;

        display: none;

        // .is-ios &,
        // .is-android &, // I am internally debating if I should add these little guys too.
        .is-touch & {
            display: flex;
        }
    }
    &__btn {
        $cursor-size: 12.5rem;
        $cursor-centered: -7.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $cursor-size;
        height: $cursor-size;
        background: var(--cursor-bg);
        border-radius: 50%;
        transform: scale(1);
        transition: all 0.2s $in-out-quad;
        will-change: transform;
        gap: var(--grid-gutter-half);
        pointer-events: none;

        &:before {
            @include pseudo-el($width: auto, $height: auto, $bg: $color-light);
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            border-radius: inherit;
        }

        > div {
            display: grid;
            grid-auto-flow: column;
            gap: 5px;
            align-items: center;
        }

        &__label {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            width: 100%;
            margin: auto;
            text-align: center;
            word-break: break-all;
        }
    }

    // ML: Ça entre en conflit avec le FeaturedVideo sur l'accueil
    /*
    @media #{md('md')} {
        max-height: calc(100vh - var(--infos-height, 20rem) - var(--grid-gutter));
    }
    */
}
</style>
