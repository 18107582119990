/*
 * Article
 */

//import { fetchApi } from 'src/graphql/config'
//import queries from 'src/graphql/queries'

import Crud from '../crud';
const crud = new Crud('article');

// Export module
export default {
    ...crud,
    namespaced: true,
}
