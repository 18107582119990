<template>
    <div v-if="partners.length > 0" class="b-partners">
        <slider
            v-if="sliderIsActive"
            :slides="partners"
            :fullheight="true"
            :prevNext="false"
            :options="{ wrapAround: true }"
            arrow-nav="play"
            class="-partners"
            ref="slider"
        >
            <template #slide="partner">
                <div class="b-partner">
                    <component
                        :is="partner.url ? 'a' : 'span'"
                        :href="partner.url"
                        :rel="partner.url && 'noopener'"
                        :target="partner.url && '_blank'"
                    >
                        <asset
                            v-if="partner.logo.length"
                            class="b-partner__logo"
                            :asset="partner.logo"
                            @loaded="reloadSlider"
                        />
                    </component>
                </div>
            </template>
        </slider>
    </div>
</template>

<script>
import Slider from "components/Slider";
import Asset from "../objects/Asset.vue";
export default {
    components: { Slider, Asset },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        sliderIsActive: false,
        partners: null
    }),
    created() {
        const partners = this.data.partners;
        this.partners = partners.length > 4 ? partners : [...partners, ...partners];
    },
    mounted() {
        setTimeout(() => {
            const slider = this.$refs.slider;
            if (slider) {
                slider.resize();
            }
        }, 8000);
    },
    methods: {
        reloadSlider() {
            setTimeout(() => {
                const slider = this.$refs.slider;
                if (slider) {
                    slider.setSlidesHeight();
                }
            }, 500);
        }
    },
    watch: {
        data: {
            handler() {
                if (this.data) {
                    this.$nextTick(() => {
                        this.sliderIsActive = true;
                        this.reloadSlider();
                    });
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss">
.b-partners {
    margin-left: calc(-1 * var(--grid-gutter-double));
    margin-right: calc(-1 * var(--grid-gutter-double));
}
</style>
