<template>
    <span :class="className">
        <span ref="inner" class="o-cursor__inner" :style="color">
            <span ref="content" class="o-cursor__content">
                <icon v-if="iconBefore" class="o-cursor__content__icon" :icon="iconBefore" />
                <span v-if="label" ref="label" class="o-cursor__content__label | t-sub"> {{ label }}</span>
            </span>
        </span>
    </span>
</template>

<script>
import { gsap } from "gsap/all";
import { EventBus } from "src/event-bus";
import { mapState } from "vuex";

import { isTouch } from "src/utils";

import Icon from "@/templates/objects/Icon";

export default {
    name: "AppCursor",
    components: {
        Icon
    },
    data: () => ({
        state: [],
        position: []
    }),

    mounted() {
        this.docElement = document.documentElement;

        if (isTouch) {
            return;
        }

        // Define vars
        this.$hoverable = document.querySelectorAll("a");
        this.content = false;

        this.show();

        // Document events
        document.addEventListener("mousemove", e => {
            this.move(e.clientX, e.clientY);
        });

        // Hover
        EventBus.$on("cursor-hover-in", params => {
            this.state.push({
                type: params.type ? params.type : false,
                label: params.data && params.data.label ? params.data.label : false,
                color: params.data && params.data.color ? params.data.color : false,
                iconBefore: params.data && params.data.iconBefore ? params.data.iconBefore : false
            });
        });

        EventBus.$on("cursor-hover-out", () => {
            this.state.pop();
            this.resetArrowCursor();
        });

        // Set active
        EventBus.$on("cursor-set-active", activate => {
            this.isActive = activate;
        });

        // Reset
        EventBus.$on("cursor-reset", () => {
            this.isActive = false;

            this.state = [];
        });

        // Update position
        EventBus.$on("cursor-update-position", ({ x, y }) => {
            this.move(x, y);
        });
    },
    computed: {
        ...mapState({
            isArrowCursorVisible: state => state.global.isArrowCursorVisible
        }),
        stateLength() {
            return this.state.length;
        },
        type() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].type : false;
        },
        label() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].label : false;
        },
        iconBefore() {
            return this.stateLength > 0 ? this.state[this.stateLength - 1].iconBefore : false;
        },
        color() {
            const color = this.stateLength > 0 ? this.state[this.stateLength - 1].color : false;
            return color ? `--cursor-bg: ${color};` : false;
        },
        className() {
            let className = "o-cursor";

            if (this.type) {
                className += ` -${this.type}`;
            }
            if (this.label) {
                className += " has-content";
            }
            if (this.isActive) {
                className += " is-active";
            }
            if (this.showScroll) {
                className += " -hide";
            }

            return className;
        }
    },
    methods: {
        show() {
            gsap.to(this.$el, {
                duration: 0.2,
                scale: 1,
                opacity: 1,
                onComplete: () => {
                    gsap.set(this.$el, { clearProps: "all" });
                }
            });
        },

        hide() {
            gsap.to(this.$el, {
                duration: 0.2,
                scale: 0,
                opacity: 0
            });
        },

        move(x, y) {
            gsap.to(this.$el, {
                duration: 0.6,
                x,
                y,
                ease: "expo.out"
            });

            this.position = [x, y];
        },

        /*------------------------------
       Start Manage the visibility of the arrow cursor
       ------------------------------*/
        resetArrowCursor() {
            this.isArrowCursorVisible ? this.toggleArrowCursor(false) : null;
        },
        toggleArrowCursor(bool) {
            this.$store.commit("global/setArrowCursorVisibility", bool);
        }
        /*------------------------------
       End Manage the visibility of the arrow cursor
       ------------------------------*/
    },
    watch: {
        $route() {
            // remove the last cursor state when the route changes
            this.state.pop();
            // reset arrow cursor if its visible
            this.resetArrowCursor();
        },
        state() {
            const state = [...this.state];

            if (state.length && state[state.length - 1].type == "hidden") {
                setTimeout(() => {
                    // Remove hidden state after 3s
                    this.state.pop();
                }, 3000);
            }

            if (state.length && state[state.length - 1].type == "arrow") {
                this.toggleArrowCursor(true);
                this.state.pop();
            }
        }
    }
};
</script>

<style lang="scss">
$cursor-size: 14.5rem;
$cursor-centered: -7.25rem;
.no-cursor * {
    cursor: auto;
    @media #{md('xs')} {
        cursor: none;
    }
}

/*==============================
=            Cursor            =
==============================*/

.o-cursor {
    z-index: 1000;
    position: fixed;
    top: $cursor-centered;
    left: $cursor-centered;
    pointer-events: none;
    display: none;
    opacity: 0;

    @media #{md('xs')} {
        opacity: 1;
    }

    .no-cursor & {
        display: block;
        will-change: transform;
    }

    &.has-content {
        .o-cursor__inner {
            transform: scale(1);
            transition: all 0.3s $in-out-quad;

            &:before {
                transform: scale(1);
                transition: all 0.2s $out-quad 0.1s;
                transition: all 0.3s $out-quad 0s;
            }
        }

        .o-cursor__content__label {
            transition: all 0.3s $out-sine 0.1s;
        }
        .o-cursor__content__icon {
            margin-right: 10px;
        }
    }

    &.-light {
        .o-cursor__inner {
            --cursor-bg: #{$color-light};
        }
    }

    &.-hover {
        .o-cursor__inner {
            --cursor-bg: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

            opacity: 0.5;
            transform: scale(0.5);
            transition: all 0.2s $in-out-quad;
        }
    }

    &.-hidden {
        .o-cursor__inner {
            transform: scale(0);
            transition: transform 0.2s $out-quad, background-color 0.1s $out-quad;
        }
    }

    @media (prefers-reduced-motion: reduce) {
        .o-cursor__inner {
            transition: none;
        }
    }
}

.o-cursor__inner {
    --cursor-bg: #{$color-dark};

    display: flex;
    justify-content: center;
    align-items: center;
    width: $cursor-size;
    height: $cursor-size;
    background: var(--cursor-bg);
    border-radius: 50%;
    transform: scale(0.1);
    transition: all 0.2s $in-out-quad;
    will-change: transform;

    &:before {
        @include pseudo-el($width: auto, $height: auto, $bg: $color-light);
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border-radius: inherit;
        transform: scale(0);
    }
}

.o-cursor__content {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.o-cursor__content__label {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    text-align: center;
    word-break: break-all;
}
</style>
