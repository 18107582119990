<template>
    <div v-if="sections" class="p-contact__wrapper | grid">
        <app-builder
            :sections="sections"
            class="p-contact__builder"
        >
            <template #afterBlock="block">
                <div
                    v-if="block.index == 0"
                    class="p-contact__map -circle-shape -circle-shape-1"
                >
                    <gmap-map
                        v-if="mapCoordinates"
                        class="o-map"
                        :zoom="15"
                        :center="mapCoordinates"
                        :options="googleMapsOpts"
                    >
                        <gmap-custom-marker
                            :marker="mapCoordinates"
                            alignment="center"
                            :offsetY="24"
                        >
                            <div class="o-map__marker">
                                <icon icon="emcv-logo-small" />
                            </div>
                        </gmap-custom-marker>

                    </gmap-map>
                </div>
            </template>
        </app-builder>
    </div>
</template>

<script>

import { SECTION_HANDLES } from 'src/constants'
import AppForm from '../components/AppForm.vue';
import { mapGetters, mapState } from 'vuex';
import AppBuilder from '../layout/AppBuilder.vue';
import { DELAY } from 'src/constants'
import Icon from 'objects/Icon'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import Variables from 'src/mixins/variables'

export default {
    name: 'Contact',
    components: {
        AppForm,
        AppBuilder,
        GmapCustomMarker,
        Icon,
    },
    mixins: [Variables],
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
        sections: null,
        decoPosition: 0,
        googleMapsOpts: {
            disableDefaultUi: true,
            zoomControl: false,
            zoomControlOptions: {
                style: 0,
                position: 8
            },
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            draggable: false,
            // styles: mapStyles
        },
    }),
    computed: {
        ...mapState({
            settings: state => state.global.settings,
        }),
        ...mapGetters({
            isLoading: 'loader/isLoading',
            isLoaderCompleted: 'loader/isLoaderCompleted',
        }),
        mapCoordinates() {
            let coords = null
            if (this.settings && this.settings.map && this.settings.map.length) {
                const map = this.settings.map[0]

                coords = {
                    lat: parseFloat(map.lat),
                    lng: parseFloat(map.lng),
                }
            }

            return coords
        },
    },
    mounted() {
        this.sections = [{
            title: 'Nous joindre',
            sectionWidth: 'w-2/3',
            hasPadding: true,
            children: [{
                typeHandle: 'BlockAddress',
                heading: 'Service de la formation continue',
                settings: this.settings
            }]
        },
        {
            title: 'Questions et commentaires ?',
            sectionWidth: 'w-full',
            hasPadding: true,
            hasBackgroundColor: true,
            children: [{
                typeHandle: 'BlockForm',
                email: this.settings.email
            }]
        }]
        this.$nextTick(() => this.setFirstSectionTitle())
        window.addEventListener('resizeEnd', this.setDecosPosition)
    },
    methods: {
        setCircle () {

            const alreadyExist = document.querySelector('.-circle-shape-2')

            if (alreadyExist) return

            this.circleShape = document.createElement('div')
            this.circleShape.classList.add('-circle-shape', '-circle-shape-2')


            this.addCircleToDom()
        },
        setDecosPosition() {
            this.$nextTick(() => {
                this.setCircle()
                const deco = document.querySelector('.l-hero__deco .-deco-2')

                this.decoPosition = deco.getBoundingClientRect()

                this.$el.style.setProperty('--deco-top', this.decoPosition.top + window.pageYOffset + 'px')
                this.$el.style.setProperty('--deco-left', this.decoPosition.left + window.pageXOffset + 'px')

                const circles = this.$el.querySelectorAll('.-circle-shape')
                circles.forEach(circ => circ.classList.add('-visible'))

            })
        },
        addCircleToDom() {
            //Add the circle to the last section (which is the form section)
            const sections = document.querySelectorAll('.l-builder section')
            const form = sections.length ? sections[sections.length - 1] : null

            if (form) {
                form.append(this.circleShape)
            }
        },
        setFirstSectionTitle() {
            const address = document.querySelector('.b-address')
            const title = document.querySelector('.-s__title')
            const titleClone = title.cloneNode(true)

            address.prepend(titleClone)
        }
    },
    beforeDestroy() {
         window.removeEventListener('resizeEnd', this.setDecosPosition)
    },
    watch: {
        isLoaderCompleted: {
            handler(loaded) {
                if (loaded) {
                    // Wait the hero finish to reveal (duration .7s)
                    setTimeout(this.setDecosPosition, DELAY.HERO + 800)
                }
            },
            immediate: true
        },
    }
};

</script>

<style lang="scss">

.p-contact {
    --shape-size: clamp(25rem, 35vw, 50rem);

    &__wrapper {
        // margin-top: var(--grid-gutter);
    }

    .l-hero__deco {
        margin-left: 5rem;

        &.-horizontal {
            // margin-top: 10rem;

            @media #{md('sm')} {
                margin-top: 20rem;
            }
        }
    }

    &__builder {

        .block-address {
            display: flex;
            flex-direction: column-reverse;
            padding: var(--grid-gutter) 0;

            @media #{md('sm')} {
                flex-direction: row;
                padding: 0;
            }
        }

        section.-s:last-of-type {
            --section-spacer: calc(var(--grid-gutter) + 5vw);
        }

        section:first-of-type {

            .-s__title:first-of-type {
                display: none;
            }

            .b-address .-s__title {
               display: block;
               margin-bottom: 3rem;
            }

            @media #{md('sm')} {
                .-s__title:first-of-type {
                    display: block;
                }

                .b-address .-s__title {
                    display: none;
                }

            }

            @media #{md('sm', 'max')} {
                margin-top: 0 !important;
            }
        }

    }

    .-circle-shape {
        overflow: hidden;
        border-radius: 50%;
        background: $color-light;
        top: 0;
        left: calc(var(--deco-left) - 2 * var(--grid-gutter));
        width: var(--shape-size);
        height: var(--shape-size);
        opacity: 0;
        transition: opacity .5s ease, trasnform .5s ease ;

        &.-visible {
            opacity: 1;
        }

        &-2 {
            opacity: 0 !important;
        }

        .o-map {
            height: var(--shape-size);
            width: var(--shape-size);

            .vue-map {
                height: 100%;
            }
        }

        @media #{md("sm")} {
            position: absolute;
            transform: translateY(-100px);

            &-2.-visible {
                opacity: 1 !important;
                transform: translateY(-3rem);
                overflow: hidden;
                left: calc(var(--deco-left) - 1 * var(--grid-gutter));

                &:after {
                    @include pseudo-el($width: 100%, $height: 100%, $bg: var(--page-color));
                    transform: translateY(calc(-100% + 3rem));
                    position: absolute;

                }
            }
        }
    }

    .o-map__marker  {
        background: $color-light;
        height: 4.5rem;
        width: 4.5rem;
        border-radius: 50%;
        display: grid;
        place-content: center;
    }
}

</style>
