<template>
    <div v-if="page" class="p-video__wrapper | grid" v-reveal.once="revealVideo">
        <div class="p-video__inner">
            <div>
                <btn
                    type="underline"
                    label="Retour"
                    tag="router-link"
                    url="/realisations"
                    class="u-dp-none u-dp-block@md"
                />
                <page-content :page="page">
                    <template #beforeTitle>
                        <div class="p-video__program" v-if="page.program">
                            <icon :icon="program.icon" />
                            <span>{{ program.title }}</span>
                        </div>
                    </template>
                    <template #bio v-if="page.student">
                        <student-bio :student="student" />
                    </template>
                </page-content>
            </div>

            <div>
                <div ref="video" class="p-video__player">
                    <base-video
                        ref="videoPlayer"
                        v-if="page.videoId"
                        :videoId="page.videoId"
                        :overwrite-poster="page.image"
                        :cursor-label="{ label: 'Visionner', icon: 'play' }"
                        :is-play-mobile-visible="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import Btn from "components/Btn";
import Icon from "objects/Icon";
import BaseVideo from "components/BaseVideo";
import PageContent from "components/PageContent";
import StudentBio from "components/StudentBio";

import Variables from "mixins/variables";
import { DELAY } from "src/constants";
import { SECTION_HANDLES } from "src/constants";
import { mapGetters } from "vuex";

export default {
    name: "Video",
    components: {
        Btn,
        Icon,
        BaseVideo,
        PageContent,
        StudentBio
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        };
    },
    props: {
        page: Object
    },
    data: () => ({
        SECTION_HANDLES,
        isRevealed: false
    }),
    mixins: [Variables],
    computed: {
        ...mapGetters({
            isLoading: "loader/isLoading",
            isLoaderCompleted: "loader/isLoaderCompleted",
            getProgramBySlug: "global/getProgramBySlug"
        }),
        student() {
            if (this.page.student) return this.page.student[0];
        },
        program() {
            if (!this.page || !this.page.program) return null;

            return this.getProgramBySlug(this.page.program.slug);
        }
        /*
        isVideoSticky() {
            const isSticky = this.W.w > 992;
            return isSticky;
        },
        */
    },
    /*
    mounted() {
        window.addEventListener("resizeEnd", this.setVideoSticky);
    },
    */
    methods: {
        revealVideo() {
            /**Set the video initial state 500ms before it fades in */
            gsap.to(
                this.$refs.videoPlayer.$el,
                // {
                //     opacity: 0,
                //     y: 50,
                //     duration: 0,
                //     // ease: "power1.out",
                //     delay: DELAY.DECO / 1000 - 0.5
                // },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.3,
                    ease: "power1.out",
                    delay: DELAY.DECO / 1000
                    // clearProps: "all"
                }
            );
            this.isRevealed = true;
        }

        // I AM A LITTLE BIT AFFRAID OF DELETING ALL THIS CODE FROM MO... it seems like it should be used but I am not 100% sure
        /*
        setSticky() {
            const inner = document.querySelector(".p-video__inner"),
                header = document.querySelector(".l-header"),
                innerTop = inner.getBoundingClientRect().top,
                headerTop = header.getBoundingClientRect().top;
            let position, stickyPosition;

            this.trigger = ScrollTrigger.create({
                trigger: ".p-video__inner",
                scrub: true,
                start: "top top+=var(--grid-gutter)",
                invalidateOnRefresh: true,
                onUpdate: ({ progress }) => {
                    position = innerTop - window.scrollY;

                    if (position < 0 && progress < 0.1) {
                        stickyPosition = Math.abs(innerTop - headerTop);
                    } else if (position > 0) {
                        stickyPosition = position;
                    } else {
                        stickyPosition = 0;
                    }

                    inner.style.setProperty("--top", stickyPosition + "px");
                },
            });
        },
        setVideoSticky() {
            if (this.trigger) {
                this.trigger.kill();
            }

            if (!this.isRevealed) {
                this.revealVideo();
            }

            if (this.isVideoSticky) {
                this.setSticky();
            }
        },
        */
    }
    /*
    beforeDestroy() {
        this.trigger.kill();
        window.removeEventListener("resizeEnd", this.setVideoSticky);
    },
    watch: {
        isLoaderCompleted: {
            handler(loaded) {
                if (loaded) {
                    this.setVideoSticky();
                }
            },
            immediate: true,
        },
    },
    */
};
</script>

<style lang="scss" scoped>
.p-video {
    &__wrapper {
        margin-top: var(--grid-gutter-double);

        // Add extra padding-bottom to last section, compensate for footer
        padding-bottom: var(--footer-offset-gap) !important;

        @media #{md('md')} {
            margin-top: var(--grid-gutter);
        }
    }

    &__inner {
        display: flex;
        flex-direction: column-reverse;

        @media #{md('md')} {
            display: grid;
            grid-template-columns: 2fr 3fr;
            gap: var(--grid-gutter);
        }
    }

    &__program {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        justify-content: start;
        gap: 1rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__player {
        position: sticky;
        top: var(--grid-gutter);
        z-index: 2;
        width: 100%;

        @media #{md('md')} {
            --video-offset: var(--grid-gutter);
            margin-right: calc(var(--video-offset) * -1);
            width: calc(100% + var(--video-offset));
        }

        .c-base-video {
            @include shared-reveal-pre-animation;
            @media #{md('md')} {
                @include transition(0.3s ease top);
            }
        }
    }
}
</style>
