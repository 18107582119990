<template>
    <component
        v-if="text"
        :is="cms ? 'div' : tag"
        :class="className"
        v-html="text"
    />
        <!-- v-reveal.once="reveal ? revealHandle : false" // parents handle it -->
</template>

<script>
import { gsap, SplitText } from "gsap/all";
gsap.registerPlugin(SplitText);

export default {
    name: "AnimText",
    props: {
        text: {
            type: String | Number,
            default: null
        },
        tag: {
            type: String,
            default: "span"
        },
        reveal: {
            type: Boolean,
            default: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        cms: {
            type: Boolean,
            default: false
        },
        chars: {
            type: Boolean,
            default: false
        },
        word: {
            type: Boolean,
            default: false
        },
        lines: {
            type: Boolean,
            default: false
        },
        isAxisY: {
            type: Boolean,
            required: false,
            default: true
        },

        isLastLineAboveImg: {
            type: Boolean,
            required: false,
            default: false
        },
        isFirstLastLinesBelowImg: {
            type: Boolean,
            required: false,
            default: false
        },

        options: {
            type: Object,
            default: () => {}
        },

        durationIn: {
            type: Number,
            required: false,
            default: 0.5
        },
        durationOut: {
            type: Number,
            required: false,
            default: 0.001
        },
        staggerIn: {
            type: Number,
            required: false,
            default: 0.1
        },
        delayFeedback: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data: () => ({
        split: null,
        opts: {
            //   inDuration: 0.5,
            inDelta: 1,
            idDelay: 0,
            outDuration: 0.001,
            outDelta: 1,
            outDelay: 0
        }
    }),
    created() {
        this.opts = { ...this.opts, ...this.options };
    },
    mounted() {
        this.$nextTick(() => {
            this.init();
        });
    },
    computed: {
        className() {
            let classname = "o-at";

            if (this.reveal) {
                classname += " js-reveal";
            }

            if (this.isVisible) {
                classname += " is-visible";
            }

            if (this.cms) {
                classname += " t-cms";
            }

            if (this.chars) {
                classname += " -chars";
            }

            if (this.isLastLineAboveImg) {
                classname += " -last-line-above";
            }
            if (this.isFirstLastLinesBelowImg) {
                classname += " -first-last-line-below";
            }

            return classname;
        },
        splitItems() {
            return this.chars ? this.split.chars : this.isSplitedEachWordOrByLine();
        }
    },
    watch: {
        visible(visible) {
            if (visible) {
                this.show();
            } else {
                this.hide();
            }
        }
    },
    methods: {
        isSplitedEachWordOrByLine() {
            return this.word ? this.split.words : this.split.lines;
        },

        init() {
            if (this.cms) {
                return;
            }

            //======= START INIT SPLITEXT =======//

            const type = this.chars ? "lines,chars" : "lines,words";

            this.split = new SplitText(this.$el, {
                type,
                charsClass: "o-at__c",
                wordsClass: "o-at__w",
                linesClass: "o-at__l",
                reduceWhiteSpace: false
            });

            let outStagger = 0;
            if (this.chars || this.lines) {
                outStagger = {
                    each: 0.1,
                    from: "start",
                    axis: this.lines ? this.whichStaggerAxis() : null
                };
            }

            // Initial hide
            gsap.set(this.splitItems, { opacity: 0 });

            //======= END INIT SPLITEXT =======//

            //======= START TIMELINE =======//

            // Set timeline
            this.tl = gsap
                .timeline({ paused: true, onComplete: () => {} })
                .addLabel("show")
                .set(this.splitItems, {
                    opacity: 1,
                    xPercent: this.isAxisY ? 0 : -this.opts.inDelta * 110,
                    yPercent: this.isAxisY ? this.opts.inDelta * 100 : 0,
                    delay: this.opts.inDelay
                })
                .to(this.splitItems, {
                    duration: this.durationIn,
                    xPercent: 0,
                    yPercent: 0,
                    stagger: {
                        each: this.staggerIn,
                        from: this.chars ? "end" : "start",
                        axis: this.lines ? this.whichStaggerAxis() : null
                    },
                    ease: this.chars ? "expo.inOut" : "expo.inOut",
                    overwrite: "all"
                })
                .add(() => {
                    this.emitToParent("completed");
                }, `-=${this.delayFeedback}`)
                .addPause()
                .addLabel("hide")
                .to(this.splitItems, {
                    duration: this.durationOut,
                    delay: this.opts.outDelay,
                    stagger: outStagger,
                    xPercent: this.isAxisY ? 0 : -this.opts.outDelta * 110,
                    yPercent: this.isAxisY ? this.opts.outDelta * 110 : 0,
                    ease: "power3.in"
                    // overwrite: 'all',
                })
                .set(this.splitItems, {
                    opacity: 0
                });

            if (this.visible) {
                this.show();
            }
        },

        //======= END TIMELINE =======//

        /*------------------------------
    Start methods for the timeline
    ------------------------------*/
        whichStaggerAxis() {
            return this.isAxisY ? "y" : "x";
        },
        emitToParent(emitName) {
            this.$emit(emitName);
        },

        /*------------------------------
    End methods for the timeline
    ------------------------------*/

        /*------------------------------
    Start Show/Hide/Reveal the timeline
    ------------------------------*/
        revealHandle(state) {
            if (state.isActive) {
                this.show();
            } else {
                this.hide();
            }
        },
        show() {
            if (this.cms) {
                return;
            }

            this.tl.play("show");
        },
        hide() {
            if (this.cms) {
                return;
            }

            this.tl.play("hide");
        }
        /*------------------------------
    End Show/Hide/Reveal the timeline
    ------------------------------*/
    }
};
</script>

<style lang="scss">
.o-at {
    display: block;

    // &.t-cms {
    //     @include anim-reveal-default;
    // }

    &.-chars {
        .o-at__l {
            margin-top: 0;
            padding-top: 0;
        }
    }

    html.reduced-motion & {
        &.t-cms > *,
        .o-at__w {
            transition: none !important;
        }
    }
}
/*
.animated-lc__main__copy{
    .o-at__l {

    }
} */
.o-at__l {
    margin-top: -0.2em;
    padding-top: 0.2em;
    margin-bottom: -0.4em;
    padding-bottom: 0.2em;
    overflow: hidden;

    // display the text above the decorative image
    z-index: 3;
}

.-last-line-above {
    .o-at__l {
        z-index: 1;
        &:last-child {
            z-index: 3;
        }
    }
}

.-first-last-line-below {
    .o-at__l {
        z-index: 3;
        &:first-child,
        &:last-child {
            z-index: 1;
        }
    }
}

.o-at__c,
.o-at__w {
    // transform: translate(0, 100%) rotate3d(0, 0, 1, 25deg);
    // opacity: 0;
    transform-origin: 0 100%;
    will-change: transform;
}
</style>
