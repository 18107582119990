<template>
    <!-- <article class="c-card" ref="card"> -->
    <article class="c-card" :ref="`card${element.id}`">
        <router-link :to="`/${element.uri}`" class="c-card__tap-zone"></router-link>
        <slot name="beforCard" />

        <div v-if="element.image" class="c-card__asset" ref="asset">
            <asset :asset="element.image" v-cursor.label="videoCursor" @click.native="openVideoModal" />
        </div>

        <div class="c-card__content">
            <slot name="beforeContent">
                <div class="c-card__before-content">
                    <span v-if="element.section == 'article'">
                        {{ element.articleType.title }}
                    </span>
                    <span v-if="element.postDate && element.articleType">
                        {{ postDate }}
                    </span>

                    <span v-if="program" class="c-card__program">
                        <icon :icon="program.icon" />
                        <span>{{ program.title }}</span>
                    </span>
                </div>
            </slot>

            <slot name="content">
                <div class="c-card__content-inner t-content" :class="{ 'is-mobile': isMobile }">
                    <div class="">
                        <h3 ref="title" class="c-card__title t-t4">
                            {{ element.title }}
                        </h3>

                        <span v-if="element.student">
                            {{ element.student[0].title }}
                        </span>

                        <div v-if="element.awards || element.nominations" class="c-card__prizes">
                            <span v-if="element.awards && element.awards.length">
                                {{ element.awards.length }} x
                                <icon icon="nomination" />
                            </span>
                            <span v-if="element.nominations && element.nominations.length">
                                {{ element.nominations.length }} x
                                <icon icon="awards" />
                            </span>
                        </div>
                    </div>
                    <div class="c-card__btns c-btn__group">
                        <btn
                            v-if="isMobile && element.section == 'video'"
                            label="Visionner"
                            type="dark"
                            @click.native="openVideoModal"
                        />
                        <btn tag="router-link" :url="`/${element.uri}`" label="Lire la suite" type="underline" />
                    </div>
                </div>
            </slot>

            <slot name="afterContent" />
        </div>

        <slot name="afterCard" />
    </article>
</template>

<script>
import Asset from "../objects/Asset.vue";

import { formatDate } from "src/utils";
import Variables from "mixins/variables";

import { DELAY } from "src/constants";
import Icon from "../objects/Icon.vue";
import Btn from "components/Btn.vue";

import { mapGetters } from "vuex";

import { gsap } from "gsap/all";

export default {
    components: { Asset, Icon, Btn },
    mixins: [Variables],
    name: "Card",
    props: {
        element: {
            type: Object,
            required: true
        },
        firstRevealed: false
    },
    data() {
        return {
            timelineCard: null,
            currentScreenWidth: 0,
            cardTimeout: null
        };
    },
    computed: {
        ...mapGetters({
            isLoading: "loader/isLoading",
            isLoaderCompleted: "loader/isLoaderCompleted",
            getProgramBySlug: "global/getProgramBySlug"
        }),
        program() {
            let program = null;

            if (this.element && this.element.program) {
                program = this.getProgramBySlug(this.element.program.slug);
            }

            return program;
        },
        postDate() {
            return formatDate(this.element.postDate, {
                month: "numeric",
                day: "numeric",
                year: "numeric"
            });
        },
        videoCursor() {
            if (this.element.section == "video" && !this.isMobile)
                return {
                    label: "Visionner",
                    iconBefore: "play"
                };
        },
        isWidthScreenResized() {
            return this.currentScreenWidth === window.innerWidth;
        },
        currentRef() {
            return `card${this.element.id}`;
        }
    },
    mounted() {
        window.addEventListener("resizeEnd", this.onResize);
        this.$nextTick(() => {
            this.setCurrentScreenWidth();
        });
    },

    beforeDestroy() {
        window.removeEventListener("resizeEnd", this.onResize);
        this.destroyTimeline();
    },
    watch: {
        isLoaderCompleted: {
            handler(loaded) {
                if (loaded) {
                    this.destroyTimeline();
                    const delay = this.firstRevealed ? 0 : DELAY.CARDS;

                    this.cardTimeout = setTimeout(() => {
                        this.setTimelineAnimation();
                        this.timeoutDestroyer();
                    }, delay);
                }
            },
            immediate: true
        }
    },

    methods: {
        timeoutDestroyer() {
            this.cardTimeout ? (clearTimeout(this.cardTimeout), (this.cardTimeout = null)) : null;
        },
        ////////////////////////////////
        //       START RESIZE
        ////////////////////////////////
        setCurrentScreenWidth() {
            this.currentScreenWidth = window.innerWidth;
        },
        onResize() {
            this.timelineCard && this.isLoaderCompleted && !this.isWidthScreenResized
                ? (this.setCurrentScreenWidth(), this.destroyTimeline(), this.setTimelineAnimation())
                : null;
        },
        ////////////////////////////////
        //       END RESIZE
        ////////////////////////////////

        openVideoModal() {
            if (this.element.section != "video") return;

            this.$store.dispatch("modal/open", { data: this.element });
        },

        ////////////////////////////////
        //       START TIMELINE ANIMATION
        ////////////////////////////////
        setTimelineAnimation() {
            const customIdentifier = this.$refs[this.currentRef];
            const durationTimeLine = 0.4;

            this.timelineCard = gsap.timeline({
                scrollTrigger: {
                    trigger: customIdentifier,
                    start: "center bottom"
                }
            });

            this.timelineCard
                .to(customIdentifier, {
                    "--card-asset-transform": 0,
                    "--card-asset-opacity": 1,
                    duration: durationTimeLine,
                    ease: "power.out"
                })
                .to(
                    customIdentifier,
                    {
                        "--card-content-transform": 0,
                        "--card-content-opacity": 1,
                        duration: durationTimeLine,
                        ease: "power.out"
                    },
                    `-=0.1`
                );
        },

        destroyTimeline() {
            this.timelineCard ? (this.timelineCard.kill(), (this.timelineCard = null)) : null;
        }
        ////////////////////////////////
        //       END TIMELINE ANIMATION
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.c-card {
    --card-asset-transform: 3rem;
    --card-asset-opacity: 0;

    --card-content-transform: 3rem;
    --card-content-opacity: 0;

    --card-gap: 1.5rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    padding-top: calc(var(--grid-gutter) * var(--index-by-row));

    &__tap-zone {
        --display-tap-zone: none;
        --pointer-events-tap-zone: none;
        @include full-screen-dom(absolute, 0, 0, 6rem);

        display: var(--display-tap-zone);
        pointer-events: var(--pointer-events-tap-zone);
        z-index: 2;

        .is-touch & {
            --display-tap-zone: inline-block;
            --pointer-events-tap-zone: auto;
        }
    }

    &__before-content {
        display: flex;
        justify-content: space-between;
        padding-top: calc(var(--card-gap) * 2);
        padding-bottom: var(--card-gap);
    }

    &__title {
        margin-right: 2rem;
    }
    &__prizes span {
        padding-right: 1rem;
        text-transform: lowercase;
    }

    &__program {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 1rem;
        font-weight: bold;
    }

    &__asset {
        @include transform(translateY(var(--card-asset-transform)));
        opacity: var(--card-asset-opacity);
    }

    &__content {
        @include transform(translateY(var(--card-content-transform)));
        opacity: var(--card-content-opacity);
        &-inner {
            display: grid;
            align-items: end;
            gap: var(--grid-gutter);
            grid-auto-flow: row;
            justify-content: space-between;

            @media #{md('xs')} {
                grid-auto-flow: column;
                grid-template-columns: auto max-content;
            }

            & > *:first-child {
                display: grid;
                gap: var(--card-gap);
            }

            &.is-mobile {
                grid-template-rows: repeat(2, min-content);
            }
        }
    }
}
</style>
