<template>
    <div :class="className">
        <loader />
        <background v-if="currentPageName" :currentPage="currentPageName" />

        <app-header @toggleNav="toggleNav" />

        <main class="l-main" ref="main">
            <router-view :page="currentPage" />
        </main>

        <modal-video />
        <app-footer />
        <app-cursor />
    </div>
</template>

<script>
import AppHeader from "layout/AppHeader";
import AppFooter from "layout/AppFooter";

import AppCursor from "objects/AppCursor";
import Background from "objects/Background";
import Loader from "components/Loader";

import { mapState } from "vuex";

import { toKebab } from "src/utils";
import ModalVideo from "../components/ModalVideo.vue";

export default {
    name: "AppBase",
    components: {
        AppHeader,
        AppFooter,
        AppCursor,
        Loader,
        Background,
        ModalVideo
    },
    data: () => ({
        navIsOpen: false,
        defaultPageColor: "white"
    }),
    computed: {
        ...mapState({
            currentPage: state => state.global.currentPage,
            currentPageName: state => state.global.currentPageName,
            isArrowCursorVisible: state => state.global.isArrowCursorVisible
        }),
        className() {
            let classname = "l-wrapper";

            if (this.currentPageName) {
                classname += ` p-${toKebab(this.currentPageName)}`;
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += " nav-is-open";
            }

            // display arrow cursor
            if (!this.isArrowCursorVisible) {
                classname += " no-cursor";
            }

            return classname;
        },
        pageColor() {
            return this.currentPage.colour || this.defaultPageColor;
        }
    },
    methods: {
        toggleNav(isOpen) {
            this.navIsOpen = isOpen;
        },
        setPageColor() {
            this.$el.style.setProperty("--page-color", `var(--color-${this.pageColor})`);
        }
    },
    watch: {
        currentPage: "setPageColor"
    }
};
</script>

<style lang="scss">
.l-wrapper {
    padding-left: var(--grid-gutter-half);
    padding-right: var(--grid-gutter-half);

    &:not(.p-video) {
        overflow-x: hidden;
    }

    &.p-video {
        @media #{md("md", "max")} {
            overflow-x: hidden;
        }
    }

    &.p-home {
        .o-bg {
            --bg-color: var(--home-hero-bg);
        }
    }

    --footer-offset-gap: calc(var(--grid-gutter-quadruple) * 3);
    --footer-negative-gap: calc((var(--footer-offset-gap) - var(--grid-gutter-quadruple)) * -1);

    @media #{md('md')} {
        --footer-offset-gap: var(--grid-gutter-quadruple);
        --footer-negative-gap: calc((var(--footer-offset-gap) - var(--grid-gutter-quadruple)) * -1);
    }
}

.l-main {
    min-height: 100vh;
}
</style>
