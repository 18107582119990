/*
 * Craft asset transform w/ srcset
 *
 */

const transforms = [
    {
        name: 'medium.16:9',
        mode: 'crop',
        width: 580,
        height: 350,
        quality: 100,
        position: 'center-center',
        srcset: `["590w", "1180w"]`
    },
    {
        name: 'large.16:9',
        mode: 'crop',
        width: 800,
        height: 480,
        quality: 100,
        position: 'center-center',
        srcset: `["235w", "470w", "565w", "855w", "945w", "1130w", "1710w", "1890w"]`
    },
    {
        name: 'medium.1:1',
        mode: 'crop',
        width: 280,
        height: 280,
        quality: 100,
        position: 'center-center',
        srcset: `["280w", "560w"]`
    },
    {
        name: 'inscription',
        mode: 'crop',
        width: 290,
        height: 420,
        quality: 100,
        position: 'center-center',
        srcset: `["180w", "275w", "300w", "360w", "550w", "600w"]`
    },
    {
        name: 'home.intro',
        mode: 'crop',
        width: 660,
        height: 435,
        quality: 100,
        position: 'center-center',
        srcset: `["185w", "360w", "455w", "370w", "660w", "720w", "910w", "1320w"]`
    },
    {
        name: 'article.card',
        mode: 'crop',
        width: 455,
        height: 275,
        quality: 100,
        position: 'center-center',
        srcset: `["345w", "360w", "370w", "400w", "600w", "690w", "800w", "1030w", "1200w"]`
    },
    {
        name: 'cover',
        mode: 'crop',
        width: 430,
        height: 260,
        quality: 100,
        position: 'center-center',
        srcset: `["345w", "370w", "690w", "740w", "425w",  "440w", "450w", "470w", "780w", "850w", "880w", "900w", "940w", "1560w", "1760w"]`
    },
    {
        name: 'tabs',
        mode: 'crop',
        width: 1280,
        height: 767,
        quality: 100,
        position: 'center-center',
        srcset: `["315w", "340w", "630w", "670w", "680w", "805w", "1225w", "1340w", "1350w", "1610w", "1700w", "2250w"]`
    },
]

const getAsset = (field = 'image', format = false) => {

    let transform

    const data = `
        url
        title
        width
        height
    `

    if ( format && typeof format == 'string' ) {
        transform = transforms.find(t => t.name == format)
    }

    if ( transform ) {

        if (transform.srcset) {
            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                    srcset (sizes: ${transform.srcset})
                }
            `
        } else {

            return `
                ${field} @ transform(mode: "${transform.mode}", width: ${transform.width}, height: ${transform.height||0}, quality: ${transform.quality||80}, position: "${transform.position||'center-center'}", immediately: false) {
                    ${data}
                    mimeType
                }
            `
        }

    } else {

        return `
            ${field} {
                ${data}
            }
        `
    }
}

export default getAsset
