<template>
    <div v-if="page" class="p-article__wrapper | grid">
        <div class="p-article__inner">
            <div>
                <btn
                    type="underline"
                    label="retour"
                    tag="router-link"
                    url="/articles"
                />
                <page-content :page="page" />
                    <!-- <template #beforeTitle> // last minute update. Leave it there for a few week as a fall back if the refactor leads to bugs
                        <div class="p-article__type | t-t5 -uppercase" v-if="page.articleType">
                            <span>{{ page.articleType.title }}</span>
                        </div>
                    </template>
                 </page-content> -->
            </div>
            <div ref="image" class="p-article__image">
                <asset v-if="page.image" :asset="page.image" />
            </div>
        </div>
    </div>
</template>

<script>

import { SECTION_HANDLES } from 'src/constants'
import PageContent from '../components/PageContent.vue';
import Btn from "../components/Btn.vue";
import Asset from 'objects/Asset'
import Icon from "objects/Icon.vue";

export default {
    name: 'Article',
    components: {
        Asset,
        Btn,
        PageContent,
        Icon
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES
    })
};

</script>

<style lang="scss">
.p-article {

    &__wrapper {
        margin-top: var(--grid-gutter-double);

        // Add extra padding-bottom to last section, compensate for footer
        padding-bottom: var(--footer-offset-gap) !important;

        @media #{md('md')} {
            margin-top: var(--grid-gutter);
        }
    }

    &__inner {
        display: flex;
        //padding-top: var(--grid-gutter);
        flex-direction: column-reverse;

        @media #{md('md')} {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: var(--grid-gutter);
        }

        @media #{md('lg')} {
            grid-template-columns: 5fr 6fr;
            gap: calc( var(--grid-gutter) + 1rem );
        }

        .c-page-content__header {
            .-postDate {
                position: absolute;
                top: 0;
                right: 0;
                font-weight: 400;
            }
        }
    }

    &__image {
        width: 100%;

        @media #{md("md", "max")} {
            padding-bottom: var(--grid-gutter);
        }
    }
}

</style>
