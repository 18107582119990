<template>
    <span class="o-burger">
        <button
            class="o-burger-menu | o-btn-toggle"
            aria-label="Ouvrir et fermer le menu"
            role="button"
            aria-pressed="false"
        >
            <span class="-line -bread-top"></span>
            <span class="-line -meat"></span>
            <span class="-line -bread-bottom"></span>
        </button>
    </span>
</template>

<script>
export default {
    name: "MenuBurger"
};
</script>

<style lang="scss">
.o-burger {
    @include transform(translateY(-100%));
    opacity: 0;

    width: 3rem;
    height: 3rem;
}

.o-burger-menu {
    width: 2rem;
    height: 0.7rem;
    z-index: 5;
    margin-left: 2rem;
    transform: translateX(-1.5rem);
    transition: transform 0.4s ease;
    cursor: pointer;

    @media #{md('sm')} {
        display: none;
        visibility: hidden;
    }

    .-line {
        width: 2rem;
        height: 0.1rem;
        display: block;
        position: absolute;
        background: var(--color-dark);
        transition: transform 0.5s ease-out, opacity 0.4s;
        cursor: pointer;

        &.-bread-top {
            // top: 0;
            margin-top:-3px;
        }
        &.-bread-bottom {
            margin-top:3px;
        }

        .nav-is-open & {
            &.-bread-top {
                transform: translateY(0.2rem) rotate(-45deg);
            }

            &.-meat {
                opacity: 0;
            }

            &.-bread-bottom {
                transform: translateY(-0.4rem) rotate(45deg);
            }
        }
    }
}
</style>
