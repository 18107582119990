export const SECTION_HANDLES = Object.freeze({
    HOME: "home",
    ARTICLES: "articles",
    ARTICLE: "article",
    CONTACT: "contact",
    PAGE: "page",
    PROGRAMS: "programs",
    PROGRAM: "program",
    VIDEOS: "videos",
    VIDEO: "video"
});

// For Vuex stores
export const STORE_MODULES = Object.freeze(["global", "pages", "article", "video", "pages", "loader", "modal", "hero"]);

export const COLORS = Object.freeze(["dark", "light", "chartreuse", "green", "olive", "blue", "brown"]);

export const DELAY = Object.freeze({
    BACKGROUND: 500, // the loader take .8s to animateOut
    NAVS: 1000,
    HERO: 1600, // you need to let enough time between Background to Hero to avoid any bugs I found
    HERO_SUB: 2500, // Helpful if there is a subtitle or anything else
    PROGRAM_INFOS: 1800,
    DECO: 1800,
    FILTERS: 2000,
    CARDS: 2200
});
