var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-builder | grid"},[_vm._t("beforeSections"),_vm._l((_vm.sections),function(section,i){return _c('section',{directives:[{name:"reveal",rawName:"v-reveal.once",modifiers:{"once":true}}],key:("section-" + i),staticClass:"l-builder__inner",class:[
            '-s',
            {
                '-has-padding':
                    section.hasPadding || (section.hasBackgroundColor && section.sectionWidth != 'w-full'),
                '-has-background-color': section.hasBackgroundColor,
                '-has-title': section.title !== null,
                '-is-empty': section.children.length === 0,
                '-revealed': _vm.isRevealed
            },
            section.sectionWidth
        ],attrs:{"id":_vm.sectionId(section)}},[(section.title)?_c('div',{staticClass:"-s__title"},[_c('span',{},[_vm._v(_vm._s(section.title))])]):_vm._e(),(section.children.length > 0)?_vm._l((section.children),function(block,j){return _c('div',{key:("block-" + j),staticClass:"-b",class:_vm.blockClasses(block)},[_c(block.typeHandle,{tag:"component",attrs:{"data":block}}),_vm._t("afterBlock",null,null,{ index: i })],2)}):_vm._e()],2)}),_vm._t("afterSections")],2)}
var staticRenderFns = []

export { render, staticRenderFns }