<template>
    <nav class="l-nav" itemscope itemtype="http://schema.org/SiteNavigationElement" v-if="navList">
        <ul class="l-nav__list">
            <li
                v-for="(item, i) in navList"
                :key="i"
                class="l-nav__item"
                itemprop="name"
                :data-label="item.entry.title.toLowerCase()"
            >
                <btn
                    :tag="isProgramsLink(item.entry) ? 'button' : 'router-link'"
                    :url="item.url"
                    :title="item.entry.title"
                    :is-background-white="true"
                    class="l-nav__link"
                    :ref="item.entry.title.toLowerCase()"
                    :type="
                        isProgramsLink(item.entry) && (programsPanelOpen || $route.name == 'program')
                            ? 'dark'
                            : btnType(item.url)
                    "
                    :icon-after="isProgramsLink(item.entry) && !isSmallScreen ? 'arrow-down' : null"
                    :label="item.label ? item.label : item.entry.title"
                    exact
                    itemprop="url"
                    @click.native.stop="openPrograms(item.entry)"
                />

                <ul v-if="isProgramsLink(item.entry)" class="l-nav__programs-wrapper">
                    <li v-for="program in programs" :key="program.id">
                        <btn
                            tag="router-link"
                            :url="program.uri || '/'"
                            :style="`--hover-color: var(--color-${program.color})`"
                            :is-background-white="btnType(program.uri) != 'dark' || isHeroBgWhite"
                            :label="program.title"
                            :type="btnType(program.uri)"
                            class="l-nav__link"
                            :icon-before="program.icon"
                            exact
                            itemprop="url"
                        />
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Btn from "components/Btn";

import { gsap } from "gsap/all";

import { DELAY } from "src/constants";

export default {
    name: "AppNav",
    components: {
        Btn
    },
    data: () => ({
        isHeroBgWhite: false,
        programsPanelOpen: false,
        revealNavTimeline: null,
        screenWidth: null
    }),
    props: {
        navList: Array,
        isSmallScreen: Boolean
    },
    computed: {
        ...mapState({
            programs: state => state.global.programs
        }),
        ...mapGetters({
            isLoading: "loader/isLoading",
            isLoaderCompleted: "loader/isLoaderCompleted"
        })
    },
    watch: {
        isLoading(loading) {
            if (!loading) {
                this.revealNavTimeline ? this.resetTimeline(0) : this.initRevealNav();
            }
        },
        isLoaderCompleted(loading) {
            loading ? setTimeout(this.revealNav, DELAY.NAVS) : null;
        },
        $route() {
            this.closePrograms();
        }
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        document.body.addEventListener("click", this.closePrograms);
    },

    beforeDestroy() {
        document.body.removeEventListener("click", this.closePrograms);
        window.removeEventListener("resize", this.onResize);
        this.destroyTimeline();
    },

    methods: {
        isProgramsLink(link) {
            return link.section.toLowerCase() == "programs";
        },
        btnType(url) {
            const type = this.$route.path == url ? "dark" : url.includes("inscription") ? "outline" : "transparent";
            return type;
        },
        closePrograms() {
            const coursesNode = this.$el.querySelector('[data-label="programmes"');

            if (coursesNode) {
                this.programsPanelOpen = false;
                coursesNode.classList.remove("open-programs");
            }
        },
        openPrograms(entry) {
            if (!(entry.section == "programs")) return;

            const coursesNode = this.$el.querySelector('[data-label="programmes"]');

            if (coursesNode) {
                this.programsPanelOpen = !this.programsPanelOpen;
                coursesNode.classList.toggle("open-programs");
            }
        },
        getHeroBgHeight() {
            this.$nextTick(() => {
                const bgHeight = document.querySelector(".o-bg").getBoundingClientRect().height;

                if (bgHeight < 10) this.isHeroBgWhite = true;
                else this.isHeroBgWhite = false;
            });
        },
        revealNav() {
            this.revealNavTimeline.play();
            this.getHeroBgHeight();
        },

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////
        initRevealNav() {
            this.revealNavTimeline = gsap.timeline({ paused: true });
            this.revealNavTimeline.to(
                ".l-header__logo",
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.3,
                    ease: "power2.out"
                },
                "started"
            );

            window.innerWidth <= 767 ? this.addMobileAnimation() : this.addDesktopAnimation();
        },

        addDesktopAnimation() {
            this.revealNavTimeline
                .to(
                    ".l-nav__item",
                    {
                        opacity: 1,
                        y: 0,
                        stagger: 0.15,
                        duration: 0.3,
                        ease: "power2.out"
                    },
                    "-=0.1"
                )
                .to(
                    ".l-header__actions .-inscription",
                    {
                        opacity: 1,
                        y: 0,
                        duration: 0.3,
                        ease: "power2.out"
                    },
                    "-=0.1"
                );
        },
        addMobileAnimation() {
            this.revealNavTimeline
                .to(
                    ".l-header__actions .-inscription",
                    {
                        opacity: 1,
                        y: 0,
                        stagger: 0.15,
                        duration: 0.3,
                        ease: "power2.out"
                    },
                    "-=0.1"
                )
                .to(
                    ".o-burger",
                    {
                        opacity: 1,
                        y: 0,
                        duration: 0.3,
                        ease: "power2.out"
                    },
                    "-=0.1"
                )
                // doesn't need to be a stagger, but its easier to understand
                .to(
                    ".l-nav__item",
                    {
                        opacity: 1,
                        y: 0,
                        stagger: 0.15,
                        duration: 0,
                        ease: "power2.out"
                    },
                    "-=0.1"
                );
        },

        //======= START RESIZE OR DESTROY =======//

        resizeTimeline() {
            this.destroyTimeline();
            this.initRevealNav();
            this.resetTimeline(100);
        },

        resetTimeline(start) {
            this.revealNavTimeline.seek(start);
            this.revealNavTimeline.pause();
        },

        destroyTimeline() {
            this.revealNavTimeline.kill();
            this.revealNavTimeline = null;
        },

        //======= END RESIZE OR DESTROY =======//

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////
        ////////////////////////////////
        //       START RESIZE
        ////////////////////////////////
        onResize() {
            // reset timeline if the screen width was updated (avoiding issues on mobile resize height when user scroll)
            this.isScreenWidthUpdated() && this.revealNavTimeline ? this.resizeTimeline() : null;
            // update current screen width
            this.setCurrentScreenSize();
        },
        isScreenWidthUpdated() {
            return window.innerWidth !== this.screenWidth;
        },
        setCurrentScreenSize() {
            this.screenWidth = window.innerWidth;
        }
        ////////////////////////////////
        //       END RESIZE
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.l-nav {
    width: calc(100% + var(--grid-gutter-double));
    margin-top: calc(-1 * var(--grid-gutter));
    padding-top: var(--grid-gutter);
    position: absolute;
    background: var(--color-light);
    transform: translateX(110%);
    transition: transform 0.4s ease-out;
    z-index: 500;
    filter: drop-shadow(0 0 0.5rem rgba($color-dark, 0.25));

    .nav-is-open & {
        transform: translateX(0);
        opacity: 1;
    }

    @media #{md('sm')} {
        width: auto;
        position: relative;
        background: none;
        transform: translate(0);
        filter: initial;
    }
}

.l-nav__list {
    @include reset-list;
    margin: 6rem calc(-1 * var(--grid-gutter)) 0;
    opacity: 0;
    transition: opacity 0.3s 0.4s;

    .nav-is-open & {
        opacity: 1;
    }

    li:not(:last-of-type) {
        border-bottom: 1px solid currentColor;
    }

    @media #{md('sm')} {
        margin: 0;
        display: flex;
        align-items: flex-start;
        opacity: 1;

        li:not(:last-of-type) {
            border: none;
        }

        &:after {
            position: static;
        }
    }

    .c-btn {
        // padding-top: 2rem;
        // padding-bottom: 2rem;
    }
}

.l-nav__programs-wrapper {
    @include reset-list;
    display: flex;
    flex-direction: column;

    li {
        border: none !important;
    }

    .c-btn {
        border-radius: 0;
        border: 0 !important;
    }

    .l-nav__link:hover {
        color: var(--hover-color);
    }

    @media #{md('sm')} {
        position: absolute;
        transform-origin: top;
        transform: scaleY(0);
        transition: transform 0.4s $in-out-quad;
        margin-top: 1rem;
        border: 1px solid var(--page-color);
        border-radius: 5px;

        .c-btn {
            width: 100%;

            &:not(.-dark) {
                --btn-bg: white;
            }
        }

        li {
            border-top: 1px solid currentColor !important;
        }

        li:first-of-type {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            overflow: hidden;
            border-top: none !important;
        }

        li:last-of-type {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            overflow: hidden;
        }
    }
}

.l-nav__item {
    margin-left: auto;
    padding-left: var(--grid-gutter);
    opacity: 0;
    transform: translateY(-100%);

    &.open-programs {
        .l-nav__programs-wrapper {
            transform: scaleY(1);
        }
    }

    @media #{md('sm')} {
        padding-left: 0;
    }
}

.l-nav__link {
    cursor: pointer;
    white-space: pre;
    font-size: 1.2rem;

    @media #{md('sm', 'max')} {
        &:not(.-inscription) {
            width: calc(100% + var(--grid-gutter));
            margin-left: calc(var(--grid-gutter) * -1);
            padding-left: calc(var(--grid-gutter-double));
        }
    }
    &.-inscription {
        --padding-x: 1.9rem; // bc of border of 1px
        --padding-y: 1.5rem
    }

    @media #{md('md')} {
        margin-right: 0.5rem;
    }
}
</style>
