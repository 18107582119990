<template>
    <div class="programs-card">
        <!-- image -->
        <div class="programs-card__img">
            <home-programs-card-img :image="element.image" />
        </div>

        <!-- copy -->
        <div class="programs-card__copy">
            <home-programs-card-copy :copy="element" />
        </div>
        <div class="programs-card__btn">
            <btn
                tag="router-link"
                type="dark"
                label="En savoir plus"
                :url="element.uri"
            />
        </div>
    </div>
</template>

<script>
import HomeProgramsCardImg from "@/templates/components/HomePrograms/HomeProgramsCard/HomeProgramsCardImg";
import HomeProgramsCardCopy from "@/templates/components/HomePrograms/HomeProgramsCard/HomeProgramsCardCopy";
import Btn from '../../Btn.vue';
export default {
    components: {
        HomeProgramsCardImg,
        HomeProgramsCardCopy,
        Btn,
    },
    props: {
        card: {
            type: Object,
            required: false,
            default: () => {},
        },
        element: {
            type: Object,
            required: true,
        },
    }
};
</script>

<style lang="scss" scoped>
.programs-card {
    display: grid;

    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, minmax(auto, 1fr));
    gap: var(--grid-gutter-half);
    height: fit-content;
    margin-bottom: var(--grid-gutter-triple);

    @media #{md('xs')} {
        margin-bottom: 0;
    }

    @media #{md('md')} {
        grid-template-rows: 1fr auto auto;
        padding-bottom: var(--grid-gutter-triple);
    }

    @media #{md("lg")} {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        padding-bottom: 0;
    }

    &__img {
        grid-column: 1/2;
        // grid-row: 1/3;
        @media #{md("lg")} {
            grid-column: 1/3;
            grid-row: 1/3;
        }
    }
    &__copy {
        grid-column: 1/2;
        // grid-row: 3/5;
        @media #{md("lg")} {
            grid-column: 3/5;
            grid-row: 1/3;
        }
    }
    &__btn {
        white-space: nowrap;
        height: fit-content;
    }
}
</style>
