<template>
    <li
        class="b-feat-awards-item"
        :class="{ 'b-feat-awards-item--solo': isNominationOnly }"
    >
        <!-- icon -->
        <div class="b-feat-awards-item__icon">
            <icon :icon="icon" />
        </div>
        <!-- qty -->
        <div class="b-feat-awards-item__qty">
            <p>{{ awards }} x</p>
        </div>
    </li>
</template>

<script>
import Icon from "objects/Icon.vue";
export default {
    components: {
        Icon,
    },
    props: {
        awards: {
            type: Number,
            required: false,
            default: 0,
        },
        icon: {
            type: String,
            required: false,
        },
        isNominationOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.b-feat-awards-item {
    @include borderRadius(0px 5px 0px 0px);
    display: flex;
    flex-direction: column;
    padding: 12px 10px;
    background-color: var(--color-light);
    &--solo {
        @include borderRadius(0px 5px 5px 0px);
    }
    &:nth-child(2) {
        @include borderRadius(0px 0px 5px 0px);
        margin-top: 2px;
    }
    &__icon {
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
}
</style>
