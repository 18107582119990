<template>
    <div class="c-hero-copy">
        <div class="c-hero-copy__inner">
            <transition name="slide">
                <ul v-if="isVisible" class="c-hero-copy__inner__list">
                    <li>
                        <shared-program-title :data="video.program" :is-visible="isVisible" />
                    </li>
                    <li class="c-hero-copy__inner__list__title" :style="`--scale: ${scale}`">
                        <animated-large-copy
                            customRef="heroTitle"
                            :copy="video.title"
                            title-size="2"
                            :lines="true"
                            :is-axis-y="false"
                            :is-visible="isVisible"
                            :duration-in="2"
                            :stagger-in="0.5"
                            :delay-feedback="1.1"
                            @completed="displayAuthor(true)"
                        />
                    </li>
                    <li>
                        <home-hero-copy-author :copy="video.student.title" :is-visible="isAuthorVisible"  :duration-in="2" />
                    </li>
                    <home-hero-copy-btn  :uri="video.uri" :is-visible="isAuthorVisible"/>
                </ul>
            </transition>
        </div>
    </div>
</template>

<script>
import AnimatedLargeCopy from "components/AnimatedLargeCopy/AnimatedLargeCopy";
import HomeHeroCopyAuthor from "components/HomeHero/HomeHeroCopy/HomeHeroCopyAuthor";
import HomeHeroCopyBtn from "components/HomeHero/HomeHeroCopy/HomeHeroCopyBtn";
import SharedProgramTitle from "components/Shared/SharedProgramTitle";
export default {
    components: {
        AnimatedLargeCopy,
        HomeHeroCopyAuthor,
        HomeHeroCopyBtn,
        SharedProgramTitle
    },
    props: {
        video: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isVisible: true,
            isAuthorVisible: false,
            revealTimeOut: null,
            scale: 1
        };
    },

    beforeDestroy() {
        this.destroyTimeOuts();
    },
    watch: {
        video: {
            handler() {
                this.revealCopy();
            },
            immediate: true
        }
    },
    methods: {
        // The code below lead to this bug > https://mambomambo-team.atlassian.net/jira/software/projects/EMCV/boards/5?selectedIssue=EMCV-184
        // I don't think the code below should run and I will explain why.
        // GSAP run a methods, which split the string into lines based on the size of the font
        // THEN, we run a methods to update the size of the font
        // however GSAP will not update propely the size of the font because it's how GSAP run, unless we want to run an infinite loop such

        //futhermore doing the code below means that it's possible to have a slider with different font size.
        // I tested in many way and I don't think any major issues, so I will comment the code below in case I am wrong, and also created a branch called fix/EMCV-192-animation-home-hero__backup which will run with the idea of getting the number of line directly when GSAP init instead of doing a weird check on the child
        // setTypoSize() {
        //     setTimeout(() => {
        //         const heroTitle = document.querySelector(".c-hero-copy__inner__list__title");
        //         const animText = heroTitle.querySelector(".o-at.js-reveal");
        //         const lines = heroTitle.querySelectorAll(".js-reveal .o-at__l");

        //         if (lines.length >= 2 && window.innerWidth < 992) {
        //             animText.classList.add("t-t3");
        //             animText.classList.remove("t-t2");
        //             animText.classList.remove("t-t4");
        //         }

        //         if (lines.length >= 4 && window.innerWidth < 992) {
        //             animText.classList.add("t-t4");
        //             animText.classList.remove("t-t2");
        //             animText.classList.remove("t-t3");
        //         } else {
        //             animText.classList.add("t-t2");
        //             animText.classList.remove("t-t3");
        //             animText.classList.remove("t-t4");
        //         }
        //     }, 800);
        // },
        ////////////////////////////////
        //       START SHOW HIDE ALL THE COPY
        ////////////////////////////////
        revealCopy() {
            this.displayAuthor(false)
            this.displayTitle(false);

            this.revealTimeOut = setTimeout(() => {
                this.displayTitle(true);
            }, 500);
        },
        displayTitle(bool){
               this.isVisible = bool;
        },

        ////////////////////////////////
        //       END SHOW HIDE ALL THE COPY
        ////////////////////////////////


        displayAuthor(bool) {
            // display author after the animation ended
            this.isAuthorVisible = bool;
        },

        ////////////////////////////////
        //       START DESTROY ALL TIMEOUT ON DESTROY
        ////////////////////////////////
        destroyTimeOuts() {
            clearTimeout(this.revealTimeOut);
        }
        ////////////////////////////////
        //       END DESTROY ALL TIMEOUT ON DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-hero-copy {
    display: flex;
    flex-direction: column;

    &__inner {
        display: flex;
        flex-direction: column;
        &__list {
            display: flex;
            flex-direction: column;
            list-style: none;
        }

        .c-btn {
            margin-top: 2rem;
        }
    }
    &__inner__list__title {
        display: flex;
        margin-top: 20px;
    }

    &__inner__list__author {
        display: flex;
        margin-top: 20px;
    }

    //??
    @media #{md('xs')} {
        padding-bottom: 5rem;
    }
}

////////////////////////////////
//       START SLIDE RIGHT TO LEFT WHEN DISPLAY NEXT COPY
////////////////////////////////
.slide-leave-active {
    @include transition(0.5s ease all);
}

.slide-leave-to {
    @include transform(translate(100px));
    opacity: 0;
}
////////////////////////////////
//       END SLIDE RIGHT TO LEFT WHEN DISPLAY NEXT COPY
////////////////////////////////
</style>
