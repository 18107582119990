<template>
    <div class="b-tabs">
        <tab-list :tabs="data.tabs" @tabchange="updateActiveImg" />
        <div class="b-tabs__assets">
            <asset
                v-for="(tab, index) in data.tabs" :key="index"
                class="b-tabs__asset"
                :class="{'-active': activeImage == index }"
                :asset="tab.image"
            />
        </div>
    </div>
</template>

<script>
import TabList from '../components/TabList.vue'
import Asset from '../objects/Asset.vue'
export default {
    name: 'BlockTabs',
    components: { TabList, Asset },
    data: () =>({
        activeImage: 0,
    }),
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    methods: {
        updateActiveImg(active) {
            this.activeImage = active
        }
    },
    watch: {
        data:{
            handler() {
            },
            immediate: true
        }
    }

}
</script>

<style lang="scss">
.b-tabs {
    margin-top: var(--grid-gutter);

    &__assets {
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }

    &__asset {
        width: 100%;
        position: absolute;
        opacity: 0;
        // transform: translateX(calc(100px)) scale(0.9);
        transition: .6s $in-out-sine opacity, transform .6s $out-sine;

        &.-active {
            opacity: 1;
            position: relative;
            transform: translateX(0) scale(1);
        }
    }
}
</style>
