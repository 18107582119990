<template>
    <div
        v-if="data && data.videoId"
        class="b-video"
    >
        <base-video
            :videoId="data.videoId"
            :cursorLabel="{ label: 'visionner', iconBefore: 'play' }"
        />
    </div>
</template>

<script>
import BaseVideo from '../components/BaseVideo.vue'
export default {
    name: "BlockVideo",
    components: {
        BaseVideo,
    },
    props: {
        data: false
    }
}
</script>

<style lang="scss">
.b-video {
}
</style>
