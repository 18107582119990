<template>
    <div
        v-if="data && data.accordions.length"
        class="b-accordions"
        :class="{ '-has-asset': data.image && data.image.length }"
        v-reveal.once="reveal"
    >
        <div v-if="data.image && data.image.length" class="b-accordions__asset">
            <asset :asset="data.image" />
        </div>
        <div class="b-accordions__list">
            <div v-if="data.heading" class="b-accordions__heading t-t3">{{ data.heading }}</div>
            <accordion
                v-for="(accordion, i) in data.accordions"
                :key="`b-accordions-${i}`"
                :data="accordion"
                :openDefault="i === 0 && data.openFirst ? true : false"
                ref="accordion"
                @toggle="handleToggle"
            />
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";

import Accordion from "components/Accordion";
import Asset from "objects/Asset";

export default {
    name: "BlockAccordions",
    components: {
        Accordion,
        Asset
    },
    props: {
        data: false,
        allowOpenAll: {
            type: Boolean,
            default: false // if false it will allow open 1 at a time
        }
    },
    data() {
        return {
            timeline: null
        };
    },
    mounted() {},
    methods: {
        handleToggle(isOpen, el) {
            if (!this.allowOpenAll) {
                this.$refs.accordion.forEach(accordion => {
                    if (accordion.$el != el) {
                        accordion.close();
                    }
                });
            }
        },
        reveal() {
            this.isRevealed ? null : this.runAnimation();
        },
        runAnimation() {
            // hide reveal
            this.toggleIsReveal(true);
            // set the gsap animation
            this.setTimeline();
        },
        toggleIsReveal(bool) {
            this.isRevealed = bool;
        },
        setTimeline() {
            // alternatively it can be a css animation.
            // const accordion = this.$refs.accordion; // there is a bug with GSAP, The ref exist and return something on console log, but it doesn't work on GSAP. I might come back later on that.
            // console.log("this.$refs.accordion", this.$refs.accordion);
            const durationTimeLine = 0.5;
            this.timeline = gsap.timeline({});
            this.timeline.to(".c-accordion", {
                y: 0,
                opacity: 1,
                duration: durationTimeLine,
                stagger: 0.1,
                ease: "power1.out"
            });
        },
        destroyTimeline() {
            this.timeline ? (this.timeline.kill(), (this.timeline = null)) : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.b-accordions {
    margin-top: var(--grid-gutter);
    display: grid;
    gap: var(--grid-gutter);

    &__heading {
        margin-bottom: var(--grid-gutter-half);
    }

    &__list {
        display: grid;
        gap: 1rem;
    }

    .c-accordion {
        @include shared-reveal-pre-animation;
    }

    .c-accordion__buttons {
        margin-top: var(--grid-gutter-half);

        .c-btn__group {
            margin-top: 1em;
        }
    }

    @media #{md('sm')} {
        &.-has-asset {
            grid-template-columns: 1fr 1fr;
            align-items: center;
        }
    }
}
</style>
