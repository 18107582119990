<template>
    <div v-if="article && article.title" class="b-feature">
        <div class="b-feature__inner | t-content">

            <div class="b-feature__inner__img">
                <asset :asset="article.image" />
            </div>

            <div v-if="articleType" class="b-feature__inner__sub-heading">
                <p>
                    <b>{{ articleType.title }}</b>
                </p>
                <p>{{ postDate }}</p>
            </div>

            <div class="b-feature__inner__title">
                <animated-large-copy
                    :custom-ref="`${article.title}BlockFeaturedArticles`"
                    :copy="article.title"
                    title-size="3"
                    :lines="true"
                    :is-axis-y="true"
                    :is-visible="true"
                    :is-visible-on-viewport="true"
                    :is-first-line-end-left="false"
                />
            </div>
            <!-- copy -->
            <p v-html="contentFormatted" />
            <!-- button -->
            <div class="b-feature__inner__btn | -actions">
                <btn
                    tag="router-link"
                    :url="`/${article.uri}`"
                    label="Lire la suite"
                    type="dark"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AnimatedLargeCopy from "components/AnimatedLargeCopy/AnimatedLargeCopy";
import Asset from "objects/Asset";
import Btn from "components/Btn";
import { formatDate, truncateString } from 'src/utils'

export default {
    components: {
        AnimatedLargeCopy,
        Btn,
        Asset,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    computed: {
        article() {
            if (this.data && this.data.article)
                return this.data.article[0]
        },
        articleType() {
            if (this.article && this.article.articleType.length)
                return this.article.articleType[0]
        },
        postDate() {
            if (this.article) {
                return formatDate(this.article.postDate, { month: 'numeric'})
            }
        },
        contentFormatted() {
            if (this.article && this.article.excerpt)
                return truncateString(this.article.excerpt, 300)
        }
    }
};
</script>

<style lang="scss" scoped>
.b-feature {
    padding-bottom: var(--grid-gutter);
    &__inner {
        --content-actions-extra-gap: var(--grid-gutter-half);
        &__sub-heading {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            p {
                @include smallTypography;
                text-transform: uppercase;
            }
        }
    }
}
</style>
