<template>
    <div class="b-cta-hero">
        <!-- title with img -->
        <div v-if="img.length" class="b-cta-hero__full">
            <asset class="b-cta-hero__full__img" :asset="img" />
            <animated-large-copy
                class="b-cta-hero__full__title"
                :custom-ref="`${title}BlockCTA`"
                ref="alc"
                :copy="title"
                title-size="3"
                :lines="true"
                :is-axis-y="false"
                :is-visible="true"
                :is-visible-on-viewport="true"
                :is-first-line-end-left="firstLine"
            >
                <div v-if="content" class="b-cta-hero__content t-cms" v-html="content"></div>
            </animated-large-copy>
        </div>
        <!-- plain title -->
        <div v-else class="b-cta-hero__simple">
            <animated-large-copy
                :custom-ref="`${title}BlockCTA`"
                :copy="title"
                title-size="3"
                :lines="true"
                :is-axis-y="true"
                :is-visible="true"
                :is-visible-on-viewport="true"
                :is-first-line-end-left="false"
            />
        </div>
    </div>
</template>

<script>
import AnimatedLargeCopy from "components/AnimatedLargeCopy/AnimatedLargeCopy";
import AnimText from "objects/AnimText";
import Asset from "objects/Asset";
export default {
    components: {
        AnimatedLargeCopy,
        AnimText,
        Asset
    },
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: false
        },
        img: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            isMediumScreen: window.innerWidth < 992
        };
    },
    mounted() {
        window.addEventListener("resizeEnd", this.setScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener("resizeEnd", this.setScreenSize);
    },
    computed: {
        firstLine() {
            return this.isMediumScreen && this.content ? false : true;
        },
        isHorizontal() {
            return !!this.content;
        }
    },
    methods: {
        setScreenSize() {
            this.isMediumScreen = window.innerWidth < 992;

            this.onResize();
        },

        onResize() {
            if (this.isHorizontal) {
                this.$nextTick(() => {
                    if (this.isMediumScreen) {
                        this.$refs.alc.startAnimationLineToLeft(0);
                    } else {
                        // I leave it here because I am not sure 100% if wasn't needed somewhere else, but don't enable it without aknowledge the overflow on the child: https://mambomambo-team.atlassian.net/browse/EMCV-130
                        // this.$refs.alc.moveFirstLineToTheLeft();
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss">
.b-cta-hero {
    &__full {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: var(--content-gap, 2rem);

        @media #{md("md")} {
            grid-template-columns: repeat(3, 1fr);
        }
        &__img {
            grid-column: 1/2;
            margin-top: 50px;
            @media #{md("md")} {
                grid-column: 1/3;
            }
        }
        &__title {
            grid-column: 2/3;
            @media #{md("md")} {
                grid-column: 3/4;
            }
        }

        .b-cta.-horizontal & {
            grid-template-columns: 1fr;

            &__img {
                grid-column: 1/2;
                margin-top: 50px;
                max-width: 75rem;
                @media #{md("md")} {
                    grid-column: 1/3;
                }
            }

            &__title {
                grid-column: 1/2;
                @media #{md("md")} {
                    grid-column: 3/4;
                }
            }
        }
    }
    &__simple {
        h3 {
            text-transform: uppercase;
        }
    }

    &__content {
        margin-top: var(--grid-gutter);
        max-width: 50rem;
        display: none;

        @media #{md('sm')} {
            display: block;
            grid-column: 2/3;
        }
        @media #{md('md')} {
            grid-column: 4/7;
        }
    }
}

.b-cta.-horizontal {
    .b-cta-hero__full {
        grid-template-columns: 1fr;

        @media #{md('sm')} {
            // grid-template-columns: ;
        }
    }
}
</style>
