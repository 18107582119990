import { fetchApi } from "src/graphql/config";
import { parseData } from "src/store/parse";
import queries from "src/graphql/queries";

/*
 * CRUD
 */

export default class {
    constructor(type) {
        this.state = {
            allPreloaded: false,
            filterGroups: {},
            elements: [],
            resultsCount: 0
        };
        this.getters = {
            getElementById: state => id => state.elements.find(e => e.id == id),
            getElementBySlug: state => slug => state.elements.find(e => e.slug === slug),
            getElementIndexById: state => id => state.elements.findIndex(e => e.id == id)
        };
        this.actions = {
            preloadAll(store) {
                return new Promise((resolve, reject) => {
                    // Start load
                    store.dispatch("loader/startLoad", null, { root: true });
                    if (store.state.allPreloaded) {
                        resolve(store.state.elements);

                        // End load
                        store.dispatch("loader/endLoad", null, { root: true });
                    } else {
                        fetchApi(queries[`${type}PreloadAll`])
                            .then(r => {
                                // Exit on error
                                if (!r || !r.entries) {
                                    throw new Error(`${type} preload failed`);
                                } else {
                                    const elements = r.entries;

                                    elements.forEach(element => {
                                        element = parseData(type, element);

                                        const index = store.getters.getElementIndexById(element.id);
                                        if (index === -1) {
                                            element.fullyFetched = false;
                                            store.commit("addElement", element);
                                        } else {
                                            element.fullyFetched = true;
                                            store.commit("updateElement", { data: element, index });
                                        }
                                    });

                                    resolve(elements);
                                    store.commit("setAllPreloaded", true);

                                    // End load
                                    store.dispatch("loader/endLoad", null, { root: true });
                                }
                            })
                            .catch(e => {
                                reject({
                                    code: 404,
                                    message: e
                                });
                                store.dispatch("loader/endLoad", null, { root: true });
                            });
                    }
                });
            },
            loadElement(store, slug) {
                return new Promise((resolve, reject) => {
                    // Start load

                    if (!slug.update) {
                        store.dispatch("loader/startLoad", null, { root: true });
                    }
                    const _slug = typeof slug === "string" ? slug : slug.slug;

                    // Search if element already exists
                    let element = store.getters.getElementBySlug(_slug);

                    // If element already exists, resolve with the element value
                    if (typeof element !== "undefined" && element.fullyFetched) {
                        resolve(element);

                        // End load
                        if (!slug.update) {
                            store.dispatch("loader/endLoad", null, { root: true });
                        }

                        // If element doesn't exist, load it
                    } else {
                        fetchApi(queries[type](_slug))
                            .then(r => {
                                // Exit on error
                                if (!r || !r.entry) {
                                    throw new Error(`Element not found`);
                                } else {
                                    // Flatten entry and add other data to object
                                    let { entry, ...data } = r; // eslint-disable-line
                                    data = { ...r.entry, ...data };
                                    data = parseData(type, data);
                                    data.fullyFetched = true;

                                    const index = store.getters.getElementIndexById(data.id);
                                    if (index === -1) {
                                        store.commit("addElement", data);
                                    } else {
                                        store.commit("updateElement", { data, index });
                                    }
                                    resolve(data);

                                    if (!slug.update) {
                                        store.dispatch("loader/endLoad", null, { root: true });
                                    }
                                }
                            })
                            .catch(e => {
                                reject({
                                    code: 404,
                                    message: e
                                });
                                if (!slug.update) {
                                    store.dispatch("loader/endLoad", null, { root: true });
                                }
                            });
                    }
                });
            },
            searchEntries(store, options) {
                return new Promise((resolve, reject) => {
                    // Start load if options.update = false

                    if (!options.update) {
                        store.dispatch("loader/startLoad", null, { root: true });
                    }

                    fetchApi(queries[`searchEntries`](type, options))
                        .then(r => {
                            // Exit on error
                            if (!r || !r.entries) {
                                throw new Error(`${type} search query failed.`);
                            } else {
                                const elements = r.entries;

                                store.commit("updateCount", r.resultsCount);

                                // Loop trough elements + build _promises
                                let _promises = elements.map(element => {
                                    return store
                                        .dispatch("loadElement", { slug: element.slug, update: options.update })
                                        .then(el => {
                                            if (r.totalCount === store.state.elements.length)
                                                store.commit("setAllPreloaded", true);

                                            return el;
                                        });
                                });

                                Promise.all(_promises).then(results => {
                                    resolve(results);

                                    // End load
                                    if (!options.update) {
                                        store.dispatch("loader/endLoad", null, { root: true });
                                    }
                                });
                            }
                        })
                        .catch(e => {
                            reject({
                                code: 404,
                                message: e
                            });

                            if (!options.update) {
                                //console.log('searchEntries:: options', options);
                                store.dispatch("loader/endLoad", null, { root: true });
                            }
                        });
                });
            },

            getCategoryGroup(store, args) {
                return new Promise((resolve, reject) => {
                    // Start load
                    store.dispatch("loader/startLoad", null, { root: true });
                    if (store.state.filterGroups[args.group]) {
                        resolve(store.state.filterGroups[args.group]);

                        // End load
                        store.dispatch("loader/endLoad", null, { root: true });
                    } else {
                        let variables = {
                            group: args.group
                        };

                        if (args.sections) {
                            const relations = args.sections.map(section => ({ section }));

                            variables["relations"] = relations;
                        }

                        fetchApi(queries["categoryGroup"], variables)
                            .then(r => {
                                // Exit on error
                                if (!r || !r.categories) {
                                    throw new Error(`${type}, ${args.group} category failed`);
                                } else {
                                    const categories = r.categories;
                                    store.commit("addCategoryGroup", { group: args.group, categories });

                                    resolve(categories);

                                    // End load
                                    store.dispatch("loader/endLoad", null, { root: true });
                                }
                            })
                            .catch(e => {
                                reject({
                                    code: 404,
                                    message: e
                                });
                                store.dispatch("loader/endLoad", null, { root: true });
                            });
                    }
                });
            }
        };
        this.mutations = {
            addCategoryGroup(state, { group, categories }) {
                state.filterGroups[group] = categories;
            },
            addElement(state, data) {
                state.elements.push(data);
            },
            updateCount(state, count) {
                state.resultsCount = count;
            },
            updateElement(state, { data, index }) {
                state.elements[index] = data;
            },
            setAllPreloaded(state, value) {
                state.allPreloaded = value;
            }
        };
    }
}
