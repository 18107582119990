<template>
    <div class="b-feat-video-section-awards">
        <ul class="b-feat-video-section-awards__list">
            <block-featured-video-section-awards-item
                v-if="nominations"
                :awards="nominations"
                icon="nomination"
                :is-nomination-only="!awards"
            />
            <block-featured-video-section-awards-item v-if="awards" :awards="awards" icon="awards" />
        </ul>
    </div>
</template>

<script>
import BlockFeaturedVideoSectionAwardsItem from "blocks/BlockFeaturedVideo/BlockFeaturedVideoSection/BlockFeaturedVideoSectionAwardsItem";
export default {
    components: {
        BlockFeaturedVideoSectionAwardsItem
    },
    props: {
        awards: {
            type: Number,
            required: false,
            default: 0
        },
        nominations: {
            type: Number,
            required: false,
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
.b-feat-video-section-awards {
    @include reset-list;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    transition: opacity .5s ease;
    &__list {
        @include reset-list;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &.-hide {
        opacity: 0;
        pointer-events: none;
    }
}
</style>
