import snippets from './snippets'
import getAsset from './assets'

/*
 * Craft GraphQL Queries
 */


/**********************************
 *
 *
 *  GENERAL
 *
 *
 **********************************/

// Main query
export const general = `{
    navs: globalSet(handle: "navs") {
        ... on navs_GlobalSet {
            navNodes {
                ...on navNodes_BlockType {
                    entry {
                        ${snippets.defaultData}
                        title
                        section: sectionHandle
                    }
                    label
                }
            }
        }
    }
    forms: globalSet(handle: "formConfirmations") {
        ... on formConfirmations_GlobalSet {
            success: body3
        }
    }
    settings: globalSet(handle: "settings") {
        ... on settings_GlobalSet {
            address
            ${snippets.map}
            phone
            email
            facebook
            instagram
            linkedIn
            vimeo
            inscriptionTitle: heading
            inscriptionSubTitle: label
            inscriptionImage: ${getAsset('image', 'inscription')}
            inscriptionPage: ${snippets.relationEntryPage}
        }
    }
}`


/**********************************
 *
 *
 *  SEARCH
 *
 *
 **********************************/

export const categoryGroup = `query Categories($group: [String!], $relations: [EntryCriteriaInput]){
    categories(group: $group, relatedToEntries: $relations) {
        id
        title
        handle: slug
    }
}
`

export const searchEntries = (section, options) => {

    if (section == undefined){
        throw('A section is required to perform the searchEntries query');
    }

    let appendQuery = ""

    if (options.relatedToCategories && options.relatedToCategories.length) {

        let relatedToCategories = []

        options.relatedToCategories.forEach(categ => {
           let category = `{
                group: ["${categ.group}"],
                id: [${categ.id}]
           }`

           relatedToCategories.push(category)
        })

        appendQuery += `, relatedToCategories: [${relatedToCategories}]`
    }
    if (options.relatedToEntries && options.relatedToEntries.length) {

        let relatedToEntries = []

        options.relatedToEntries.forEach(prog => {
           let entry = `{
                id: [${prog.id}]
           }`

           relatedToEntries .push(entry)
        })

        appendQuery += `, relatedToEntries: [${relatedToEntries}]`
    }

    if (options.fixedOrder) {
        appendQuery += `, fixedOrder: ${options.fixedOrder}`
    } else {
        let orderKey = options.order ? options.order.key : 'postDate',
        orderDirection = options.order ? options.order.direction : 'DESC'

        let orderBy = `${orderKey} ${orderDirection}`
        appendQuery += `, limit: ${options.limit || 100}, offset: ${options.offset || 0}`
        appendQuery += `, orderBy: "${orderBy}"`
    }

    if (options.ids) {
        let _ids = options.ids.map(id => parseInt(id))

        _ids.forEach(id => {
            if(isNaN(id))
                throw('ids should be of type Number')
        })
        appendQuery += `, id: [${_ids}]`
    }

    return `{
        totalCount: entryCount(section: "${section}")
        resultsCount: entryCount(section: "${section}" ${appendQuery})
        entries(section: "${section}", ${appendQuery}) {
            ${snippets.entry}

        }
    }`
}


/**********************************
 *
 *
 *  CONTENT
 *
 *
 **********************************/

// Homepage
export const home = `{
    entry(section: "home") {
        ${snippets.defaultData}
        section: sectionHandle

        ...on home_home_Entry {

            videos: relationEntriesVideo {
                ${snippets.entry}
                ...on video_default_Entry {
                    videoId
                    teaserStartTime
                    teaserDurationTime
                    ${snippets.relationProgram}
                    ${snippets.student}
                }
            }
            heading
            ${getAsset('image', 'home.intro')}
            ${snippets.layout}
            ${snippets.seo}
            colour
        }
    }
}`

// Contact
export const contact = `{
    entry(section: "contact") {
        ${snippets.defaultData}
        section: sectionHandle

        ...on contact_contact_Entry {
            heading
            ${snippets.hero}
            ${snippets.seo}
        }
    }
}`

// Pages
export const page = slug => `{
    entry(section: "page", slug: "${slug}") {
        ${snippets.page}
    }
}`

// Articles
export const articles = `{
    entry(section: "articles") {
        ${snippets.entry}

        ...on articles_articles_Entry {
            ${snippets.hero}
            ${snippets.seo}
        }
    }
}`

export const article = slug => `{
    entry(section: "article", slug: "${slug}") {
        ${snippets.article}
    }
}`

// Programs
export const programs = `{
    entry(section: "programs") {
        ${snippets.entry}

        ...on programs_programs_Entry {
            ${snippets.hero}
            ${snippets.seo}
        }
    }
}`

export const program = slug => `{
    entry(section: "program", slug: "${slug}") {
        ${snippets.program}
    }
}`

// Video
export const videos = `{
    entry(section: "videos") {
        ${snippets.entry}

        ...on videos_videos_Entry {
            ${snippets.hero}
            ${snippets.seo}
        }
    }
}`

export const video = slug => `{
    entry(section: "video", slug: "${slug}") {
        ${snippets.video}
    }
}`


export default {
    // GENERAL
    general,

    // SEARCH
    categoryGroup,
    searchEntries,

    // CONTENT
    home,
    contact,
    page,
    articles,
    article,
    programs,
    program,
    videos,
    video
}
