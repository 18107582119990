<template>
    <div class="c-home-hero">
        <!-- inner -->
        <div class="c-home-hero__inner grid" :class="{ 'c-home-hero__inner--visible': isHomeHeroReady }">
            <!-- left column video component -->

            <div class="c-home-hero__inner__video">
                <home-hero-video
                    ref="videos"
                    :slides="slides"
                    :current-slide="currentSlide"
                    :length-array="slides.length"
                    :is-animation-started="animation.isStarted"
                    @goToNextSlide="userClickedNextSlide"
                />
            </div>

            <!-- right copy  -->
            <div class="c-home-hero__inner__copy">
                <home-hero-copy
                    v-if="animation.copy.isVisible"
                    :video="slides[currentSlide]"
                    :current-slide="currentSlide"
                />
            </div>
        </div>
        <shared-progress :progression="progress.progression" :colour="setCustomBackgroundColor(currentSlide)" />
    </div>
</template>

<script>
import { gsap } from "gsap/all";

import { mapGetters } from "vuex";

import HomeHeroVideo from "@/templates/components/HomeHero/HomeHeroVideo/HomeHeroVideo";
import HomeHeroCopy from "@/templates/components/HomeHero/HomeHeroCopy/HomeHeroCopy";
import SharedProgress from "components/Shared/SharedProgress";

export default {
    components: {
        HomeHeroCopy,
        HomeHeroVideo,
        SharedProgress
    },
    props: {
        slides: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            currentSlide: 0,

            progress: {
                progression: 0,
                timeline: null,
                duration: 10
            },
            listColour: ["brown", "blue", "green"],
            animation: {
                isVisible: false,
                video: {
                    isVisible: false,
                    timeout: null
                },
                copy: {
                    isVisible: false,
                    timeout: null
                }
            }
        };
    },
    watch: {
        currentSlide: {
            handler: "updateCustomBgColor",
            immediate: true
        },
        isLoaderCompleted: {
            handler(loading) {
                loading ? this.triggerAnimation() : null;
            },
            immediate: true
        },
        isHomeHeroReady(bool) {
            bool ? this.triggerAnimation() : null;
        }
    },
    computed: {
        ...mapGetters({
            isLoaderCompleted: "loader/isLoaderCompleted",
            isHomeHeroReady: "hero/isHomeHeroReady"
        })
    },
    beforeDestroy() {
        this.progress.timeline ? this.destroyTimelime() : null;
    },

    methods: {
        setCustomBackgroundColor(currentSlide) {
            // return a fall back
            return currentSlide <= this.listColour.length - 1 ? this.listColour[currentSlide] : this.listColour[0];
        },

        updateCustomBgColor() {
            document.body.style.setProperty(
                "--home-hero-bg",
                `var(--color-${this.setCustomBackgroundColor(this.currentSlide)})`
            );
        },

        ////////////////////////////////
        //       START ANIMATION TEXT && VIDEO
        ////////////////////////////////
        triggerAnimation() {
            this.isHomeHeroReady && this.isLoaderCompleted && !this.animation.isStarted ? this.displayBlock() : null;
        },
        displayBlock() {
            this.animation.isStarted = true;
            this.displayVideo();
            this.setProgressTimeline(); // avoid the progression to start too earlier
        },

        displayVideo() {
            gsap.to(".c-home-hero__inner__video", {
                x: 0,
                scale: 1,
                duration: 1,
                ease: "power3.out",
                onComplete: () => {
                    this.displayCopy();
                }
            });
        },

        displayCopy() {
            this.animation.copy.isVisible = true;
        },

        ////////////////////////////////
        //       END ANIMATION TEXT && VIDEO
        ////////////////////////////////

        ////////////////////////////////
        //       START COUNT DOWN
        ////////////////////////////////
        setProgressTimeline() {
            this.progress.timeline = gsap.timeline({
                repeat: -1,
                onRepeat: () => {
                    this.goToNextSlide();
                }
            });
            this.progress.timeline.to(this.progress, {
                progression: 100,
                duration: this.progress.duration,
                ease: "none"
            });
        },
        goToNextSlide() {
            this.progress.timeline ? this.progress.timeline.seek(0) : null;
            this.updateSlide();
        },
        ////////////////////////////////
        //       END COUNT DOWN
        ////////////////////////////////
        ////////////////////////////////
        //       START UPDATE SLIDER
        ////////////////////////////////

        updateSlide() {
            // is this slide the end of the array
            this.currentSlide + 1 >= this.slides.length ? this.setSlide(0) : this.setSlide(this.currentSlide + 1);
        },
        setSlide(slideNumber) {
            this.currentSlide = slideNumber;
        },

        ////////////////////////////////
        //       END UPDATE SLIDER
        ////////////////////////////////

        ////////////////////////////////
        //       START DESTROY TIMELINE
        ////////////////////////////////
        destroyTimelime() {
            this.progress.timeline.kill();
            this.progress.timeline = null;
        },
        ////////////////////////////////
        //       END DESTROY TIMELINE
        ////////////////////////////////

        userClickedNextSlide() {
            this.goToNextSlide();
        }
    }
};
</script>

<style lang="scss">
.c-home-hero {
    @include transition(0.5s ease all);

    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    height: calc(100vh - (var(--nav-height) + var(--grid-gutter-half)));

    @media #{md('xs')} {
        min-height: 90rem; // fall back for small screens that aren't phones
    }

    &__inner {
        --home-hero-opacity: 0;
        @include transition(0.5s ease opacity); // this is a fall back, nobody should be able to see this animation
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
        justify-content: center;
        height: 100%;

        opacity: var(--home-hero-opacity);

        @media #{md('md')} {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(1, 1fr);
        }

        &--visible {
            --home-hero-opacity: 1;
        }
        &__video {
            // animation
            @include transform(translateX(500px) scale(0.8));

            order: 1;
            grid-column: 1/8;
            display: flex;
            align-items: center;
        }

        &__copy {
            order: 2;
            display: flex;
            align-items: center;
            grid-row: 2 / 4;
            pointer-events: none;
            @media #{md('md')} {
                grid-column: 9/13;
                grid-row: 1 / 1;
            }
        }
    }
}
</style>
