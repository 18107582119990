<template>
    <div class="b-featured-video" v-if="data && data.video.length">
        <!-- <div class="b-featured-video__section-title">
            <section-title section-name="En lumière" />
        </div> -->
        <div class="b-featured-video__inner">
            <!-- copy -->
            <div class="b-featured-video__inner__copy">
                <block-featured-video-copy :data="data.video[0]" />
            </div>
            <!-- video -->
            <div class="b-featured-video__inner__video">
                <block-featured-video-section :data="data.video[0]" />
            </div>
        </div>
    </div>
</template>

<script>
import BlockFeaturedVideoSection from "blocks/BlockFeaturedVideo/BlockFeaturedVideoSection/BlockFeaturedVideoSection";
import BlockFeaturedVideoCopy from "blocks/BlockFeaturedVideo/BlockFeaturedVideoCopy";
import SectionTitle from "components/SectionTitle/SectionTitle";
export default {
    components: {
        BlockFeaturedVideoSection,
        BlockFeaturedVideoCopy,
        SectionTitle
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.b-featured-video {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    position: relative;
    margin-top: var(--grid-gutter);

    @media #{md("sm")} {
        margin-top: calc(2.5 * var(--grid-gutter));
    }
    @media #{md("md")} {
        margin-top: 0;
    }

    &__section-title {
        padding-bottom: var(--grid-gutter);
        @media #{md("md")} {
            position: absolute;
            padding-bottom: 0px;
        }
    }
    &__inner {
        display: grid;
        gap: var(--grid-gutter);
        grid-template-columns: repeat(4, 1fr);

        @media #{md("md")} {
            grid-template-columns: repeat(12, 1fr);
        }

        &__copy {
            order: 2;
            grid-column: 1/4;
            @media #{md("md")} {
                order: 1;
                grid-column: 1/6;
            }
        }
        &__video {
            order: 1;
            grid-column: 1/5;
            min-height: 100%;
            height: 100%;
            min-height: 200px;
            display: grid;
            align-items: start;
            @media #{md("xs")} {
                min-height: 300px;
            }
            @media #{md("md")} {
                order: 2;
                grid-column: 6/13;
                min-height: 100%;
            }
        }
    }
}
</style>
