<template>
    <div class="l-builder | grid">
        <slot name="beforeSections" v-reveal.once />
        <section
            v-for="(section, i) in sections"
            class="l-builder__inner"
            :class="[
                '-s',
                {
                    '-has-padding':
                        section.hasPadding || (section.hasBackgroundColor && section.sectionWidth != 'w-full'),
                    '-has-background-color': section.hasBackgroundColor,
                    '-has-title': section.title !== null,
                    '-is-empty': section.children.length === 0,
                    '-revealed': isRevealed
                },
                section.sectionWidth
            ]"
            :key="`section-${i}`"
            :id="sectionId(section)"
            v-reveal.once
        >
            <div class="-s__title" v-if="section.title">
                <span class="">{{ section.title }}</span>
            </div>

            <template v-if="section.children.length > 0">
                <div v-for="(block, j) in section.children" :key="`block-${j}`" class="-b" :class="blockClasses(block)">
                    <component :is="block.typeHandle" :data="block" />
                    <slot name="afterBlock" v-bind="{ index: i }" />
                </div>
            </template>
        </section>
        <slot name="afterSections" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { DELAY } from "src/constants";

import BlockAccordions from "blocks/BlockAccordions";
import BlockActivities from "blocks/BlockActivities";
import BlockContent from "blocks/BlockContent";
import BlockCTA from "blocks/BlockCTA";
import BlockFeaturedArticle from "blocks/BlockFeaturedArticle";
import BlockFeaturedVideo from "blocks/BlockFeaturedVideo";
import BlockGallery from "blocks/BlockGallery";
import BlockHeading from "blocks/BlockHeading";
import BlockImage from "blocks/BlockImage";
import BlockSpacer from "blocks/BlockSpacer";
import BlockVideo from "blocks/BlockVideo";
import BlockPersons from "blocks/BlockPersons";
import BlockTabs from "blocks/BlockTabs";
import BlockAddress from "blocks/BlockAddress";
import BlockForm from "blocks/BlockForm";
import BlockButtons from "blocks/BlockButtons";
import BlockTable from "blocks/BlockTable";
import BlockPartners from "blocks/BlockPartners";
import BlockFees from "blocks/BlockFees";
import BlockTextImage from "blocks/BlockTextImage2";

import { toKebab, toSnake } from "src/utils";

export default {
    name: "AppBuilder",
    props: {
        sections: {
            type: Array,
            default: () => []
        }
    },
    components: {
        BlockAccordions,
        BlockActivities,
        BlockButtons,
        BlockContent,
        BlockFees,
        BlockAddress,
        BlockForm,
        BlockCTA,
        BlockFeaturedArticle,
        BlockFeaturedVideo,
        BlockGallery,
        BlockHeading,
        BlockImage,
        BlockSpacer,
        BlockTabs,
        BlockVideo,
        BlockPersons,
        BlockPartners,
        BlockTable,
        BlockTextImage
    },
    data() {
        return {
            isRevealed: false,
            timeoutRevealAppBuilder: null
        };
    },
    beforeDestroy() {
        this.timeoutDestroyer();
    },
    computed: {
        ...mapGetters({
            isLoaderCompleted: "loader/isLoaderCompleted"
        })
    },
    watch: {
        isLoaderCompleted(bool) {
            bool ? this.toggleRevealWithDelay() : this.toggleIsReveal(false);
        }
    },
    methods: {
        sectionId(section) {
            return `section-${toKebab(section.title) || section.id}`;
        },
        blockClasses(block) {
            let classes = [];

            if (block.displayHalf) classes.push("-half");

            classes.push(toSnake(block.typeHandle));

            return classes;
        },

        ////////////////////////////////
        //       START MANAGE REVEALS
        ////////////////////////////////
        toggleRevealWithDelay() {
            // to match hero
            this.timeoutRevealAppBuilder = setTimeout(() => {
                this.toggleIsReveal(true);
                this.timeoutDestroyer();
            }, DELAY.HERO + 700);
        },

        toggleIsReveal(bool) {
            this.isRevealed = bool;
        },
        timeoutDestroyer() {
            this.timeoutRevealAppBuilder
                ? (clearTimeout(this.timeoutRevealAppBuilder), (this.timeoutRevealAppBuilder = null))
                : null;
        }
        ////////////////////////////////
        //       END MANAGE REVEALS
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.l-builder {
    --builder-section-count-start: 0;

    .p-home & {
        --builder-section-count-start: 1;
    }

    counter-reset: sectionCounter;
    counter-set: sectionCounter var(--builder-section-count-start, sectionCounter);

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .is-safari .p-home & {
        // Fix pour 186 car Safari ne support par Counter Set: https://mambomambo-team.atlassian.net/browse/EMCV-186
        counter-increment: sectionCounter;
    }

    &__inner {
        @include shared-reveal-animation-css;
    }

    .-s {
        --block-spacer: var(--grid-gutter);
        --section-spacer: calc(var(--grid-gutter-double) * 2);
        margin-top: var(--section-spacer);
        width: 100%;
        height: fit-content;

        @media #{md("md")} {
            --block-spacer: var(--grid-gutter-half);
            --section-spacer: var(--grid-gutter-double);
        }

        // Increment section with title
        &.-has-title {
            counter-increment: sectionCounter;

            // Override spacing, equals to --block-space instead of --section-spacer
            .-s__title:only-child {
                margin-bottom: calc(var(--grid-gutter) * -2);
            }
        }

        // Modifiers
        &.-has-padding {
            padding: var(--grid-gutter);
        }

        &.-has-background-color {
            &:after {
                @include pseudo-el($width: auto, $height: auto, $bg: var(--page-color));
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: -1;

                border-radius: $border-radius-xs;
            }
        }

        // Width
        &.w-full {
            width: 100%;

            &.-has-background-color {
                padding-top: var(--grid-gutter-double);
                padding-bottom: var(--grid-gutter-double);

                @media #{md("md")} {
                    padding-top: var(--grid-gutter-double);
                    padding-bottom: var(--grid-gutter-double);
                }

                &:after {
                    left: calc(var(--grid-gutter-double) * -1);
                    right: calc(var(--grid-gutter-double) * -1);
                    border-radius: 0;
                }
            }
        }

        &.-is-empty {
            padding-bottom: 0;

            &.-has-title {
                &.w-full {
                    & + *,
                    & + [class*=" w-1"] + [class*=" w-2"],
                    & + [class*=" w-1\/2"] + [class*=" w-1\/2"],
                    & + [class*=" w-2"] + [class*=" w-1"] {
                        margin-top: var(--grid-gutter-double);

                        @media #{md("md")} {
                            margin-top: var(--grid-gutter);
                        }
                    }
                }
            }
        }

        // Builder exceptions
        &.w-1\/3,
        &.w-2\/3 {
            + .w-1\/2,
            + .w-1\/3,
            + .w-2\/3 {
                @media #{md("sm", "max")} {
                    margin-top: var(--grid-gutter-double);
                }
            }
        }

        &.w-1\/2 {
            + .w-1\/2,
            + .w-1\/3,
            + .w-2\/3 {
                @media #{md("md", "max")} {
                    margin-top: var(--grid-gutter-double);
                }
            }
        }

        @media #{md("sm")} {
            &.w-1\/3,
            &.w-1\/2,
            &.w-2\/3 {
                width: calc(50% - var(--grid-gutter));
            }
        }

        @media #{md('lg')} {
            &.w-1\/2 {
                width: calc(50% - var(--grid-gutter-half));
            }
            &.w-1\/3 {
                width: calc(33.33% - var(--grid-gutter-half));
            }
            &.w-2\/3 {
                width: calc(66.66% - var(--grid-gutter-half));
            }
        }

        // Add extra padding-bottom to last section, compensate for footer
        &:last-of-type {
            padding-bottom: var(--footer-offset-gap) !important;
        }

        // Title
        &__title {
            &:before {
                content: counter(sectionCounter, decimal-leading-zero);
                display: inline-block;
                padding-right: var(--grid-gutter-half);
            }
        }

        // Blocks
        .-s__title + .-b {
            margin-top: calc(var(--block-spacer) * 2) !important;
        }
        .-b {
            &:nth-child(n + 2) {
                margin-top: var(--block-spacer);
            }

            &:nth-last-child(n + 2) {
                @media #{md("xs", "max")} {
                    margin-bottom: var(--block-spacer);
                }
            }

            & + .block-boxed-content {
                @media #{md("xs", "max")} {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            &.block-c-t-a {
                + .block-c-t-a,
                + .block-heading {
                    @media #{md("sm", "max")} {
                        --block-spacer: var(--grid-gutter-double);
                    }
                }
            }

            &.block-c-t-a.-half {
                + .block-c-t-a.-half {
                    border-top: 1px solid rgba(black, 0.25);

                    @media #{md("sm", 'max')} {
                        > .b-cta {
                            margin: var(--block-spacer) 0;
                        }
                    }

                    @media #{md("sm")} {
                        padding-right: 0;
                        border-top: 0;

                        > .b-cta {
                            border-left: 1px solid rgba(black, 0.25);
                            padding-left: var(--grid-gutter);
                        }
                    }
                }

                @media #{md("sm", 'max')} {
                    --block-spacer: var(--grid-gutter-double);
                }

                @media #{md("sm")} {
                    max-width: calc(50% - var(--grid-gutter));
                    padding-right: var(--grid-gutter);
                    float: left;
                }
            }
        }
    }
}
</style>
