<template>
    <ul class="c-alc-img js-c-alc-img">
        <div class="c-alc-img__main js-c-alc-img__main">
            <animated-large-copy-img-fit
                class="c-alc-img__main__asset js-c-alc-img__main__asset"
                :image="image"
                :is-img-circle="isImgCircle"
                :vertical-position="verticalPosition"
            />
        </div>
    </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { gsap, ScrollTrigger } from "gsap/all";
import { DELAY } from "src/constants";
import AnimatedLargeCopyImgFit from "@/templates/components/AnimatedLargeCopy/AnimatedLargeCopyImgFit";

gsap.registerPlugin(ScrollTrigger);

export default {
    components: {
        AnimatedLargeCopyImgFit
    },
    props: {
        image: {
            type: Object | Array,
            required: false,
            default: null
        },
        isImgCircle: {
            type: Boolean,
            required: false,
            default: false
        },
        isParallax: {
            type: Boolean,
            required: false,
            default: true
        },
        verticalPosition: {
            type: String,
            required: false,
            default: "bottom",
            validator: type => ["top", "center", "bottom"].indexOf(type) !== -1
        }
    },
    data() {
        return {
            imageParallaxTimeline: null
        };
    },
    computed: {
        ...mapGetters({
            isLoaderCompleted: "loader/isLoaderCompleted"
        })
    },

    watch: {
        isLoaderCompleted: {
            handler(loading) {
                loading ? setTimeout(this.reveal, DELAY.DECO) : null;
            },
            immediate: true
        }
    },
    mounted() {
        window.addEventListener("resizeEnd", this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener("resizeEnd", this.onResize);
        this.killExistingScrollTrigger();
    },
    methods: {
        reveal() {
            gsap.to(".js-c-alc-img__main", {
                opacity: 1,
                y: 0,
                duration: 0.5,
                ease: "power2.in"
            });

            this.isParallax ? this.setParallax() : null;
        },

        //======= START PARALLAX IMG =======//

        placeRoundImageBeforeParallax() {
            // get current position from the top
            const el = document.getElementsByClassName("js-c-alc-img")[0];
            const positionImageY = el.getBoundingClientRect().y;

            const screenHeight = window.innerHeight;

            const travelledScrollOnMount = (screenHeight / positionImageY) * 100;

            const ratioScreenVsTravel = 100 / screenHeight;

            gsap.set(".js-c-alc-img__main__asset", {
                y: 100 - travelledScrollOnMount * ratioScreenVsTravel
            });
        },

        setParallax() {
            // kill before init a new scrollTrigger to avoid bug
            this.killExistingScrollTrigger();

            // run scroll trigger
            this.runScrollTrigger();
        },
        killExistingScrollTrigger() {
            this.imageParallaxTimeline ? this.imageParallaxTimeline.kill() : null;
        },

        runScrollTrigger() {
            this.imageParallaxTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: ".js-c-alc-img",
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 1
                }
            });

            this.imageParallaxTimeline.to(".js-c-alc-img__main", { y: -100 });
        },
        //======= END PARALLAX IMG =======//

        onResize() {
            this.isImgCircle ? (this.placeRoundImageBeforeParallax(), this.setParallax()) : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-alc-img {
    display: flex;
    list-style: none;
    flex: 1;
    width: 100%;
    &__main {
        display: flex;
        flex-direction: column;
        width: 100%;
        opacity: 0;

        @media #{md('sm')} {
            /* needed for the animation over and below the text on desktop only */
            z-index: 2;
        }
        &__asset {
            margin-top: 5rem;
            opacity: 1;
        }
    }
}
</style>
