<template>
    <div class="c-page-content">
        <div
            v-if="page.title"
            class="c-page-content__header | t-content"
        >

            <animated-large-copy v-if="page.articleType" title-size="5"   :copy="page.articleType.title" :is-visible="isBeforeVisible"  custom-ref="before"  :is-axis-y="false" :duration-in="2"/>
            <animated-large-copy v-if="postDate" class="-postDate" title-size="5-regular"   :copy="postDate" :is-visible="isBeforeVisible"  custom-ref="before"  :is-axis-y="false" :duration-in="2"/>
            <animated-large-copy title-size="3"   :copy="page.title" :is-visible="isTitleVisible"  custom-ref="animText"  :is-axis-y="false" :duration-in="2"/>


        </div>
        <transition name="fade-page">
        <div class="c-page-content__body | t-content" v-if="isTitleVisible">
        <div
            v-if="page.description || page.body"
            class="c-page-content__description | t-cms"
            v-html="page.description || page.body"
        />

        <div
            class="c-page-content__builder"
            v-if="page.section == 'article' && page.sections && page.sections.length"
        >
            <app-builder-article
                :blocks="page.sections"
            />
        </div>

        <div
            v-if="page.nominations || page.awards"
            class="c-page-content__prizes"
        >
            <prize-list
                :nominations="page.nominations"
                :awards="page.awards"
            />
        </div>

        <div
            v-if="$slots.bio"
            class="c-page-content__bio"
        >
            <slot name="bio" />
        </div>
        </div>
        </transition>
    </div>
</template>

<script>
import { formatDate } from 'src/utils'

import AnimatedLargeCopy from "components/AnimatedLargeCopy/AnimatedLargeCopy";
import AnimText from 'objects/AnimText'
import PrizeList from 'components/PrizeList'
import AppBuilderArticle from "layout/AppBuilderArticle";

import { DELAY } from "src/constants";
import { mapGetters } from 'vuex';

export default {
    components: {
    name: 'PageContent',
    AnimatedLargeCopy,
        AnimText,
        AppBuilderArticle,
        PrizeList
    },
    data: () =>({
        isBeforeVisible: false,
        isTitleVisible: false
    }),
    props: {
        page: {
            type: Object
        }
    },
    computed: {
        ...mapGetters({
            isLoading: 'loader/isLoading',
            isLoaderCompleted: 'loader/isLoaderCompleted',
        }),
        postDate() {
            if (this.page.postDate) {
                return formatDate(this.page.postDate, { month: 'numeric'})
            }
        },
    },
    mounted() {
        window.addEventListener('resizeEnd', this.invalideTextSplitting)
    },
    methods: {

        setVisible() {
            // there is either a title and a kicker, or the title in solo. They can't be deplay at the same time
            this.page.articleType ? this.displayTitles(): this.displayTitle(0);
        },

        displayTitles(){
            setTimeout(() => this.isBeforeVisible = true, DELAY.HERO)

              this.displayTitle(500)
        },
        displayTitle(extraDelay){

            setTimeout(() => this.isTitleVisible = true, DELAY.HERO + extraDelay)
        },


    },
    watch: {
        isLoaderCompleted(loaded) {
            if(loaded) {
                this.setVisible()
            }
        }
    }
};
</script>

<style lang="scss">
.c-page-content {
    display: grid;
    gap: var(--grid-gutter);
    margin-top: var(--grid-gutter);

    @media #{md("md")} {
        > *:not(.c-page-content__header) {
            padding-right: calc( var(--grid-gutter) + 1rem );
        }
    }

    &__header {
        gap: 0.5rem;
    }

    &__body {
        gap: var(--grid-gutter);
        @include transform(translateY(0rem));
    }

    &__before {
        transition: opacity .4s .4s ease-in, transform .4s .4s ease-in;
        overflow: hidden;
    }

    &__student {
        font-size: fs('medium');
        display: inline-block;
        transition: opacity .4s .6s ease-in, transform .4s .6s ease-in;
    }

    &__description {
        max-width: 50rem;
    }
}

.fade-page-enter-active,
.fade-page-leave-active {
    transition: all ease .5s;
    transition-delay: 2s;
}
.fade-page-enter,
.fade-page-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    @include transform(translateY(5rem));
}
</style>
