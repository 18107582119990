<template>
    <span class="o-bg" :style="`--nav-height: ${navHeight}px`"></span>
</template>

<script>
import { mapGetters } from "vuex";
import { DELAY } from "src/constants";
export default {
    name: "Background",
    data: () => ({
        height: 0,
        navHeight: 40
    }),
    props: {
        currentPage: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            isLoaderCompleted: "loader/isLoaderCompleted"
        })
    },
    mounted() {
        window.addEventListener("resize", this.setHeight);

        // TMP fix
        setTimeout(() => {
            this.setHeight()
        }, 8000)
    },
    methods: {
        getPageBackgroundLimit() {
            const limit = {
                home: document.querySelector("main"),
                articles: document.querySelector(".c-card__asset"),
                article: document.querySelector("footer"),
                page: null,
                program: document.querySelector("main"),
                videos: document.querySelector(".c-card__asset"),
                video: document.querySelector("footer")
            };
            return limit[this.currentPage];
        },
        setHeight() {


            const limitElement = this.getPageBackgroundLimit();
            const exception =
                this.currentPage == "home" ||
                this.currentPage == "program" ||
                this.currentPage == "video" ||
                this.currentPage == "article";

            this.height = limitElement
                ? exception
                    ? window.pageYOffset + limitElement.getBoundingClientRect().top
                    : window.pageYOffset + limitElement.getBoundingClientRect().top + limitElement.offsetHeight / 2
                : 0;

            this.isLoaderCompleted ? document.body.style.setProperty("--bg-height", this.height + "px") : null;

            this.getHeaderHeight();
        },

        ////////////////////////////////
        //       START GET HERO
        ////////////////////////////////
        getHeaderHeight() {
            // calculate hero height from this component to avoid potential issue
            this.navHeight = document.querySelector(".l-header__inner").getBoundingClientRect().height;
        }

        ////////////////////////////////
        //       END GET HERO
        ////////////////////////////////
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.setHeight);
    },
    watch: {
        isLoaderCompleted: {
            handler(loading) {
                loading
                    ? setTimeout(this.setHeight, DELAY.BACKGROUND)
                    : document.body.style.setProperty("--bg-height", "0px");
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss">
.o-bg {
    --nav-height: 80px;
    --bg-color: var(--page-color);
    --bg-transition-speed: 0.5s;
    @include transition(height var(--bg-transition-speed) ease);

    background: var(--bg-color);
    position: absolute;
    width: 100vw;
    height: var(--bg-height, 0);
    z-index: -10;
    top: 0;
    left: 0;

    .p-home & {
        --bg-transition-speed: 0s;
        --bg-height: 100vh;

        // this should be refactor using --nav-height, unfortunatly I need to refactor from AppHEader to AppBase and also Appbase to avoid conflict with page colours
        @media #{md('xs')} {
            // min-height: calc(
            //     90rem + 4.8rem + var(--grid-gutter-half)
            // );
            min-height: calc(90rem + var(--nav-height) + var(--grid-gutter-half));
            // fall back for small screens that aren't phones // switch to pixel to fix with methods this.fallBackSmallScreen()
        }
        // @media #{md('md')} {
        //     // min-height: calc(
        //     //     90rem + 8.6rem + var(--grid-gutter-half)
        //     // );
        //     min-height: calc(
        //         90rem + 8.6rem + var(--grid-gutter-half)
        //     );
        //     // fall back for small screens that aren't phones // switch to pixel to fix with methods this.fallBackSmallScreen()
        // }
    }
}
</style>
