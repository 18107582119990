<template>
    <ul class="c-table" :class="{ 'c-table--visible': !isReveal }" v-reveal.once="reveal">
        <li v-for="(line, index) in table" :key="index" ref="appTableLine" :class="[`c-table__line line${index + 1}`]">
            <div v-for="(col, idx) in line" :key="idx" :class="'-col ' + idx">
                {{ col }}
            </div>
        </li>
    </ul>
</template>

<script>
import { gsap } from "gsap";
export default {
    props: {
        table: {
            type: Array,
            required: true
        },
        isReveal: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isRevealed: false,
            timeline: null
        };
    },
    mounted() {
        this.isReveal ? this.setTimeline() : null;
    },
    watch: {
        isVisible(bool) {
            return bool && !this.isRevealed ? this.runAnimation() : null;
        }
    },
    beforeDestroy() {
        this.destroyTimeline();
    },
    methods: {
        reveal() {
            this.isRevealed && this.isReveal ? null : this.runAnimation();
        },
        runAnimation() {
            // hide reveal
            this.toggleIsReveal(true);
            // set the gsap animation
            this.playTimeline();
        },
        toggleIsReveal(bool) {
            this.isRevealed = bool;
        },
        setTimeline() {
            const durationTimeLine = 0.5;
            this.timeline = gsap.timeline({ paused: true });
            this.timeline.to(this.$refs.appTableLine, {
                y: 0,
                opacity: 1,
                duration: durationTimeLine,
                stagger: 0.1,
                ease: "power1.out"
            });
            // in case the timeline is already in on the viewport
            this.isVisible ? this.playTimeline() : null;
        },
        playTimeline() {
            this.timeline ? this.timeline.play() : null;
        },

        destroyTimeline() {
            this.timeline ? (this.timeline.kill(), (this.timeline = null)) : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-table {
    --table-gap: 2.5rem;
    --pre-animation-translate: 5rem;
    --pre-animation-opacity: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--table-gap);

    &--visible {
        --pre-animation-translate: 0rem;
        --pre-animation-opacity: 1;
    }

    &__line {
        @include shared-reveal-pre-animation(var(--pre-animation-translate), var(--pre-animation-opacity));
        display: grid;
        grid-auto-flow: column;
        gap: var(--table-gap);
        padding-bottom: 1.5rem;
        border-bottom: $default-border;
        justify-content: space-between;

        .-col:last-of-type {
            justify-self: end;
            width: fit-content;
        }
    }
}
</style>
