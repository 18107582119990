<template>
    <li class="c-home-hero-copy-btn">
        <btn tag="router-link" :url="`/${uri}`" label="En savoir plus" type="dark" />
    </li>
</template>

<script>
import { gsap } from "gsap";
import Btn from "components/Btn";
export default {
    components: {
        Btn
    },
    props: {
        uri: {
            type: String,
            required: true
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        isVisible(bool) {
            bool ? this.buttonTimeline.play() : null;
        }
    },
    data() {
        return {
            buttonTimeline: null
        };
    },
    mounted() {
        this.initAnimation();
    },
    beforeDestroy() {
        this.destroyTimeline();
    },
    methods: {
        initAnimation() {
            this.buttonTimeline = gsap.timeline({ paused: true });
            this.buttonTimeline.to(".c-home-hero-copy-btn .c-btn", {
                duration: 1,
                y: 0,

                ease: "expo.inOut",
                delay: 1.75
            });
        },
        destroyTimeline() {
            this.buttonTimeline.kill();
            this.buttonTimeline = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-home-hero-copy-btn {
    overflow: hidden;
    margin-top: var(--grid-gutter);
    pointer-events: auto;
    z-index: 1;
    .c-btn {
        @include transform(translateY(100%));
    }
}
</style>
