<template>
    <ul v-if="data" class="c-shared-type-title">
        <!-- icon -->
        <li class="c-shared-type-title__icon">
            <icon class="c-shared-type-title__icon__svg" :icon="icon" />
        </li>
        <!-- animated copy -->
        <li class="c-shared-type-title__copy">
            <anim-text
                :text="data.title"
                class="t-t-3"
                tag="h3"
                :visible="isVisible"
                reveal.once="true"
                :chars="false"
                :word="false"
                :lines="true"
                :is-axis-y="true"
                :duration-in="1"

            />
        </li>
    </ul>
</template>

<script>
import { gsap } from "gsap/all";
import AnimText from "@/templates/objects/AnimText";
import Icon from "@/templates/objects/Icon";
import { mapState } from 'vuex';
export default {
    components: {
        AnimText,
        Icon
    },
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {}
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            iconTimeline: null,
            isIconVisible: false,

        };
    },
    computed: {
        ...mapState({
            programs: state => state.global.programs
        }),
        icon() {
            const _program = this.programs.filter(prog => {
                return prog.id == this.data.id
            })[0]

            return _program.icon
        }
    },
    mounted() {
        this.setInconAnimationTimeline();
    },

    methods: {
        setInconAnimationTimeline() {
            this.iconTimeline = gsap.timeline();
            const durationAnimation = 0.5;

            this.iconTimeline.to(".c-shared-type-title__icon__svg", { scale: 1, duration: durationAnimation, delay:0.3 });
            // pause the timeline on mounted
            this.iconTimeline.pause();

            // not really usefull right now but possibly manage the play from somwhere where
            this.startAnimation();
        },
        startAnimation() {
            this.iconTimeline.play();
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-type-title {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    list-style: none;
    &__icon {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        height: 14px;

        &__svg {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            transform: scale(0);
            height: 14px;
        }
    }
    &__copy {
        text-transform: uppercase;
        overflow: hidden;
    }
}
</style>
