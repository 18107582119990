/*
 * Loader
 */

// State
const state = {
    loading: 0,
    firstload: true,
    isLoaderCompleted: false
};

// Getters
const getters = {
    isLoading: state => state.loading > 0,
    loadCount: state => state.loading,
    isLoaderCompleted: state => state.isLoaderCompleted
};

// Actions
const actions = {
    startLoad(store) {
        store.commit("updateLoad", store.state.loading + 1);
    },
    endLoad(store) {
        store.commit("updateLoad", store.state.loading - 1);
    },
    resetLoad(store) {
        store.commit("updateLoad", 0);
    },
    firstLoaded(store) {
        store.commit("setFirstLoad", false);
    }
    // loaderOut(store) {
    //     store.commit("setLoaderOut", false);
    // }
};

// Mutations
const mutations = {
    updateLoad(state, value) {
        // Uncomment for debug
        // console.log('updateLoad', value)
        state.loading = value;
    },
    setFirstLoad(state, isFirstLoaded) {
        state.firstload = isFirstLoaded;
    },
    setLoaderOut(state, bool) {
        state.isLoaderCompleted = bool;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
