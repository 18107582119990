<template>
    <div :class="className">
        <div v-if="(data && data.image) || $slots.asset" ref="asset" class="c-accordion__asset">
            <slot name="asset">
                <asset :asset="data.image" />
            </slot>
        </div>
        <div class="c-accordion__inner">
            <div class="c-accordion__header" @click="toggle">
                <slot name="heading">
                    <h3 v-if="data && data.heading" class="c-accordion__title">
                        {{ data.heading }}
                    </h3>
                </slot>
                <icon icon="arrow-down" class="c-accordion__icon" />
            </div>
            <div ref="container" class="c-accordion__container">
                <div ref="inner" class="c-accordion__content">
                    <slot name="content">
                        <div v-if="data && data.content" class="t-cms" v-html="data.content" />
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap/all";
import Icon from "../objects/Icon.vue";
import Asset from "../objects/Asset.vue";

export default {
    components: { Icon, Asset },
    name: "Accordion",
    data: () => ({
        isOpen: false,
    }),
    props: {
        data: {
            type: Object,
            default: null,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        upsideDown: {
            type: Boolean,
            default: false,
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        openDefault: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            let classname = "c-accordion";

            if (this.isOpen) {
                classname += " is-open";
            }
            if (this.data && this.data.image) {
                classname += " -has-asset";
            }

            if (this.upsideDown) {
                classname += ` -upside-down`;
            }

            return classname;
        },
    },
    mounted() {
        if (this.openDefault) this.open();
    },
    methods: {
        open() {
            this.isOpen = true;

            const height = this.$refs.inner.offsetHeight;

            this.tl = gsap.timeline({
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: "auto",
                    })
                    gsap.set(this.$refs.asset, {
                        height: "auto",
                    });
                }
            })

            this.tl
                .to(this.$refs.container, {
                    duration: 1,
                    height,
                    opacity: 1,
                    ease: "power2.out",
                })
                .to(this.$refs.asset, {
                    duration: 1,
                    opacity: 1,
                    delay: -.8,
                    height: "100%",
                    ease: "expo.out",
                })

        },
        close() {
            this.isOpen = false;

            this.tl = gsap.timeline({
                onComplete: () => {
                    gsap.set(this.$refs.container, {
                        height: 0,
                    })
                    gsap.set(this.$refs.asset, {
                        height: 0,
                    });
                }
            })
            this.tl
                .to(this.$refs.container, {
                    duration: 0.6,
                    height: 0,
                    opacity: 0,
                    ease: "power2.out",
                })
                .to(this.$refs.asset, {
                    duration: .5,
                    height: 0,
                    delay: -.5,
                    opacity: 0,
                    ease: "expo.out",
                })
        },
        toggle() {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }

            this.$nextTick(() => {
                this.$emit('toggle', this.isOpen, this.$el)
            })
        },
    },
};
</script>

<style lang="scss">
.c-accordion {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--grid-gutter);
    border-bottom: $default-border;

    .c-accordion__asset {
        order: 2;
        height: 0;
        opacity: 0;
    }
    @media #{md('sm')} {
        &.-has-asset {
            grid-template-columns: 1fr 1fr;
        }
        border-bottom: 0;

        .c-accordion__asset {
            order: 1;
        }

        .c-accordion__inner {
            order: 2;
            border-bottom: $default-border;
            padding-bottom: 1rem;
        }
    }

    &.is-open {

        .c-accordion__asset {
            padding-bottom: var(--grid-gutter-half);
        }

        .c-accordion__content {
            pointer-events: initial;
        }

        .c-accordion__icon {
            transform: rotateX(-180deg);
        }
    }

    &__icon {
        transition: transform 0.6s $out-quad;
    }

    &__title {
        text-transform: uppercase;
        font-size: 1.2rem;
        padding-right: 2rem;
    }

    &.-upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2;
        }

        .c-accordion__container {
            order: 1;
        }
    }
}

.c-accordion__header {
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.c-accordion__container {
    height: 0;
    opacity: 0;
    overflow: hidden;
}

.c-accordion__content {
    padding: 1em 0;
    pointer-events: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}
</style>
