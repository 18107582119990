
<template>
    <div
        v-if="data.persons && data.persons.length"
        class="b-persons"
        :class="{ 'is-grid': data.persons.length > 1 }"
    >
        <person
            v-for="(person, i) in data.persons"
            :key="i"
            class="b-persons__card"
            :data="person"
            :is-in-grid="data.persons.length > 1"
        />
    </div>
</template>

<script>
import Person from '../components/Person.vue'
export default {
    name: 'BlockPersons',
    components: {
        Person,
    },
    props: {
        data: null
    },
    methods: {
    }
}
</script>

<style lang="scss">
.b-persons {

    &.is-grid {
        --default-width: 12rem;
        width: 100%;
        display: grid;
        gap: var(--grid-gutter-double);
        grid-template-columns: repeat(auto-fill, minmax(var(--default-width), auto));

        .b-persons__card {
            grid-auto-flow: row;
        }

        @media #{md('sm')} {
            --default-width: 20rem;
            gap: var(--grid-gutter);
        }
        @media #{md('md')} {
            --default-width: 25rem;
        }
    }
}
</style>
