<template>
    <section class="programs-card-copy">
        <ul class="programs-card-copy__list">
            <!-- icon -->
            <li
                class="
                    programs-card-copy__list__copy
                    programs-card-copy__list__copy--icon
                "
            >
                <icon :icon="copy.icon" />
            </li>
            <!-- title -->
            <HomeProgramsCardCopyList
                v-if="copy.fullTitle"
                class="programs-card-copy__list__copy"
                :copy="copy.fullTitle"
            />

            <!-- length -->
            <HomeProgramsCardCopyList
                v-if="copy.duration"
                class="programs-card-copy__list__copy"
                :copy="copy.duration"
                title="Durée"
            />

            <!-- limit d'inscription -->
            <HomeProgramsCardCopyList
                v-if="copy.limitDate && copy.inscriptionRunning"
                class="programs-card-copy__list__copy"
                :copy="copy.limitDate"
                title="Limite d’inscription"
            />
        </ul>
    </section>
</template>

<script>
import Icon from "objects/Icon";
import HomeProgramsCardCopyList from "components/HomePrograms/HomeProgramsCard/HomeProgramsCardCopyList";
export default {
    components: {
        Icon,
        HomeProgramsCardCopyList
    },
    props: {
        copy: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.programs-card-copy {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-content: stretch;

    height: 100%;
    &__list {
        @include reset-list;

        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;

        &__copy {
            &--icon {
                .o-icon {
                    width: 35px;
                    height: 35px;
                    &::v-deep svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>
