<template>
    <div
        v-if="data"
        class="b-btns c-btn__group"
        :class="{'-vertical': !data.horizontal}"
    >
        <btn
            v-for="(btn, index) in buttons"
            :key="index"
            :tag="btn.tag"
            :label="btn.label"
            :url="btn.url"
            :type="btn.btnType"
            :icon-after="btn.externalLink ? 'external-link' : null"
        />

    </div>
</template>

<script>
import Btn from '../components/Btn.vue'
import { parseButtons } from 'src/store/parse'
export default {
    name: 'BlockButtons',
    components: { Btn },
    props: {
        data: Object
    },
    computed: {
        buttons() {
            const btns = parseButtons(this.data.buttons)
            return btns
        }
    },

}
</script>

<style lang="scss">
.b-btns {
    --btns-gap: 1em;
}
</style>
