<template>
    <div class="c-pagination">

        <div class="c-pagination__load-more">
            <div
                class="c-pagination__progress"
                :style="`--progress-perc: ${progress}%;`"
            >
                <span>
                    {{ loaded }} sur {{ total }}
                    <slot name="progressText" />
                </span>
                <span class="-load-progress"></span>
            </div>
            <transition name="btn-loadmore">
                <btn
                    v-show="nextPage <= totalPages"
                    :label="labelButton"
                    type="dark"
                    class="btn-load-more"
                    :class="{'btn-load-more--disabled': pageChanging}"

                    @click.native="changePage(nextPage)"
                />
            </transition>
        </div>

  </div>
</template>

<script>
import Btn from 'components/Btn'

export default {
    name: 'Pagination',
    components: {
        Btn
    },
    props: {
        loaded: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 12
        },
        isBlocked: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        current: 1,
        pageChanging: false,
        allDisplayed: false
    }),
    created() {

        if (this.currentPage)
            this.current = this.currentPage

        if (this.$route.query.page > this.totalPages)
            this.changePage(this.totalPages)

        if (this.$route.query.page == 'all')
            this.$router.replace({ query: { page: undefined } })
    },
       computed: {
        totalPages() {
            return Math.ceil(this.total / this.perPage)
        },
        nextPage() {
            return this.current + 1
        },
        prevPage() {
            return this.current - 1
        },
        progress()  {
            return (this.loaded / this.total) * 100
        },
        labelButton(){
            return this.pageChanging ? "chargement": "En voir plus";
        }
    },
    methods: {
        changePage(page) {

            if (this.isBlocked || this.pageChanging)
                return null

            if ( (page > 0 && page <= this.totalPages) && (page !== this.current) ) {
                this.$emit('block', true)
                this.current = page === 'all' ? 1 : parseInt(page)
                this.pageChanging = true
                this.$nextTick(() => {
                    this.$emit('pageChange', page)
                    this.$router.replace({ query: { page } })
                });
            }
        },
        reset() {

            if (this.isBlocked || this.pageChanging)
                return null

            this.allDisplayed = false

            if (this.$route.query.page) {
                this.current = 1
                this.pageChanging = true
                this.$router.replace({ query: { page: undefined } })
            }
        }
    },

    watch: {
        $route() {
            this.pageChanging = false
        },
    }
}
</script>
<style lang="scss">
.c-pagination {

    &__load-more {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 5rem;

        .btn-load-more {
            width: fit-content;
            margin-top: 2rem;
            border-radius: .3rem !important;

            &--disabled{
                pointer-events: none;
            }
        }
    }

    &__progress {
        margin-bottom: 2rem;

        .-load-progress {
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: -1rem;
            left: 0;
            background: #0002;

            &:before {
                @include pseudo-el($width: 100%, $height: 100%, $bg: var(--color-dark));
                position: absolute;
                left: 0;
                transform-origin: left;
                transform:  scaleX(var(--progress-perc));
                transition: scaleX .5s ease-out;
            }
        }
    }

    .btn-loadmore-enter-to {
        opacity: 1;
        transition: opacity .5s .5s ease;
    }
    .btn-loadmore-leave-to {
        opacity: 0;
        transition: opacity .5s .5s ease;
    }
}
</style>
