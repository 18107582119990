<template>
    <div v-if="activity" :data-activity="activity.heading" class="c-card-activity">
        <div class="c-card-activity__media" :class="mediaType">
            <div class="c-card-activity__media__inner">
                <base-video
                    v-if="activity.videoId"
                    :videoId="activity.videoId"
                    :options="{ controls: null }"
                    :muted="true"
                    :loop="true"
                    :isObjectFit="true"
                    :cursorLabel="null"
                    ref="activityVideo"
                    @loaded="setVideo"
                />
                <asset 
                    v-else
                    class="c-base-video__fallback-image" 
                    :asset="activity.image" 
                    :cover="true" 
                />
            </div>
        </div>
        <animated-large-copy
            :custom-ref="`blockActivity`"
            :copy="activity.heading"
            class="c-card-activity__heading"
            title-size="3"
            :lines="true"
            :is-axis-y="true"
            :is-visible="true"
            :is-visible-on-viewport="true"
            :is-first-line-end-left="false"
        />
        <div v-html="activity.description" class="c-card-activity__description t-cms"></div>
    </div>
</template>

<script>
import BaseVideo from "../components/BaseVideo.vue";
import Asset from "objects/Asset";
import AnimatedLargeCopy from "components/AnimatedLargeCopy/AnimatedLargeCopy";

export default {
    components: {
        BaseVideo,
        Asset,
        AnimatedLargeCopy
    },
    props: {
        activity: {
            type: Object
        }
    },
    data() {
        return {
            isEventListenerSetup: false,
            mediaType: this.activity.videoId ? '-type-video' : '-type-image'
        };
    },
    beforeDestroy() {
        this.isEventListenerSetup ? this.removeAllEventListener() : null;
    },
    methods: {
        ////////////////////////////////
        //       START VIDEO
        ////////////////////////////////
        setVideo() {
            this.$nextTick(() => {
                this.videoWrapper = this.$refs.activityVideo.$el;
                this.video = this.videoWrapper.querySelector("video");
                this.video.muted = true;
                this.setHandlers();
            });
        },
        setHandlers() {
            this.isEventListenerSetup = true;
            this.videoWrapper.addEventListener("mouseenter", () => this.handleMouseEnter());
            this.videoWrapper.addEventListener("mouseleave", () => this.handleMouseLeave());

            this.setVideoSize();
            window.addEventListener("resizeEnd", () => this.setVideoSize());
        },
        handleMouseEnter() {
            this.video.play();
        },
        handleMouseLeave() {
            this.video.pause();
        },
        setVideoSize() {
            this.$nextTick(() => {
                this.videoWrapper.style.setProperty("--height", this.video.offsetHeight + "px");
            });
        },
        ////////////////////////////////
        //       END VIDEO
        ////////////////////////////////

        removeAllEventListener() {
            this.videoWrapper.removeEventListener("mouseenter", this.handleMouseEnter());
            this.videoWrapper.removeEventListener("mouseleave", this.handleMouseLeave());
            window.removeEventListener("resizeEnd", () => this.setVideoSize());
        }
    }
};
</script>

<style lang="scss">
.c-card-activity {
    width: 100%;
    justify-self: center;
    display: grid;
    gap: 0.5rem;

    &__media {
        &__inner {
            padding-bottom: 100%;
            transition: background-color 0.4s ease-out;

            .-type-video &:hover {
                background-color: $color-dark;
            }
        }

        .c-base-video,
        .o-asset {
            overflow: hidden;
            position: absolute;
            top: var(--activity-gap);
            bottom: var(--activity-gap);
            left: var(--activity-gap);
            right: var(--activity-gap);
            border-radius: 50%;

            video {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &__heading {
        margin-top: 2rem;
    }

    &__description {
        max-width: min(35rem, 100%);
    }
}
</style>
