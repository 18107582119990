<template>
    <div class="c-prize-list">
        <div class="-prizes" v-if="nominations.length">
            <div class="-heading">
                <icon icon="nomination" />
                <span>Nominations</span>
            </div>
            <div
                v-for="(nomination, i) in nominations"
                :key="'nomination-' + i"
                class="-prizes-list"
            >
                <span
                    class="-festival"
                    v-html="nomination.festival[0].title"
                />
                <span v-if="nomination.label">{{ nomination.label }}</span>
            </div>
        </div>
        <div class="-prizes" v-if="awards.length">
            <div class="-heading">
                <icon icon="awards" />
                <span>Prix</span>
            </div>
            <div
                v-for="(award, i) in awards"
                :key="'award-' + i"
                class="-prizes-list"
            >
                <span class="-festival">
                    <icon
                        v-if="award.festival[0].icon"
                        :icon="award.festival[0].icon"
                    />
                    {{ award.festival[0].title }}
                </span>
                <span v-if="award.label">{{ award.label }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from 'objects/Icon'

export default {
    name: 'PrizeList',
    components: {
        Icon,
    },
    props: {
        nominations: {
            type: Array,
        },
        awards: {
            type: Array,
        }
    }
};
</script>

<style lang="scss">
.c-prize-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--grid-gutter-half);

    .-heading {
        display: grid;
        grid-auto-flow: column;
        gap: 1rem;
        justify-content: start;
        align-items: center;
    }

    .-festival {
        font-weight: bold;
    }

    > *:first-child:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, .25);
        padding-right: var(--grid-gutter-half);
    }

    .-prizes-list {
        display: grid;
        margin-top: 2rem;
        grid-template-columns: 1fr;
        gap: 1rem;
        // white-space: nowrap;
    }
}
</style>
