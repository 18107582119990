<template>
    <div class="o-video-background" :class="{ 'o-video-background--loaded': isLoaded }">
        <video
            v-if="isLoaded"
            v-view="playInViewport"
            v-cursor.label="{ label: 'Visionner', iconBefore: 'play' }"
            ref="player"
            class="lazyload"
            data-autoplay=""
            preload="none"
            muted="true"
            loop="true"
            playsinline="true"
            autoplay="true"
            allowtransparency
            :data-poster="poster.src"
            @lazyloaded="isLoaded = true"
        >
            <source :src="defaultVideo.src" :data-size="defaultVideo.size" :type="defaultVideo.type" />
        </video>
    </div>
</template>

<script>
export default {
    props: {
        videoId: {
            type: Number | String,
            required: true
        },
        teaser: {
            type: Object,
            defaul: () => {}
        }
    },
    data() {
        return {
            isLoaded: false,
            error: null,
            isPaused: false,
            resize: false,
            currentTime: this.teaser.start,

            videoSources: null,
            posterSources: null,

            poster: null,
            defaultVideo: null,
            defaultImage: null,

            client: null,
            client_id: "7c568520c7829412502eab3acb1cde6880a18e43",
            client_secret:
                "5gapVkLEASOEaUAHWujCJBsfTnqfuNBZe2+pbzTIrmK0FsxyC+6oinxqOOGjFGJpnboQ/vwMPr1scAnlFB/japcEm5iUm2gnI+pMStk/tsJKRx33dJqNRe6Cf2bB34dd",
            access_token: "ba41f6eb901c3a417094b809eba00159",
            timeoutLoader: null
        };
    },
    mounted() {
        this.createVimeo();
    },
    computed: {
        player() {
            return this.$refs.player;
        }
    },
    watch: {
        isLoaded: {
            handler(loaded) {
                if (loaded) {
                    this.$nextTick(() => {
                        this.player.addEventListener("timeupdate", this.playTeaserLoop);
                    });
                }
            },
            immediate: true
        }
    },
    beforeDestroy() {
        this.player ? this.player.removeEventListener("timeupdate", this.playTeaserLoop) : "";
        this.timeoutDestroyer();
    },
    methods: {
        ////////////////////////////////
        //       START SETUP AND RUN VIMEO
        ////////////////////////////////

        createVimeo() {
            const Vimeo = require("vimeo").Vimeo;
            const client = new Vimeo(this.client_id, this.client_secret, this.access_token);

            client.request(
                {
                    method: "GET",
                    path: `/videos/${this.videoId}`
                },
                (error, body) => {
                    if (error) {
                        this.isLoaded = false;
                        this.error = error;
                        return;
                    }
                    if (body.files) {
                        // Videos
                        this.videoSources = body.files.map(file => ({
                            size: file.width,
                            src: file.link,
                            type: file.type
                        }));

                        this.defaultVideo =
                            this.getVideoBySize(1280) ||
                            this.getVideoBySize(1366) ||
                            this.getVideoBySize(640) ||
                            this.getVideoBySize(1080) ||
                            this.getVideoBySize(720);
                    } else {
                        // TMP to avoid any JS error while the client update their Vimeo account(or not)
                        this.defaultVideo = {
                            size: null,
                            src: body.player_embed_url,
                            type: "video/mp4"
                        };
                    }

                    //   Posters;
                    this.posterSources = body.pictures.sizes.map(file => ({
                        size: file.width,
                        src: file.link
                    }));
                    //
                    this.poster = this.getPosterBySize(100);

                    this.defaultImage = this.getPosterBySize(960);

                    this.isLoaded = true;
                    this.error = null;
                    this.timeoutLoader = setTimeout(() => {
                        this.updateUploadedVideoLengthGlobally();
                        this.timeoutDestroyer();
                    }, 1000); // there is still a weird bug tiny even if we wait that the video load, but a little delay avoid this ugly bug https://mambomambo-team.atlassian.net/browse/EMCV-224
                }
            );
        },
        timeoutDestroyer() {
            this.timeoutLoader ? (clearTimeout(this.timeoutLoader), (this.timeoutLoader = null)) : null;
        },
        getPosterBySize(size) {
            const posters = this.posterSources;
            return posters.find(poster => poster.size === size);
        },
        getVideoBySize(size) {
            const videos = this.videoSources;
            return videos.find(video => video.size === size);
        },
        updateUploadedVideoLengthGlobally() {
            // this is used start the animation only after the videos are loaded: https://mambomambo-team.atlassian.net/browse/EMCV-224
            this.$store.dispatch("hero/updateHeroVideoLoaded");
        },
        ////////////////////////////////
        //       END SETUP AND RUN VIMEO
        ////////////////////////////////

        playTeaserLoop() {
            this.currentTime = this.player.currentTime;

            // Loop back to teaser start if outside of track interval
            if (this.currentTime < this.teaser.start || this.currentTime > this.teaser.start + this.teaser.duration) {
                this.player.currentTime = this.teaser.start;
            }
        },
        playInViewport(e) {
            let top = e.percent.top;
            let left = e.percent.left;

            if (top >= 1 || top <= 0 || left >= 0.99 || left <= 0) {
                this.player.pause();
            } else {
                this.player.play();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.o-video-background {
    --opacity-video: 0;
    display: flex;
    width: 100%;
    position: relative;

    video {
        @include transition(0.3s ease opacity);
        opacity: var(--opacity-video);
    }

    &--loaded {
        --opacity-video: 1;
    }
}
</style>
