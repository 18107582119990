<template>
    <div v-if="data && data.activities.length" class="b-activities">
        <card-activity
            v-for="(activity, i) in data.activities"
            :key="`b-activity-${i}`"
            :activity="activity"
            :class="`b-activity b-activity-${i}`"
        />
    </div>
</template>

<script>
import CardActivity from '../components/CardActivity.vue';

export default {
    name: 'BlockActivities',
    components: {
        CardActivity,
    },
    data: () => ({}),
    props: {
        data: false
    },
    mounted() {

    },
};

</script>

<style lang="scss">
.b-activities {
    --activity-gap: 1.7rem;

    display: grid;
    column-gap: var(--activity-gap);
    row-gap: calc(var(--activity-gap) * 2);
    grid-template-columns: min(calc(100vw - 2 * var(--grid-gutter-triple)), 40rem);
    justify-content: center;

    @media #{md('xs')} {
        --activity-gap: 3rem;
    }

    @media #{md('sm')} {
        grid-template-columns: 1fr 1fr;
    }

    @media #{md('lg')} {
        grid-template-columns: 1fr 1fr 1fr;
    }
}


</style>
