<template>
    <div class="c-animated-lc">
        <ul class="c-animated-lc__main">
            <li
                :ref="customRef"
                class="c-animated-lc__main__copy"
                :class="{ 'c-animated-lc__main__copy--hidden': isOverflow }"
            >
                <anim-text
                    :text="forceCopyToUppercase(copy)"
                    :class="setTextSize('t-t')"
                    :tag="setTextSize('h')"
                    :visible="isTextVisible()"
                    reveal.once="false"
                    :chars="chars"
                    :word="word"
                    :lines="lines"
                    :is-axis-y="isAxisY"
                    :is-last-line-above-img="isLastLineAboveImg"
                    :is-first-last-lines-below-img="isFirstLastLinesBelowImg"
                    :duration-in="durationIn"
                    :stagger-in="staggerIn"
                    :delay-feedback="delayFeedback"
                    @completed="animationIsCompleted"
                />
                <slot />
            </li>
            <li class="c-animated-lc__main__img" :class="{ 'img-circle': isImgCircle }">
                <animated-large-copy-img
                    v-if="image"
                    :image="image"
                    :vertical-position="verticalPosition"
                    :is-img-circle="isImgCircle"
                />

                <slot name="deco" />
            </li>
        </ul>
    </div>
</template>

<script>
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import AnimText from "@/templates/objects/AnimText";
import AnimatedLargeCopyImg from "@/templates/components/AnimatedLargeCopy/AnimatedLargeCopyImg";

export default {
    components: {
        AnimText,
        AnimatedLargeCopyImg
    },
    props: {
        customRef: {
            type: String,
            required: false,
            default: "c-animated-lc__main__copy"
        },
        copy: {
            type: String,
            required: true
        },
        titleSize: {
            type: String,
            required: false,
            default: "1"
        },
        chars: {
            type: Boolean,
            default: false
        },
        word: {
            type: Boolean,
            default: false
        },
        lines: {
            type: Boolean,
            default: false
        },

        isLastLineAboveImg: {
            type: Boolean,
            required: false,
            default: false
        },
        isFirstLastLinesBelowImg: {
            type: Boolean,
            required: false,
            default: false
        },

        image: {
            type: Object,
            required: false,
            default: null
        },
        isImgCircle: {
            type: Boolean,
            required: false,
            default: false
        },
        verticalPosition: {
            type: String,
            required: false,
            default: "bottom",
            validator: type => ["top", "center", "bottom"].indexOf(type) !== -1
        },
        isAxisY: {
            type: Boolean,
            required: false,
            default: true
        },

        isVisibleOnViewport: {
            type: Boolean,
            required: false,
            default: false
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: true
        },
        // Props only needed for the EMVC
        isFirstLineEndLeft: {
            type: Boolean,
            required: false,
            default: false
        },
        durationIn: {
            type: Number,
            required: false,
            default: 0.5
        },
        staggerIn: {
            type: Number,
            required: false,
            default: 0.1
        },
        delayFeedback: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            titleScrollTrigger: null,
            timeOutScrollTrigger: null,
            isTitleVisible: false,
            isOverflow: true,
            isCompleted: false
        };
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
            this.isVisibleOnViewport ? this.delaySetScrollTrigger() : null;
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
        this.clearTimeOutScrollTrigger();
    },

    methods: {
        ////////////////////////////////
        //       START SET SCROLL TRIGGER ON MOUNT
        ////////////////////////////////
        delaySetScrollTrigger() {
            // page transition duration >= 0.8s, so it's a easy patch for: https://mambomambo-team.atlassian.net/browse/EMCV-178
            this.timeOutScrollTrigger = setTimeout(() => {
                this.setScrollTrigger();
                this.clearTimeOutScrollTrigger();
            }, 800);
        },

        clearTimeOutScrollTrigger() {
            this.timeOutScrollTrigger ? clearTimeout(this.timeOutScrollTrigger) : null;
        },
        ////////////////////////////////
        //       END SET SCROLL TRIGGER ON MOUNT
        ////////////////////////////////
        //======= START STYLE AND RELATED METHODS =======//

        isTextVisible() {
            // either the animation start when the copy is on the viewport, either it's manually triggered from parent
            return (this.isTitleVisible && this.isVisibleOnViewport) || (this.isVisible && !this.isVisibleOnViewport);
        },

        forceCopyToUppercase(copy) {
            return copy.toUpperCase();
        },
        setTextSize(textClass) {
            return `${textClass}${this.titleSize}`;
        },

        //======= END STYLE AND RELATED METHODS =======//

        //======= START ANIMATION OF THE COPY =======//

        setScrollTrigger() {
            // kill before init a new scrollTrigger to avoid bug
            this.killExistingScrollTrigger();

            // run scroll trigger
            this.runScrollTrigger();
        },
        killExistingScrollTrigger() {
            this.titleScrollTrigger ? this.titleScrollTrigger.kill() : null;
        },
        runScrollTrigger() {
            this.titleScrollTrigger = ScrollTrigger.create({
                trigger: this.customTrigger(),
                start: "center bottom",
                onEnter: () => {
                    this.toggleTitle(true);
                }
            });
        },
        customTrigger() {
            // avoid bug if this component is reuse multiple time in the same page
            return this.$refs[this.customRef];
        },
        toggleTitle(bool) {
            this.isTitleVisible = bool;
        },

        //======= END ANIMATION OF THE COPY =======//

        ////////////////////////////////
        //       START ON ANIMATION COMPLETED
        ////////////////////////////////
        animationIsCompleted() {
            this.isFirstLineEndLeft ? this.moveFirstLineToTheLeft() : this.emitToParent("completed");
        },

        //======= START TRIGGER FIRST LINE ANIMATION =======//

        moveFirstLineToTheLeft() {
            this.disableOverFlow();
            this.startAnimationLineToLeft();
        },

        disableOverFlow() {
            this.isOverflow = false;
        },
        startAnimationLineToLeft(x = "-50%") {
            const findThisComponent = this.$refs[this.customRef];
            const child = findThisComponent.querySelector(`.o-at__l:nth-child(1)`);
            gsap.to(child, {
                duration: 1,
                x: x,
                onComplete: () => {
                    this.emitToParent("completed");
                    this.isCompleted = true; // allow resize to happens after the animation was completed: https://mambomambo-team.atlassian.net/browse/EMCV-130
                },
                ease: "expo.out"
            });
        },

        //======= END TRIGGER FIRST LINE ANIMATION =======//

        emitToParent(emitName) {
            this.$emit(emitName);
        },

        ////////////////////////////////
        //       END ON ANIMATION COMPLETED
        ////////////////////////////////

        ////////////////////////////////
        //       START RESIZE
        ////////////////////////////////
        onResize() {
            this.isVisibleOnViewport ? this.setScrollTrigger() : null;
            // allow resize to happens after the animation was completed: https://mambomambo-team.atlassian.net/browse/EMCV-130
            this.isCompleted ? this.moveFirstLineToTheLeft() : null;
        }
        ////////////////////////////////
        //       END RESIZE
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.c-animated-lc {
    display: flex;
    flex: 1;

    --max-radius: 355px;
    --radius: 40vw;

    &__main {
        display: flex;
        flex: 1;
        flex-direction: row;
        list-style: none;
        justify-content: space-between;

        &__copy {
            display: flex;
            flex-direction: column;
            flex: 1;
            z-index: 1;

            /* avoid the text to be cropped */ // https://mambomambo-team.atlassian.net/browse/EMCV-211
            padding-bottom: 3%;

            &--hidden {
                overflow: hidden;
            }
        }
        &__img {
            display: flex;
            position: absolute;
            width: 50%;
            height: 100%;
            right: 0px;
            top: 50%;

            &:not(.img-circle) {
                @media #{md('sm')} {
                    transform: translateY(-50%);
                }
            }
        }
    }
}
</style>
