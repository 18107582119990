<template>
    <div class="l-hero" :style="style">
        <animated-large-copy
            v-if="page"
            :copy="heroTitle"
            :is-last-line-above-img="true"
            :lines="true"
            :image="needImage ? page.image : null"
            :is-axis-y="false"
            :is-visible="isVisible"
            :isImgCircle="isImgCircle"
        >
            <template #deco>
                <div class="l-hero__deco" v-if="hasDeco" :class="'-' + page.decoType">
                    <div v-for="count in shapeCount" :key="count" ref="deco" :class="`-deco -deco-${count}`"></div>
                </div>
            </template>
        </animated-large-copy>

        <program-hero v-if="isProgramPage" :page="page" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";

import AnimatedLargeCopy from "components/AnimatedLargeCopy/AnimatedLargeCopy";
import Asset from "objects/Asset";

import { DELAY } from "src/constants";

import { gsap, ScrollTrigger } from "gsap/all";
import ProgramHero from "./ProgramHero.vue";
gsap.registerPlugin(ScrollTrigger);

export default {
    name: "AppHero",
    components: {
        AnimatedLargeCopy,
        Asset,
        ProgramHero
    },
    props: {
        page: {
            type: Object
        },
        image: {
            type: Object,
            default: () => {}
        },
        decoType: {
            type: String,
            default: "none",
            validator: dir => ["none", "vertical", "horizontal", "overlap"].indexOf(dir) != -1
        },
        shapeCount: {
            type: Number,
            default: 2
        }
    },
    data: () => ({
        isVisible: false
    }),
    computed: {
        ...mapGetters({
            isLoaderCompleted: "loader/isLoaderCompleted"
        }),
        isProgramPage() {
            return this.$store.state.global.currentPageName == "program";
        },
        style() {
            let styles = ` --deco-count: ${this.shapeCount};`;
            return styles;
        },
        needImage() {
            return this.page.decoType == "none" || this.page.decoType == "vertical";
        },
        isImgCircle() {
            return this.page.decoType == "vertical";
        },
        hasDeco() {
            return this.page.decoType && this.page.decoType !== "none";
        },
        heroTitle() {
            return this.page.heading || this.page.title;
        }
    },
    methods: {
        // TODO: set visible to true when the loader is done
        revealHero() {
            if (this.$refs.deco) {
                this.revealDeco();
            }
            this.isVisible = true;
        },

        revealDeco() {

            this.setDecoBackground();

            const verticalReveal = this.page.decoType == "vertical";
            const horizontalReveal = this.page.decoType == "horizontal";
            const overlapReveal = this.page.decoType == "overlap";

            gsap.to(".-deco", {
                "--x": horizontalReveal ? "50%" : 0,
                "--y": verticalReveal ? "50%" : overlapReveal ? "40%" : 0,
                duration: 0.6,
                opacity: 1,
                delay: 0.1,
                ease: "sine.inOut"
            });

        },
        setDecoBackground() {

            const background = document.querySelector(".o-bg"),
                  backgroundHeight = window.getComputedStyle(background).getPropertyValue("height"),
                  hasBackground = Number(backgroundHeight.replace("px", "")) > 10;

            // That means there is no bg
            // so the decos background should be the global page color
            if (hasBackground == false) {
                this.$refs.deco.forEach(d => d.style.setProperty("--deco-bg", "var(--page-color)"));
            } else {
                this.$refs.deco.forEach(d => d.style.removeProperty("--deco-bg"));
            }
        }
    },
    watch: {
        isLoaderCompleted: {
            handler(loaded) {
                if (loaded) setTimeout(this.revealHero, DELAY.HERO + 700);
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss">
.l-hero {
    margin-top: var(--grid-gutter-double);
    padding-top: var(--grid-gutter);
    padding-bottom: var(--grid-gutter-double);
    z-index: 0;
    display: flex;
    flex-direction: column;

    @media #{md('sm')} {
        margin-top: var(--grid-gutter);
    }

    .-spacer {
        --spacer-height: 10rem;
        width: 100vw;
        height: var(--spacer-height);
    }

    &__deco {
        --shape-size: max(25rem, 35vw);
        transform: translateX(-50%);
        position: absolute;

        min-width: var(--shape-size);
        min-height: var(--shape-size);

        @media #{md("sm")} {
            transform: translateX(0);
        }
    }

    .-deco {
        background: var(--deco-bg, white);
        width: var(--shape-size);
        height: var(--shape-size);
        border-radius: 50%;
        position: absolute;
        opacity: 0;
    }

    .-horizontal {
        .-deco:nth-child(2n) {
            transform: translateY(-20%) translateX(calc(-0.5 * var(--x)));
        }
        .-deco:nth-child(2n + 1) {
            transform: translateY(-20%) translateX(calc(var(--x) * 1.5 + var(--grid-gutter)));
        }
    }

    .-vertical {
        transform: translateX(0);

        .-deco {
            max-width: var(--max-radius);
            max-height: var(--max-radius);
            width: var(--radius);
            height: var(--radius);
        }

        .-deco:nth-child(2n) {
            transform: translateY(calc(-2 * var(--y)));
        }
        .-deco:nth-child(2n + 1) {
            transform: translateY(calc(var(--y) * -4 - var(--grid-gutter)));
        }
    }

    .-overlap {
        .-deco:nth-child(2n) {
            transform: translateY(calc(-2 * var(--y)));
            overflow: hidden;

            &:after {
                @include pseudo-el($width: 100%, $height: 100%, $bg: var(--page-color));
                z-index: -1;
                border-radius: 50%;
                transform: translateY(calc(var(--y) * 1.5));
            }
        }
        .-deco:nth-child(2n + 1) {
            transform: translateY(calc(var(--y) * -0.5));
        }
    }
}
</style>
