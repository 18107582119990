<template>
    <div class="c-alc-img-fit" :class="className">
        <asset
            class="c-alc-img-fit__img"
            :asset="image"
            :rounded="isImgCircle"
        />
    </div>
</template>

<script>
import Asset from "../../objects/Asset.vue";
export default {
    components: { Asset },
    props: {
        image: {
            type: Object | Array,
        },
        isImgCircle: {
            type: Boolean,
            required: false,
            default: false,
        },

        verticalPosition: {
            type: String,
            required: false,
            default: "bottom",
            validator: (type) =>
                ["top", "center", "bottom"].indexOf(type) !== -1,
        },
    },
    computed: {
        className() {
            return `c-alc-img-fit--${
                this.verticalPosition
            } ${this.isCircleImage()}`;
        },
    },
    methods: {
        isCircleImage() {
            return this.isImgCircle ? "c-alc-img-fit--circle" : "";
        },
    },
};
</script>

<style lang="scss" scoped>
.c-alc-img-fit {
    display: flex;
    flex: 1;
    width: 100%;
    height: auto;
    justify-content: flex-end;

    &--top {
        align-items: flex-start;
    }
    &--center {
        align-items: center;
    }
    &--bottom {
        align-items: flex-end;
    }

    &__img {
        object-fit: cover;
        width: 100%;
        max-height: 355px;
        height: 100%;
    }
    &.c-alc-img-fit--circle {
        max-width: var(--max-radius);
        max-height: var(--max-radius);
        width: var(--radius);
        height: var(--radius);
    }
}
</style>
