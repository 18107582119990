<template>
  <ul class="c-section-title">
    <!-- number -->
    <li class="c-section-title__number">
      <p>{{ this.addZeroFrontOfNumber(sectionNumber) }}</p>
    </li>
    <!-- copy -->
    <li class="c-section-title__copy">
      <p>{{ sectionName }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    sectionNumber: {
      type: Number,
      required: false,
      default: 1
    },
    sectionName: {
      type: String,
      required: true
    }
  },
  methods: {
    addZeroFrontOfNumber(sectionNumber) {
      return sectionNumber >= 10 ? sectionNumber : `0${sectionNumber}`;
    }
  }
};
</script>

<style lang="scss">
.c-section-title {
  @include reset-list;
  display: flex;
  flex-direction: row;
  padding-bottom: var(--grid-gutter);
  &__number {
    padding-right: var(--grid-gutter-half);
  }
}
</style>
