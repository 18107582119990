import { SECTION_HANDLES } from 'src/constants'
import {decodeHtmlCharCodes} from 'src/utils'
/*
 * Data parser
 */

// Utilities
const arrayHasItem = array => {
    return typeof array !== 'undefined' && array.length > 0
}

export const parseIcon = icon => {
    return icon && icon.name ? icon.name.replace('svg-', '') : null
}

export const parseStudent = student => {

    if (!student)
        return null

    if (student.image)
        student.image = arrayHasItem(student.image) ? student.image[0] : null

    return student
}

export const parseVideo = video => {

    if (!video)
        return null

    if (video.image)
        video.image = arrayHasItem(video.image) ? video.image[0] : null

    if (video.program)
        video.program = arrayHasItem(video.program) ? video.program[0] : null

    if (video.student)
        video.student = arrayHasItem(video.student) ? parseStudent(video.student[0]) : null

    return video
}

export const parseVideos = videos => {
    let _videos = []

    if (!arrayHasItem(videos))
        return

    videos.forEach(v => {

        v = parseVideo(v)

        if (v)
            _videos.push(v)

    })
    return _videos
}


export const parseButtons = buttons => {
    let _buttons = []

    if (!arrayHasItem(buttons))
        return

    buttons.forEach(b => {
        let target, url, label

        if ( b.externalLink == null && b.target == null )
            return

        if (b.target)
            target = arrayHasItem(b.target) ? b.target[0] : null

        url = b.externalLink || `/${target.uri}`,
        label = b.label || target.title

        _buttons.push({
            ...b,
            url,
            target,
            label: label,
            tag: b.externalLink ? 'a' : 'router-link',
        })
    })

    return _buttons
}

//Parse Programs
export const parsePrograms = (programs) => {

    if (programs && !(programs instanceof Array)) {
        return parsePrograms([programs])
    }

    if(!arrayHasItem(programs)) return

    const icons = {
        3635: "play",
        3323: "record",
        49: "stop",
    };

    programs.forEach((program) => {

        let _prog = Object.keys(icons)
            .filter( icon => program.id.indexOf(icon) != -1 )[0];

        program.icon = icons[_prog];
    })

    return programs
}

//Parse SEO
export const parseSEO = (seo) => {

    // Force encoding all special character (especially single quote used as apostrophe)
    seo.title = decodeHtmlCharCodes(seo.title)

    return seo
}


// Parse method
export const parseData = (type, data) => {

    // Standard single asset image
    if (data.image)
        data.image = arrayHasItem(data.image) ? data.image[0] : null

    // Clean buttons
    if (data.buttons)
        data.buttons = parseButtons(data.buttons)

    // Clean SEO
    if (data.seo)
        data.seo = parseSEO(data.seo)

    // Single program
    if (data.program)
        data.program = arrayHasItem(data.program) ? data.program[0] : null

    switch(type) {
        case(SECTION_HANDLES.HOME): {

            //
            data.videos = parseVideos(data.videos)

            break
        }

        // ARTICLE
        case(SECTION_HANDLES.ARTICLE): {

            data.colour = 'olive'
            data.articleType = arrayHasItem(data.articleType) ? data.articleType[0] : null

            break;
        }

        // VIDEO
        case(SECTION_HANDLES.VIDEO): {

            data.colour = 'blue'

            break;
        }
    }

    return data
}
