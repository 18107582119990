<template>
    <div v-if="data" class="b-cta" :class="{ '-horizontal': data.horizontal }">
        <div
            class="b-cta__inner | t-content"
            :class="{ 'b-cta__inner--with-img': data.image.length }"
        >
            <!-- copy -->
            <div v-if="data.heading || data.image.length" class="b-cta__inner__titre">
                <!-- the kebab case is a pain to call a component like that. You can keep it as CamelCase  -->
                <BlockCTAHero
                    :title="data.heading"
                    :img="data.image"
                    :content="data.horizontal ? data.content : null"
                />
                <span v-if="data.label" class="b-cta__inner__subtitle">{{ data.label }}</span>
            </div>

            <div class="b-cta__inner__copy">
                <div class="t-cms" v-html="data.content">{{ data.content }}</div>
            </div>
            <!-- button -->
            <div v-if="buttons && buttons.length" class="b-cta__inner__button | c-btn__group | -actions">
                <btn
                    v-for="(btn, index) in buttons"
                    :key="index"
                    :url="btn.url"
                    :tag="btn.tag"
                    :label="btn.label"
                    :type="btn.btnType"
                    :icon-after="btn.externalLink ? 'external-link' : null"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Btn from "components/Btn";
import BlockCTAHero from "blocks/BlockCTA/BlockCTAHero";
import { parseButtons } from 'src/store/parse'

export default {
    name: "BlockCta",
    components: {
        Btn,
        BlockCTAHero,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    computed: {
        buttons() {
            if (this.data.buttons)  {
                const _btns = parseButtons(this.data.buttons)

                return _btns
            }

        },
    },
    methods: {
    },
    watch: {
    },
};
</script>

<style lang="scss">
.b-cta {

    &__inner {
        --content-gap: 3rem;
        --content-actions-extra-gap: var(--grid-gutter-half);
        grid-template-columns: repeat(4, 1fr);

        &__titre {
            grid-column: 1/5;
        }
        &__copy {
            grid-column: 1/5;
            @media #{md("md")} {
                .b-cta__inner--with-img & {
                    grid-column: 1/3;
                }
            }

        }
        &__button {
            grid-column: 1/5;
            @media #{md("md")} {
                .b-cta__inner--with-img & {
                    grid-column: 1/3;
                }
            }
        }

        .t-cms figure {
            max-width: 22rem;
        }
    }

    &.-horizontal {
        .b-cta__inner {

            &__copy {
                max-width: 50rem;
            }
            @media #{md('sm')} {

                .b-cta-hero__full {
                    grid-template-columns: repeat(6, 1fr);

                    &__img {
                        grid-column: 1/4;
                    }

                    &__title {
                        grid-column: 4/7;
                    }
                }

                &__copy {
                   display: none;
                }
            }
        }
    }
}
</style>
