<template>
    <tag
        :is="tag"
        :href="url"
        :to="tag == 'router-link' && url !== null ? url : null"
        :rel="tag == 'a' ? 'noopener' : false"
        class="c-btn"
        :class="className"
    >
        <span class="c-btn__ripple">
            <span v-if="isRippleVisible" ref="ripple" class="c-btn__ripple__fill"></span>
        </span>
        <span class="c-btn__inner" :class="{ 'c-btn__inner--hover': show }">
            <icon v-if="iconBefore !== null" class="c-btn__icon" :icon="iconBefore" />
            <span class="c-btn__label">
                <slot>{{ btnLabel }}</slot>
            </span>
            <icon v-if="iconAfter !== null" class="c-btn__icon" :icon="iconAfter" />
        </span>
        <span
            v-if="isRippleVisible"
            class="js-ripple-hit"
            v-cursor.hidden

            @mouseenter="show"
            @mouseleave="hide"
        ></span>
    </tag>
</template>

<script>
import { gsap } from "gsap/all";
import Icon from "objects/Icon";

export default {
    name: "Btn",
    components: {
        Icon
    },
    props: {
        tag: {
            type: String,
            default: "button"
        },
        href: {
            type: String,
            default: null
        },
        label: {
            type: String | Number,
            default: "Button Label"
        },
        url: {
            type: String,
            default: null
        },
        iconBefore: {
            type: String,
            default: null
        },
        iconAfter: {
            type: String,
            default: null
        },
        type: {
            type: String | Array,
            default: "outline",
            validator: type => ["dark", "outline", "transparent", "light", "underline"].indexOf(type) !== -1
        },
        isRippleVisible: {
            type: Boolean,
            required: false,
            default: true
        },
        isBackgroundWhite: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        btnLabel() {
            return this.label.toString().toUpperCase();
        },
        isBgWhite() {
            return this.type != "dark";
        },

        className() {
            return `-${this.type}${this.isButtonNeedClassForRipple()}`;
        }
    },
    methods: {
        isButtonNeedClassForRipple() {
            return this.isBackgroundWhite ? " -white-background" : "";
        },
        ////////////////////////////////
        //       START RIPPLE EFFECT
        ////////////////////////////////
        show(e) {
            gsap.fromTo(
                this.$refs.ripple,
                {
                    ...this.getCoordinates(this.$el, e),
                    scale: 0
                },
                {
                    duration: 0.5,
                    x: 0,
                    y: 0,
                    scale: 1.5,
                    ease: "sine.in",
                    overwrite: 1
                }
            );
        },

        hide(e) {
            gsap.to(this.$refs.ripple, {
                duration: 0.5,
                ...this.getCoordinates(this.$el, e),
                scale: 0,
                ease: "power3.out",
                overwrite: 1
            });
        },

        getCoordinates($el, e) {
            const rect = $el.getBoundingClientRect();
            const x = e.clientX - rect.left - rect.width / 2;
            const y = e.clientY - rect.top - rect.height / 2;

            return { x, y };
        }
        ////////////////////////////////
        //       END RIPPLE EFFECT
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.c-btn {
    --btn-color: var(--color-dark);
    --btn-color-ripple: var(--color-light);
    --btn-bg: transparent;
    --padding-x: 1.5rem;
    --padding-y: 1.5rem;

    display: inline-flex;
    align-items: center;
    padding: var(--padding-y) var(--padding-x);
    color: var(--btn-color);
    background: var(--btn-bg);
    border-radius: 0.3rem;
    font-weight: 600;
    font-size: fs("regular");
    transition: color 0.5s $out-sine, background 0.5s $out-sine;
    overflow: hidden;

    @media #{md('md')} {
        --padding-x: 2rem;
        --padding-y: 1.6rem;
        font-size: fs("small");
    }

    &.-dark {
        --btn-bg: var(--color-dark);
        --btn-color: var(--color-light);
        //TODO fix the border on hover, please leave the border until its fixed /* border: var(--color-dark) 0px solid; */
        html:not(.is-touch) &:hover {
            --btn-color: var(--color-dark);
            // color: var(--color-dark);
        }
    }

    &.-light {
        --btn-bg: var(--color-light);
        --btn-color: var(--color-dark);

        html:not(.is-touch) &:hover {
            --btn-color: var(--color-light);
            // --btn-bg: var(--color-dark);
            --btn-color-ripple: var(--color-dark);
        }
    }

    &.-underline {
        --btn-color-ripple: var(--color-dark);
        border-bottom: $default-border;
        padding: 1rem 0.5rem;
        width: fit-content;
        border-radius: 0;
        // transition: padding .35s ease;

        &:hover {
            // padding: 1rem .5rem;
            color: var(--color-light);
        }
    }

    &.-outline {
        border: currentColor 1px solid;
    }

    &.-white-background {
        --btn-color-ripple: var(--color-dark);

        &:hover {
            color: var(--color-light);
        }
    }

    &.-small {
        --padding-x: 1rem;
        --padding-y: 1rem;

        .c-btn__label {
            font-weight: 400;
        }
    }

    &.-outline.-white-background {
        &:hover {
            border: var(--color-dark) 1px solid;
        }
    }
}

.c-btn__label {
    & + .c-btn__icon {
        margin-left: 1rem;
    }
}

.c-btn__icon {
    & + .c-btn__label {
        margin-left: 1rem;
    }

    &.-accessibility-icon {
        margin-top: -4px;
        margin-bottom: -4px;
    }
}

.c-btn__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.c-btn__ripple {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: block;
    width: auto;
    height: auto;
    background-color: var(--color-bg);
    border-radius: inherit;

    // Safari overflow bug fix
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    &:before {
        @include pseudo-el($width: auto, $height: auto);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid var(--color-border);
        border-radius: inherit;
    }
    &__fill {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        width: 100%;
        height: 0;
        padding-top: 100%;
        border-radius: 50%;
        margin-top: -50%;
        background-color: var(--btn-color-ripple);
        transform: scale(0);
        will-change: transform;

        .is-touch & {
            display: none;
        }
    }
}

.js-ripple-hit {
    --hit-x: -0.5em;
    --hit-y: var(--hit-x);

    position: absolute;
    top: var(--hit-y);
    bottom: var(--hit-y);
    right: var(--hit-x);
    left: var(--hit-x);
    z-index: 10;

    display: block;
    width: auto;
    height: auto;
    border-radius: inherit;
}

.c-btn__group {
    --btns-gap: 2em;
    --btn-vertical-gap: 1rem;

    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: var(--btns-gap);

    &.-vertical {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--btn-vertical-gap, var(--btns-gap));

        > * {
            width: fit-content;
        }
    }
}
</style>
