<template>
    <div class="l-builder-article">
        <slot name="beforeSections" />

        <div v-for="(block, j) in blocks" :key="`block-${j}`" class="-b">
            <component :is="block.typeHandle" :data="block" />
            <slot name="afterBlock" v-bind="{ index: j }" />
        </div>

        <slot name="afterSections" />
    </div>
</template>

<script>
import BlockContent from "blocks/BlockContent";
import BlockQuote from "blocks/BlockQuote";

export default {
    name: "AppBuilderArticle",
    props: {
        blocks: {
            type: Array,
            default: () => []
        }
    },
    components: {
        BlockContent,
        BlockQuote
    }
};
</script>

<style lang="scss">
.l-builder-article {
    --block-spacer: var(--grid-gutter-half);

    // Blocks
    .-b {
        &:nth-child(n + 2) {
            margin-top: var(--block-spacer);
        }

        &:nth-last-child(n + 2) {
            @media #{md("xs", "max")} {
                margin-bottom: var(--block-spacer);
            }
        }
    }
}
</style>
