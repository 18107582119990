<template>
    <div class="b-address" v-if="data">
        <anim-text
            class="-title t-t3"
            tag="h3"
            :text="data.heading"
            :visible="true"
            reveal.once="true"
            :chars="false"
            :word="false"
            :lines="true"
            :is-axis-y="true"
        />
        <div class="b-address__settings" v-if="settings">
            <div class="-contact-infos">
                <span v-if="settings.address">{{ settings.address }}</span>
                <!-- TODO: TMP Hide email -->
                <!-- <span class="-email">{{ settings.email }}</span> -->
            </div>
            <div class="-contact-infos">
                <span v-if="settings.phone" class="-phone">Téléphone / {{ settings.phone }}</span>
                <span v-if="settings.phoneNoFees" class="-phone-no-fees">Sans frais / {{ settings.phoneNoFees }}</span>
                <span v-if="settings.fax" class="-fax">Télécopieur / {{ settings.fax }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import AnimText from '../objects/AnimText.vue'

export default {
    components: { AnimText },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        settings() {
            return this.data.settings
        }
    }
}
</script>

<style lang="scss">
.b-address {
    margin: var(--grid-gutter) 0;

    .-title {
        max-width: 32rem;
    }
    &__settings {
        display: flex;
        flex: 25rem;
        flex-wrap: wrap;
        gap: var(--grid-gutter);
        margin-top: var(--grid-gutter);
        z-index: 2;

        .-contact-infos {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
            max-width: 25rem;
        }

        .-email {
            text-decoration: underline;
        }
    }
}

</style>
