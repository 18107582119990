<template>
    <div
        v-if="data"
        class="b-quote"
        :class="{'has-image': image}"
    >
        <div v-if="image" class="b-quote__image">
            <asset
                :asset="image"
                :rounded="true"
            />
        </div>
        <blockquote class="b-quote__content | t-t4">
            «&nbsp;<span v-html="data.content" />&nbsp;»
        </blockquote>
    </div>
</template>

<script>
import Asset from 'objects/Asset'

export default {
    name: "BlockQuote",
    components: {
        Asset
    },
    props: {
        data: false
    },
    computed: {
        image() {
            const data = this.data
            return data && data.image.length ? data.image[0] : null
        }
    }
}
</script>

<style lang="scss">
.b-quote {

    &.has-image {
        display: grid;
        grid-template-columns: 5rem 1fr;
        gap: var(--grid-gutter-half);
    }
}
</style>
