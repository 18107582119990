<template>
    <div class="b-table" ref="blockTable" v-reveal.once="reveal">
        <h3 class="b-table__title t-t3" ref="blockTableTitle">{{ data.heading }}</h3>
        <app-table :table="data.table" :is-reveal="true" />
    </div>
</template>

<script>
import AppTable from "../components/AppTable.vue";
import { gsap } from "gsap";
export default {
    components: { AppTable },
    props: {
        data: Object
    },
    data() {
        return {
            isRevealed: false,
            timeline: null
        };
    },
    beforeDestroy() {
        this.destroyTimeline();
    },
    methods: {
        reveal() {
            this.isRevealed ? null : this.runAnimation();
        },
        runAnimation() {
            // hide reveal
            this.toggleIsReveal(true);
            // set the gsap animation
            this.setTimeline();
        },
        toggleIsReveal(bool) {
            this.isRevealed = bool;
        },
        setTimeline() {
            // alternatively it can be a css animation.
            const durationTimeLine = 0.5;
            this.timeline = gsap.timeline({});
            this.timeline.to(this.$refs.blockTableTitle, {
                y: 0,
                opacity: 1,
                duration: durationTimeLine,
                ease: "power1.out"
            });
        },

        destroyTimeline() {
            this.timeline ? (this.timeline.kill(), (this.timeline = null)) : null;
        }
    }
};
</script>

<style lang="scss">
.b-table {
}
.b-table__title {
    @include shared-reveal-pre-animation;
    margin-bottom: var(--grid-gutter);
}
</style>
