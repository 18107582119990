<template>
    <div :class="className" ref="loader">
        <div class="o-loader__inner">
            <span ref="bg" class="o-loader__bg"> </span>

            <div>
                <div ref="logo" class="o-loader__logo"></div>
            </div>
        </div>
        <shared-progress :progression="progression.progress" :is-loader="true" />
    </div>
</template>

<script>
import lottie from "lottie-web";
import { mapState, mapGetters } from "vuex";
import { gsap } from "gsap/all";

import SharedProgress from "components/Shared/SharedProgress";

export default {
    name: "Loader",
    data() {
        return {
            loading: true,
            logoAnimation: null,
            progression: {
                progress: 0,
                timeout: null
            }
        };
    },
    components: {
        SharedProgress
    },
    mounted() {
        this.$store.dispatch("loader/startLoad");

        this.bg = this.$refs.bg || document.querySelector(".o-loader__bg");
        this.loader = this.$refs.loader || document.querySelector(".o-loader");

        this.firstloadIn();

        this.setAnimation();
    },
    beforeDestroy() {
        this.timeoutDestroyer();
    },
    computed: {
        ...mapState({
            firstload: state => state.loader.firstload,
            override: state => state.loader.override
        }),

        ...mapGetters({
            isLoading: "loader/isLoading"
        }),

        className() {
            let classname = "o-loader";

            if (this.firstload) {
                classname += ` -firstload`;
            }

            if (this.override) {
                classname += ` is-hidden`;
            }

            if (this.loading) {
                classname += " is-loading";
            }

            return classname;
        }
    },

    watch: {
        isLoading: {
            handler(loading) {
                if (this.override) return;

                if (loading) {
                    this.animateIn();
                } else if (this.firstload) {
                    this.firstloadOut();
                    this.resizeOnLoad();
                } else {
                    this.animateOut();
                    this.resizeOnLoad();
                }
            }
        }
    },
    methods: {
        ////////////////////////////////
        //       START MOCK PROGRESS
        ////////////////////////////////
        // this is used between page because there is no real progress bar
        runMockProgress() {
            this.updateProgress(0);
            this.progression.timeout = setInterval(() => {
                this.progression.progress < 100 ? this.updateProgress(0.5) : this.timeoutDestroyer();
            }, 10);
        },
        updateProgress(value) {
            this.progression.progress = this.progression.progress + value;
        },
        overwriteProgress(value) {
            this.progression.progress = value;
        },
        timeoutDestroyer() {
            this.progression.timeout
                ? (clearInterval(this.progression.timeout), (this.progression.timeout = null))
                : null;
        },

        ////////////////////////////////
        //       END MOCK PROGRESS
        ////////////////////////////////
        disableScroll() {
            //document.body.classList.add('-scroll-disabled')
        },
        enableScoll() {
            //document.body.classList.remove('-scroll-disabled')
        },
        firstloadIn() {
            this.disableScroll();
            gsap.timeline()
                .add(() => {
                    this.startAnimation();
                })
                .to(
                    this.loader,
                    {
                        yPercent: 0,
                        duration: 3
                    },
                    "firstLoadIn"
                )
                .to(
                    this.bg,
                    {
                        xPercent: 100,

                        duration: 2.4, //fake overlap with logo animation
                        ease: "power3.inOut",
                        delay: 1,
                        onStart: () => {},
                        onComplete: () => {
                            this.$store.dispatch("loader/endLoad");
                        }
                    },
                    "firstLoadIn"
                );
        },

        firstloadOut() {
            gsap.timeline({
                onComplete: () => {
                    this.$store.dispatch("loader/firstLoaded");
                    this.loading = false;
                }
            })
                .to(this.loader, {
                    duration: 0.8,
                    "--x1": "100%",
                    "--x4": "100%",
                    ease: "power2.inOut",
                    delay: 1.2,
                    onStart: () => {
                        this.enableScoll();
                        this.setScroll();
                    }
                })
                .add(() => {
                    this.$store.commit("loader/setLoaderOut", true);
                }, "-=0.5");
        },
        animateIn() {
            this.loading = true;
            this.$store.dispatch("loader/startLoad");

            gsap.timeline({
                onStart: () => {
                    // this.overwriteProgress(0);
                    this.runMockProgress();
                },
                onComplete: () => {
                    this.overwriteProgress(100);
                    this.$store.commit("loader/setLoaderOut", false);
                    this.$store.dispatch("loader/endLoad");
                }
            })
                .set(this.bg, {
                    yPercent: -100,
                    xPercent: 0
                })
                .set(this.loader, {
                    xPercent: 0,
                    yPercent: 0,
                    "--x1": "0%",
                    "--x4": "0%",

                    "--y1": "100%",
                    "--y2": "100%",
                    "--y3": "100%",
                    "--y4": "100%"
                })
                .to(this.loader, {
                    duration: 0.8,
                    "--y1": "0",
                    "--y2": "0",
                    ease: "power2.inOut",
                    onComplete: () => {
                        window.scrollTo(0, 0);
                        this.disableScroll();
                    }
                })
                .to(this.loader, {
                    duration: 0.3,
                    ease: "power2.inOut"
                });
        },
        animateOut() {
            gsap.timeline({
                onComplete: () => {
                    this.loading = false;
                    this.overwriteProgress(0);
                    this.timeoutDestroyer();
                }
            })

                .set(this.loader, {
                    "--y0": "0",
                    "--y1": "0"
                })
                .to(this.loader, {
                    "--y3": "0",
                    "--y4": "0",
                    duration: 0.8,
                    ease: "power3.inOut",
                    onStart: () => {
                        this.enableScoll();
                        this.setScroll();
                    }
                })
                .add(() => {
                    this.$store.commit("loader/setLoaderOut", true);
                }, "-=0.5");
        },
        resizeOnLoad() {
            window.dispatchEvent(new Event("resize"));
        },
        setScroll() {
            // Scroll to hash if exists
            const hash = this.$route.hash;

            if (hash) {
                location.href = hash;
                let el = document.querySelector(hash);

                if (el) {
                    el.scrollIntoView({
                        block: "start",
                        inline: "nearest",
                        behavior: "smooth"
                    });
                } else {
                    window.scrollTo(0, 0);
                }
            } else {
                window.scrollTo(0, 0);
            }
        },
        ////////////////////////////////
        //       START LOTTIE
        ////////////////////////////////
        setAnimation() {
            // Destroy autoplaynimation if exists
            if (this.logoAnimation) {
                this.logoAnimation.destroy();
            }

            this.logoAnimation = lottie.loadAnimation({
                container: this.$refs.logo,
                renderer: "svg",
                autoplay: false,
                loop: false,
                animationData: require(`assets/animations/logo.json`)
            });
        },
        startAnimation() {
            this.logoAnimation.play();
        }
        ////////////////////////////////
        //       END LOTTIE
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
body.-scroll-disabled {
    height: 100vh;
    overflow: hidden;
}

.o-loader {
    --x1: 0;
    --x4: 0;
    --y1: 0;
    --y2: 0;
    --y3: 100%;
    --y4: 100%;

    --clipPath: polygon(var(--x1) var(--y1), 100% var(--y2), 100% var(--y3), var(--x4) var(--y4));

    position: fixed;
    top: 0;
    left: 0;
    z-index: 50000; // I am too scared to update that but it seems really wrong to me, no? Update at your own risk.
    // pointer-events: none; // avoid click through https://mambomambo-team.atlassian.net/browse/EMCV-221

    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    transition: z-index 0s 1s, left 0s 1s;
    background-color: $color-blue;
    clip-path: var(--clipPath);

    &.-firstload {
        background-color: var(--color-green);

        .o-loader__bg {
            background-color: white;
        }
    }

    &.is-loading {
        left: 0;
        transition: none;
    }

    &.is-hidden {
        display: none;
    }

    &__inner {
        height: 100%;
        width: 100%;

        > * {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
        }
    }
    &__logo {
        position: absolute;
        max-width: 800px;
        width: 50vw;

        padding: var(--grid-gutter-half);
        z-index: 2;
        opacity: 0;

        @media #{md("sm")} {
            width: 30vw;
        }

        .-firstload & {
            opacity: 1;
        }
    }
}

.o-loader__bg {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $color-blue;
    transform-origin: 50% 100%;
    will-change: transform;
    clip-path: var(--clipPath);
}
</style>
