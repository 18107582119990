<template>
    <app-section>
        <app-elements
            :filterGroups="filterGroups"
            sectionHandle="article"
            ref="elements"
            :options="{
                limit: 12
            }"
        >

            <template #filterHeader>
                <btn
                    label="Type d'Articles"
                    tag="span"
                    type="dark"
                    icon-after="arrow-down"
                    class="btn-articles__filter"
                    @click.native.stop="toggleFilterPanel"
                />
            </template>
            <template #paginationText>
                actualités présentées
            </template>
        </app-elements>
    </app-section>
</template>

<script>
import AppElements from 'layout/AppElements/AppElements'
import AppSection from 'layout/AppSection'

import { SECTION_HANDLES } from 'src/constants'
import AnimText from '../objects/AnimText.vue'
import Btn from '../components/Btn.vue'

export default {
    name: 'Articles',
    components: {
        AppElements,
        AppSection,
        AnimText,
        Btn
    },
    metaInfo() {
        return {
            title: this.page.seo ? this.page.seo.title : null
        }
    },
    props: {
        page: Object,
    },
    data: () => ({
        SECTION_HANDLES,
        filterGroups: [
            {
                group: 'articleTypes',
                type: 'category'
            },
        ]
    }),
    methods: {
        toggleFilterPanel() {
            this.$refs.elements.$refs.filter.toggleFilterPanel()
        }
    }
};

</script>

<style lang="scss">

.p-articles {

    .l-hero {
        padding-bottom: var(--grid-gutter-6X);

        @media #{md("sm")} {
            padding-bottom: var(--grid-gutter-triple);
        }
    }

    .btn-articles__filter {
        --btn-bg: black;
        --btn-color: white;
        z-index: 10;
        cursor: pointer;
        border-radius: .3rem;
        height: fit-content;
        width: fit-content;
        white-space: nowrap;

        @media #{md('sm')} {
            display: none;
            opacity: 0;
            visibility: hidden;;
        }
    }

    // Add extra padding-bottom to last section, compensate for footer
    .l-elements {
        padding-bottom: var(--footer-offset-gap) !important;
    }
}

</style>
