<template>
    <div
        :class="className"
        :style="cellsHeight ? `--slide-height: ${cellsHeight}px` : false"
    >
        <div class="c-slider__side" :class="`c-slider__side--${navHorizontalPosition}`">
            <slot name="beforeNav" />

            <nav
                v-if="prevNext"
                class="c-slider__nav"
                :class="{'c-slider__nav--outline':isNavHaveOutline}"
                :style="`--slider-progress: ${sliderProgress};`"
            >
                <button
                    aria-label="Précédent"
                    class="c-slider__btn -prev | u-as"
                    :class="{ 'is-disabled': currentIndex === 0 }"
                    @click="previous()"
                >
                    <icon :icon="arrowNav" />
                </button>
                <span class="c-slide__count" v-if="showIndex">
                    {{ slideIndex }}/{{ slidesCount }}
                </span>
                <button
                    aria-label="Suivant"
                    class="c-slider__btn -next | u-as"
                    :class="{ 'is-disabled': isNaN(sliderProgress) || sliderProgress === 1 }"
                    @click="next()"
                >
                    <icon :icon="arrowNav" />
                </button>
            </nav>
            <slot name="afterNav" />
        </div>

        <lightbox
            v-if="zoom"
            ref="zoom"
            :items="slides"
            :index="zoomIndex"
            @close="zoomIndex = null"
        />

        <flickity ref="flkty" :options="opts" class="c-slider__inner">
            <div v-if="fullwidth" class="c-slider__spacer"></div>

            <slot name="sliderExtraCards"/>

            <div
                v-for="(slide, i) in slides"
                :key="`slider-${_uid}-slide-${i}`"
                ref="slide"
                class="c-slider__slide"
                @click="zoomIndex = i"
            >
                <slot
                    name="slide"
                    v-bind="{
                        ...slide,
                        index: i,
                        isActive: i === currentIndex,
                        delta: currentIndex - oldIndex,
                    }"
                >
                    {{ slide }}
                </slot>
            </div>
            <!-- <div v-if="fullwidth" class="c-slider__spacer"></div> -->
        </flickity>
    </div>
</template>

<script>
import Variables from 'src/mixins/variables';
import Icon from 'objects/Icon'

import Flickity from 'vue-flickity'
import Btn from 'components/Btn'
import Lightbox from 'components/Lightbox'

export default {
    name: 'Slider',
    components: {
        Icon,
        Btn,
        Flickity,
        Lightbox
    },
    mixins: [ Variables ],
    data: () => ({
        isDragging: false,
        currentIndex: 0,
        oldIndex: -1,
        flktySlides: 0,
        opts: {
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
            contain: true,
            accessibility: false
        },
        cellsHeight: false,
        zoomIndex: null
    }),
    props: {
        showIndex: {
            type: Boolean,
            default: false,
        },
        calcChildrenHeight: {
            type: Boolean,
            default: false,
        },
        modifier: {
            type: String,
            default: null,
        },
        prevNext: {
            type: Boolean,
            default: true,
        },
        translate: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Object,
            default: () => { },
        },
        slides: {
            type: Array,
            default: () => [],
        },
        reveal: {
            type: Boolean,
            default: true,
        },
        fullwidth: {
            type: Boolean,
            default: false,
        },
        fullheight: {
            type: Boolean,
            default: false,
        },
        zoom: {
            type: Boolean,
            default: false,
        },
        navHorizontalPosition:{
            type: String,
            required: false,
            default: 'left',
        },
        isNavHaveOutline:{
            type: Boolean,
            required:false,
            default: true,
        },
        arrowNav:{
            type: String,
            required:false,
            default: 'arrow-right',
        }
    },
    created() {

        this.opts = { ...this.opts, ...this.options }
        
        setTimeout(() => {
            this.$refs.flkty.resize()
        }, 2500)
    },
    mounted() {
        const flkty = this.$refs.flkty

        flkty.on('dragStart', this.onDragStart = () => this.isDragging = true)
        flkty.on('dragEnd', this.onDragEnd = () => this.isDragging = false)

        this.flktySlides = flkty.slides().length

        flkty.on('resize', this.onResize = () => {
            this.flktySlides = flkty.slides().length
            this.setSlidesHeight()
        })

        flkty.on('select', this.onSelect = i => {
            this.oldIndex = this.currentIndex
            this.currentIndex = i
            this.$emit('select', this.currentIndex, this.oldIndex)
        })

        this.setSlidesHeight()
    },
    computed: {
        className() {
            let classname = 'c-slider'
            if (this.opts && this.opts.wrapAround) {
                classname += ' -wrap-around'
            }
            if (this.modifier) {
                classname += ` -${this.modifier}`
            }

            if (!this.translate) {
                classname += ` -no-translate`
            }

            if (this.zoom) {
                classname += ` -zoom`
            }

            if (this.isDragging) {
                classname += ` is-dragging`
            }

            if (this.fullwidth) {
                classname += ` -fullwidth`
            }

            if (this.cellsHeight) {
                classname += ` -fullheight`
            }

            return classname
        },
        totalSlides() {
            return this.slides.length
        },
        sliderProgress() {
            const progress = this.currentIndex / (this.flktySlides - 1)
            return progress
        },
        slideIndex() {
            return this.currentIndex >= 9
                ? this.currentIndex + 1
                : `0${this.currentIndex + 1}`
        },
        slidesCount() {
            return this.slides.length >= 10
                ? this.slides.length
                : `0${this.slides.length}`
        }
    },
    methods: {
        select(index) {
            this.$refs.flkty.select(index)
        },
        resize() {
            this.$refs.flkty.resize()
        },
        previous() {
            this.$refs.flkty.previous()
        },
        next() {
            this.$refs.flkty.next()
        },
        setSlidesHeight() {
            if (!this.fullheight) {
                return
            }
            this.cellsHeight = false

            this.$nextTick(() => {
                let newHeight = 0
                this.$refs.slide.forEach((slide) => {


                    let slideHeight = 0;

                    if (this.calcChildrenHeight && this.browser.name == 'mobile-safari') {

                        const children = slide.children[0].children;

                        for (var i = 0; i < children.length; i++) {
                            slideHeight += children[i].offsetHeight;
                        }
                    } else {
                        slideHeight = slide.offsetHeight
                    }

                    if (slideHeight > newHeight) {
                        newHeight = slideHeight
                    }
                })

                this.cellsHeight = newHeight
            })
        }
    },
    beforeDestroy() {
        const flkty = this.$refs.flkty

        flkty.off('dragStart', this.onDragStart)
        flkty.off('dragEnd', this.onDragEnd)
        flkty.off('resize', this.onResize)
        flkty.off('select', this.onSelect)
    },
    watch: {
        zoomIndex($v) {
            if (this.$refs.zoom)
                this.$refs.zoom.index = $v
        }
    }
};

</script>

<style lang="scss">
.c-slider {
    --slide-width: 100%;
    --slide-gap: var(--grid-gutter);
    --nav-bg: transparent;
    height: auto;

    &.-no-translate {
        --slide-width: 100%;

        .flickity-slider {
            transform: none !important;
        }

        .c-slider__slide {
            &:not(:first-child) {
                top: 0;
                left: 0 !important;
                width: 100%;
            }

            &.is-selected {
                z-index: 1;
            }
        }
    }

    .flickity-viewport {
        overflow: visible;
    }

    &.-fullwidth {
        .flickity-viewport {
            width: 100vw;
            left: calc(50% - 50vw);
        }

        .c-slider__spacer {
            width: var(--grid-start-x);
        }
    }

    &.-fullheight {
        .flickity-viewport {
            height: var(--slide-height) !important;
        }

        .c-slider__slide {
            height: var(--slide-height);
        }
    }

    &.-gallery {
        --slide-width: 98%;
        --slide-gap: 0;
        padding: 0 var(--grid-gutter);

        @media #{md("xs")} {
            --slide-width: 60%;
        }
        @media #{md("sm")} {
            --slide-width: 40%;
            padding: 0;
        }
        @media #{md("md")} {
            --slide-width: 33%;
        }

        .flickity-slider {
            height: 100%;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .c-slider__slide {
            padding: 0 calc( var(--grid-gutter-half) / 2 );

            @media #{md("md")} {
                padding: 0 var(--grid-gutter-half);
            }
        }

        .c-slider__side  {
            transform: translateY(-50%);
            pointer-events: none;

            .c-slider__btn {
                pointer-events: auto;
            }
        }
    }

    &.-partners {
        --slide-gap: calc(var(--grid-gutter) * 1.5);

        .flickity-viewport {
            overflow: hidden;
        }

        .flickity-slider {
            display: flex;
            align-items: center;
            height: var(--slide-height);
        }

        .c-slider__slide {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;

            @media #{md("sm")} {
                --slide-gap: var(--grid-gutter-double);
            }

            .o-asset__img {
                max-height: 8rem;
                max-width: 10rem;
                width: auto;

                @media #{md("sm")} {
                    max-height: 12rem;
                    max-width: 14rem;
                }

                @media #{md("lg")} {
                    max-height: 15rem;
                    max-width: 18rem;
                }
            }
        }
    }

    &.-nav-on-top {

        .c-slider__side {
            position: relative;
            top:0;
            left:0;
            justify-content: space-between;
            margin-top: var(--grid-gutter);
            width: 100%;

            .c-slider__nav--after {
                position: relative;
            }

            .c-slider__nav {
                width: fit-content;
            }

            .c-slider__btn {
                padding: 0.9rem 1.2rem;
                border: 1px solid currentColor;

                .o-icon {
                    svg {
                        --svg-width: 0.8rem;
                    }
                }

                &:last-of-type {
                    margin-left: -1px;
                }
            }
        }
    }

    &.is-dragging {
        a,
        button {
            pointer-events: none;
        }
    }

    &.-zoom {
        .c-slider__slide {
            cursor: zoom-in;
        }
    }

    @media #{md("md")} {
        --slide-width: calc(1 / 4 * 100%);
    }

    @media #{md("xl")} {
        --slide-width: calc(1 / 5 * 100%);
    }
}

.c-slider__slide {
    display: block;
    width: var(--slide-width);
    margin-right: var(--slide-gap);
}

.c-slider__side {
    position: absolute;
    top: 50%;
    left: calc(-1 * var(--grid-gutter));
    z-index: 3;
    display: flex;
    align-items: center;
    width: calc(100% + 2 * var(--grid-gutter));
    margin-bottom: var(--grid-gutter);

    .c-slider__nav--after {
        position: absolute;
        right: 0;
    }
    .c-slider__nav--before {
        margin-right: var(--grid-gutter);
    }
}

.c-slider__nav {
    --slider-progress: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;

    &:after,
    &:before {
        position: absolute;
        left: 0;
    }

    &:before {
        @include pseudo-el($width: calc(100% - 3.5em * 2 - 3em), $height: 1px);
        top: calc(50% - 1px / 2);
    }

    &.is-disabled {
        pointer-events: none;
    }

    .c-slide__count {
        --slider-btn-width: 5.25em;
        width: var(--slider-btn-width);
        padding: 0.75em 1em;
        background: var(--nav-bg);
        border-left: solid 1px currentColor;
        border-right: solid 1px currentColor;
        text-align: center;
    }
}

.c-slider__btn {

    display: flex;
    align-items: center;
    padding: 0.75em 1em;
    cursor: pointer;

    &:before {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.2s ease-out;
    }

    &:hover:before {
        transition: transform 0.3s ease-out;
    }

    .c-slider:not(.-wrap-around) &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.-prev {
        border-top-left-radius: $border-radius-xs;
        border-bottom-left-radius: $border-radius-xs;
        background: var(--nav-bg);

        .o-icon  {
            transform: rotate(180deg);
        }
    }

    &.-next {
        border-top-right-radius: $border-radius-xs;
        border-bottom-right-radius: $border-radius-xs;
        background: var(--nav-bg);
    }
}
</style>
